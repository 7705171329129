import React, { useContext, useState } from "react";
import { Box, Button, TextField, Typography, Paper } from "@mui/material";
import SmartLead from "../../img/images/smartlead.png";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
import CircularProgress from "@mui/material/CircularProgress";
import SnackBar from "../common/SnackBar";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SmartLeadAPIForm = ({ handleRedirect }) => {
    const workctx = useContext(WorkspaceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [apiKey, setApiKey] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const handleConnect = () => {
        setIsLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/createAppIntegraion`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                workspaceId: workctx.activeWorkspace,
                api_key: apiKey,
                name: "smartlead",
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    handleRedirect();
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message || data.msg);
                }
                setIsLoading(false);
            });
    };
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                padding: 2,
            }}
        >
            <Paper
                sx={{
                    padding: 3,
                }}
            >
                {" "}
                <Typography
                    variant="h5"
                    component="div"
                    color="primary"
                    sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
                >
                    <img src={SmartLead} alt="Smart lead Logo" style={{ width: 122, height: 40 }} />
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Enter your smartlead account API key here to audit campaigns
                </Typography>
                <TextField
                    label="Enter API key"
                    variant="outlined"
                    sx={{ mb: 2 }}
                    onChange={(e) => setApiKey(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ display: "block" }}
                    disabled={isLoading}
                    onClick={() => handleConnect()}
                >
                    {isLoading ? <CircularProgress color="inherit" /> : "Connect"}
                </Button>
                <Box sx={{ mt: 2, textAlign: "left" }}>
                    <Typography variant="subtitle2" gutterBottom>
                        Steps to find Smartlead API key:
                    </Typography>
                    <Typography variant="body2">
                        •{" "}
                        <a href="https://app.smartlead.ai/login" target="_blank">
                            Login
                        </a>{" "}
                        to your Smartlead application.
                        <br />• Go to settings and then the integration tab (
                        <a href="https://app.smartlead.ai/app/settings/profile" target="_blank">
                            URL
                        </a>
                        ).
                        <br />• In Smartlead API card click on Generate new API key, copy the new generated API key, and
                        paste it above.
                    </Typography>
                </Box>
            </Paper>

            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
        </Box>
    );
};

export default SmartLeadAPIForm;
