import CustomCard from "../common/CustomCard";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Provider/AuthProvider";
import { useContext } from "react";
import dnsRecord from "../../img/icons/globe.png";
import blacklistCheck from "../../img/icons/blacklist.png";
import coldEmail from "../../img/icons/cal.png";
import spamCheck from "../../img/icons/spam.png";
import domainName from "../../img/icons/dgenerator.png";
import emailHeader from "../../img/icons/emailHeader.png";
import espFinder from "../../img/icons/espFinder.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import { styled } from "@mui/material/styles";
import { PlanContext } from "../Provider/PlanContext";
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
        },
    })
);
const Tools = () => {
    const planCtx = useContext(PlanContext);
    const navigate = useNavigate();
    const handleClick = (path) => {
        navigate(`/dashboard/${path}`);
    };
    const toolArray = [
        {
            title: "Spam Checker",
            content:
                "SpamAssassin analyzes your emails and assigns a spam score, highlighting any detected issues to help keep your inbox clean.",
            img: spamCheck,
            path: "spammy-word-checker",
        },
        {
            title: "Cold Email Strategy",
            content:
                "Cold email outreach is a strategic way of sending highly personalized emails to individuals or businesses to grab their interest in your product or service.",
            img: coldEmail,
            path: "domain-calculator",
        },
        {
            title: "Domain Name Generator",
            content:
                "Get creative domain name ideas. Tell us about your idea and Domain Search will create a targeted list of domain names for you.",
            img: domainName,
            path: "domain-generator",
        },
        {
            title: "Blacklist Checker",
            content:
                "Our blacklist check tool enables you to detect if IPs, domains, and emails are blacklisted. This significantly helps to identify suspicious activities.",
            img: blacklistCheck,
            path: "blacklist-checker",
        },
        {
            title: "DNS Records",
            content:
                "DNS records are text instructions stored on DNS servers. They indicate the IP address associated with a domain and can provide other information as well.",
            img: dnsRecord,

            path: "dns-records",
        },
        {
            title: "Email Header Analyzer",
            content:
                "Email Header lets you analyze your email with its source, authentication details. Copy your original email to analyze them in human readable format.",
            img: emailHeader,
            path: "email-header-analyze",
        },
        {
            title: "ESP Finder",
            content: "Use our ESP Finder to quickly identify the email service provider associated with any domain. ",
            img: espFinder,
            path: "find-ESP",
        },
    ];
    const toolsSubModules = planCtx.features.find((feature) => feature.label === "Tools")?.subModules ?? [];
    const updatedSubModules =
        toolsSubModules
            ?.map((subModule) => {
                const matchingTool = toolArray.find((tool) => tool.path === subModule.path);
                return {
                    ...subModule,
                    content: matchingTool ? matchingTool.content : null, // Add content if matched, otherwise null
                };
            })
            ?.sort((a, b) => {
                const indexA = toolArray.findIndex((tool) => tool.path === a.path);
                const indexB = toolArray.findIndex((tool) => tool.path === b.path);
                return indexA - indexB; // Sort according to toolArray order
            }) ?? [];
    return (
        <>
            <Box display="flex" justifyContent="center" padding={2}>
                <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }} maxWidth="1200px">
                    {updatedSubModules.map((arr, index) => (
                        <LightTooltip
                            title={!arr.isActive ? "Subscribe to Access!" : ""}
                            placement="bottom"
                            key={index}
                        >
                            <Grid item xs={4} sm={4} md={4}>
                                <Box position="relative">
                                    <CustomCard
                                        title={arr.label}
                                        content={arr.content}
                                        handleClick={() => handleClick(arr.path)}
                                        disabled={!arr.isActive}
                                        img={arr.img ? arr.img : ""}
                                    />
                                    {!arr.isActive && (
                                        <Box
                                            position="absolute"
                                            top={8}
                                            right={8}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            color="#ffc8009c"
                                        >
                                            <CardMembershipOutlinedIcon fontSize="small" />
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        </LightTooltip>
                    ))}
                </Grid>
            </Box>
        </>
    );
};
export default Tools;
