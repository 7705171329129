import React from "react";
import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItemButton,
    ListItemText,
    Typography,
    CircularProgress,
} from "@mui/material";
import "./ManualAudit.css";
import CloseIcon from "@mui/icons-material/Close";

const DomainListDialog = ({ open, onClose, domainList, onDomainClick, loading, Close }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="domain-list-dialog"
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    width: 400, 
                    height:"auto", 
                    overflow: "hidden", 
                },
            }}
        >
            <Box className="closeIcon-box">
                <CloseIcon onClick={Close} className="close-icon" />
            </Box>
            <DialogTitle id="domain-list-dialog" className="manual-dialog-title">
                 Domain List

                <Typography variant="subtitle1">Choose a domain</Typography>
            </DialogTitle>
            <DialogContent>
                {loading ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <List>
                        {domainList.map((data, index) => (
                            <ListItemButton
                                key={index}
                                className="manual-list-item"
                                onClick={() => onDomainClick(data.domain)}
                            >
                                <ListItemText sx={{textAlign:'center'}} primary={data.domain} />
                            </ListItemButton>
                        ))}
                    </List>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default DomainListDialog;
