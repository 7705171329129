import React from "react";
import { Box, Paper, Typography, ListItemText, List, ListItem as MuiListItem, Grid } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton } from "@mui/material";
import HtmlPreview from "../HtmlPreview";
const TemplateSelector = ({ templateContent, spamCheck }) => {
    const handleCopy = (content) => {
        navigator.clipboard.writeText(content);
        alert("Copied to clipboard");
    };
    return (
        <Box>
            <Paper
                sx={{
                    width: "850px",
                    padding: "20px",
                    border: "1px solid #f1f1f1",
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                    boxShadow: "none !important",
                }}
            >
                <Paper sx={{ p: 2, display: "flex" }}>
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                borderRadius: "5px",
                                padding: "10px",
                                marginBottom: "20px",
                                backgroundColor: "white",
                                alignItems: "center",
                                height: "40px",
                                gap: 2,
                                width: "550px", // Set width to 550px
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: "bold",
                                    color: "#24a0ed",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                Subject:
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    flexGrow: 1, // Allows the text to take up remaining space
                                }}
                            >
                                {templateContent?.subject ? templateContent.subject : "No Subject Found"}
                            </Typography>
                            <IconButton onClick={() => handleCopy(templateContent.subject)}>
                                {" "}
                                <ContentCopyIcon sx={{ marginLeft: "auto", cursor: "pointer", float: "right" }} />
                            </IconButton>
                        </Box>

                        {/* Body Display Box */}
                        <Box
                            sx={{
                                borderRadius: "5px",
                                padding: "10px",
                                backgroundColor: "white",
                                alignItems: "center",
                                overflow: "hidden",
                                width: "550px", // Set width to 550px
                            }}
                        >
                            <Typography
                                sx={{
                                    minHeight: "180px",
                                    wordBreak: "break-word",
                                    whiteSpace: "pre-wrap",
                                    padding: "10px",
                                    backgroundColor: "#f9f9f9",
                                    borderRadius: "5px",
                                }}
                            >
                                {templateContent?.body ? (
                                    <HtmlPreview htmlContent={templateContent.body} />
                                ) : (
                                    "No Content FOund"
                                )}
                            </Typography>
                            <IconButton onClick={() => handleCopy(templateContent.body)}>
                                <ContentCopyIcon sx={{ marginTop: "10px", cursor: "pointer", float: "right" }} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: "#e0f2f1",
                            width: "350px",
                            padding: "10px", // Enable vertical scrolling
                            maxHeight: "400px",
                            overflowX: "hidden",
                        }}
                    >
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Spam Analysis
                        </Typography>
                        {Array.isArray(spamCheck) ? (
                            <List>
                                {spamCheck.map((word, index) => (
                                    <MuiListItem key={index}>
                                        <ListItemText primary={word} />
                                    </MuiListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography>{spamCheck ? spamCheck : ""}</Typography>
                        )}
                    </Box>
                </Paper>
            </Paper>
        </Box>
    );
};

export default TemplateSelector;
