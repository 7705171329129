import React, { useContext, useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
    CssBaseline,
    Box,
    Typography,
    CircularProgress,
    Button,
    TextField,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import reputationCheck from "../img/images/repuatiobCheck.png";
import xaudit1 from "../img/icons/xaudit1.png";
import cookie from "cookiejs";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SnackBar from "./common/SnackBar";
import { WorkspaceContext } from "./Provider/WorkspaceProvider";
import CustomCarousel from "./common/Carousel";
import { AuthContext } from "./Provider/AuthProvider";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const mdTheme = createTheme({
    typography: {
        fontFamily: ["League Spartan", "sans-serif"].join(","),
    },
});
const Login = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const context = useContext(WorkspaceContext);
    const authCtx = useContext(AuthContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [sessionExpired, setSessionExpired] = useState(state === null ? false : state.sessionExpired);
    const [accountVerifiedMsg, setAccountVerifiedMsg] = useState(state === null ? "" : state.msg);
    const [showPassword, setShowPassword] = useState(false);
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState(state === null ? false : state.success);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [isValidEmail, setIsValidEmail] = useState(false);
    useEffect(() => {
        let isLogin = cookie.get("token");
        if (isLogin) {
            navigate("/dashboard/InboxPlacement");
        }
    }, [navigate]);

    function verify() {
        navigate(`/account-verification?email=${email}`);
    }

    const submitHandler = (e) => {
        setLoginLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/login`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            credentials: "include",
            body: new URLSearchParams({
                password: password,
                email: email,
            }),
        })
            .then((response) => {
                setLoginLoading(false);
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    context.recheckWorkspace();
                    authCtx.recheckPlanDetails();
                    navigate("/dashboard/extra-tools");
                } else if (
                    !data.success &&
                    (data.msg || data.message) === "Account not verified, verify your account"
                ) {
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message || data.msg);
                    verify();
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message || data.msg);
                }
            });
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(value));
    };
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
    };
    const isAllConditionMet = loginLoading || !isValidEmail || !email;
    const createContent = (des1, des2) => (
        <Box>
            <Typography
                className="second-child-typography"
                sx={{ textAlign: "center", fontSize: "30px", fontWeight: "500px", lineHeight: "38px" }}
            >
                {des1}
            </Typography>
            <Typography
                className="second-child-typography"
                sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "600px",
                    lineHeight: "28px",
                    marginRight: "25px",
                }}
            >
                {des2}
            </Typography>
        </Box>
    );
    const slides = [
        {
            id: 1,
            content: createContent(
                "Optimize your email template",
                "Create visually appealing emails that engage your audience and boost interaction"
            ),
        },
        {
            id: 2,
            content: createContent(
                "Monitor Your Domain Reputation",
                "Regularly check your domain score to ensure your emails land in the inbox"
            ),
        },
        {
            id: 3,
            content: createContent(
                "Ensure Proper Authentication",
                "Set up SPF and DKIM records to enhance email security and deliverability"
            ),
        },
    ];
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (!isAllConditionMet && !loginLoading) {
                submitHandler();
            }
        }
        return;
    };
    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />

            <Box sx={{ width: "100%", height: "100vh", display: "flex" }}>
                <Box
                    sx={{
                        width: "60%",
                        height: "100vh",
                        backgroundColor: "whitesmoke",
                        padding: "22px",
                        overflow: "hidden",
                    }}
                >
                    <Box>
                        <Link exact to="/">
                            <img src={xaudit1} alt="/" style={{ width: "200px", height: "56px", marginTop: "-10px" }} />
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "10%",
                            flexDirection: "column",
                        }}
                    >
                        <Box>
                            <img
                                src={reputationCheck}
                                style={{
                                    textAlign: "center",
                                    width: "60%",
                                }}
                            />
                        </Box>
                        <CustomCarousel slides={slides} />
                    </Box>
                </Box>
                <Box sx={{ width: "40%", height: "100vh" }}>
                    <div style={{ marginTop: "30%", marginLeft: "100px" }}>
                        <Typography
                            sx={{
                                fontSize: "30px",
                                fontWeight: "bold",
                                pb: 3,
                            }}
                        >
                            Log In
                        </Typography>
                        <div>
                            <TextField
                                placeholder="Email"
                                type="email"
                                onChange={handleEmailChange}
                                onKeyDown={handleKeyDown}
                                value={email}
                                fullWidth
                                sx={{ width: "370px", pb: 1 }}
                            />
                            {!isValidEmail && email && (
                                <Typography sx={{ fontSize: "14px", pb: 1, color: "red" }}>
                                    Enter a valid email address (e.g., user@domain.com)."
                                </Typography>
                            )}
                        </div>
                        <TextField
                            required
                            placeholder="Password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={password}
                            onKeyDown={handleKeyDown}
                            onChange={handlePasswordChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            sx={{ width: "370px", pb: 1 }}
                        />
                        <Typography sx={{ pt: 1 }}>
                            <Link
                                exact
                                to={isValidEmail ? `/forgotPassword?email=${email}` : `/forgotPassword`}
                                style={{
                                    textDecoration: "none",
                                    color: "rgba(0, 74, 173, 1)",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    lineHeight: "21px",
                                }}
                            >
                                Forgot Password
                            </Link>
                        </Typography>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            onClick={submitHandler}
                            sx={{
                                width: "370px",
                                mt: 1,
                                borderRadius: "6px",
                                textTransform: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                                backgroundColor: "#481BEB",
                            }}
                            disabled={isAllConditionMet}
                        >
                            {loginLoading && <CircularProgress size={25} />}
                            {!loginLoading && "Login"}
                        </Button>
                        <Typography sx={{ pt: 2 }}>
                            <Typography sx={{ mr: 0.5, float: "left" }}>Don't have an account?</Typography>
                            <Link exact to="/register" style={{ color: "rgba(0, 74, 173, 1)", textDecoration: "none" }}>
                                Sign Up Now
                            </Link>
                        </Typography>
                    </div>
                </Box>
            </Box>
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
            <SnackBar
                open={sessionExpired}
                message="Session expired, Login again!"
                type="error"
                onClose={() => setSessionExpired(false)}
            />
            <SnackBar
                open={accountVerifiedMsg !== ""}
                message={accountVerifiedMsg}
                type={accountVerifiedMsg === "account verified" ? "success" : "error"}
                onClose={() => setAccountVerifiedMsg("")}
            />
            <SnackBar
                open={resetPasswordSuccess}
                message="Password reset successfully"
                type="success"
                onClose={() => setResetPasswordSuccess(false)}
            />
        </ThemeProvider>
    );
};

export default Login;
