import React, { useState, useEffect } from "react";
import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItemButton,
    ListItemText,
    Typography,
    Checkbox,
    IconButton,
    CircularProgress
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "rsuite";

const DomainListDialog = ({ open, onClose, domainList, onAdd, loading }) => {
    const [checked, setChecked] = useState([]);

    useEffect(() => {
        setChecked(domainList);
    }, [domainList]);

    const handleToggle = (value) => {
        const updatedDomainList = checked.map((domain) =>
            domain.mail === value ? { ...domain, exist: !domain.exist } : domain
        );

        setChecked(updatedDomainList);
    };

    const handleAddClick = () => {
        onAdd(checked);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="domain-list-dialog"
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    width: 450,
                    height: "auto",
                    overflow: "hidden",
                    position: "relative",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                },
            }}
        >
            <IconButton
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: "#555",
                    "&:hover": {
                        color: "#000",
                    },
                }}
                onClick={onClose}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle
                id="domain-list-dialog"
                sx={{
                    padding: "24px 24px 0 24px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "10px",
                    borderBottom: "1px solid #f0f0f0",
                }}
            >
                <Typography variant="h5" fontWeight="bold">
                    {" "}
                    Bcc List
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    Choose a BCC
                </Typography>
            </DialogTitle>
            <DialogContent
                sx={{
                    padding: "16px 24px",
                    "& .MuiList-root": {
                        paddingTop: 0,
                    },
                }}
            >
                <List>
                    {checked.map((data, index) => (
                        <ListItemButton
                            key={index}
                            onClick={() => handleToggle(data.mail)}
                            sx={{
                                padding: "12px 16px",
                                borderRadius: "8px",
                                "&:hover": {
                                    backgroundColor: "#f0f0f0",
                                },
                            }}
                        >
                            <Checkbox checked={data.exist?true:false} tabIndex={-1} disableRipple sx={{ padding: 0 }} />
                            <ListItemText primary={data.mail} sx={{ marginLeft: "16px", textAlign: "left" }} />
                        </ListItemButton>
                    ))}
                </List>
            </DialogContent>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px",
                    borderTop: "1px solid #ddd",
                    marginTop: "auto",
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddClick}
                    sx={{
                        minWidth: "100px",
                        padding: "8px 16px",
                        textTransform: "none",
                        borderRadius: "20px",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                    }}
                    startIcon={loading && <CircularProgress size={20} style={{ color: "white" }} />}
                >
                    {loading ? " Add Bcc..." : " Add Bcc"}
                </Button>
            </Box>
        </Dialog>
    );
};

export default DomainListDialog;
