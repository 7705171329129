import React, { useState, useEffect, useRef } from "react";
import { Box, CardContent, Card, Typography, Grid, Button } from "@mui/material";
import "./spamchecker.css";
import spamWordsV1 from "./englishSpam.json";
import { PieChart } from "@mui/x-charts/PieChart";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
const SpamChecker = () => {
    const navigate = useNavigate();

    const [spamScore, setSpamScore] = useState(0);
    const [ScoreText, setScoreText] = useState(0);
    const [total, setTotal] = useState(0);
    const [sWords, setSpamWords] = useState(0);
    const [spam, setSpam] = useState(0);
    const [notSpam, setNotSpam] = useState(100);
    const contentEditableRef = useRef(null);
    const data = [
        { label: "Spam", value: spam },
        { label: "Not spam", value: notSpam },
    ];

    const highlightSpamWords = (content) => {
        const spamWords = spamWordsV1.spamWords;
        const regex = new RegExp(`\\b(${spamWords.join("|")})\\b`, "gi");
        const highlighted = content.replace(regex, (match) => {
            return `<span class="highlight">${match}</span>`;
        });
        return highlighted;
    };

    const handleInput = () => {
        if (contentEditableRef.current) {
            const content = contentEditableRef.current.innerText;
            const totalWords = content.trim().split(/\s+/);
            const spamWords = spamWordsV1.spamWords;
            const regex = new RegExp(`\\b(${spamWords.join("|")})\\b`, "gi");
            const numSpamWords = content.match(regex) || [];
            if (totalWords.length === 1 && totalWords[0] === "") {
                totalWords.pop();
            }
            if (numSpamWords[numSpamWords.length - 1] === "") {
                numSpamWords.pop();
            }
            let spamScore = numSpamWords.length / totalWords.length;
            setTotal(totalWords.length);
            setSpamWords(numSpamWords.length);
            let spamScoreTextValue = "";
            if (spamScore >= 0.1) {
                spamScoreTextValue = "Poor";
            } else if (spamScore >= 0.05) {
                spamScoreTextValue = "Good";
            } else {
                if (isNaN(spamScore)) {
                    spamScore = 0;
                }
                spamScoreTextValue = "Excellent";
            }
            setSpamScore(spamScore);
            setScoreText(spamScoreTextValue);
            setSpam(Math.round(spamScore * 100));
            setNotSpam(Math.round((1 - spamScore) * 100));
            const highlightedContent = highlightSpamWords(content);
            contentEditableRef.current.innerHTML = highlightedContent;
            const selection = window.getSelection();
            const range = document.createRange();
            selection?.removeAllRanges();
            range.selectNodeContents(contentEditableRef.current);
            range.collapse(false);
            selection?.addRange(range);
        }
    };

    useEffect(() => {
        if (contentEditableRef.current) {
            contentEditableRef.current.focus();
        }
    }, []);
    return (
        <Box>
            <Button
                sx={{ margin: "15px" }}
                onClick={() => {
                    navigate(-1);
                }}
            >
                <ArrowBackIos />
                Back
            </Button>
            <Box sx={{ textAlign: "center" }}>
                <span style={{ fontSize: "30px", fontWeight: "1200px", lineHeight: "38px" }}>Spam Checker</span>
                <br />
                <span
                    style={{
                        fontSize: "18px",
                        color: "rgba(71, 84, 103, 1)",
                        fontWeight: "400px",
                        lineHeight: "24px",
                    }}
                >
                    Discover all DNS records for a domain with ease. From A to TXT, view various record types
                    effortlessly. Enter the domain name, choose a record type from the dropdown.
                </span>
            </Box>
            <Grid container spacing={3} sx={{ paddingTop: "20px" }}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <CardContent>
                                <Card
                                    sx={{
                                        padding: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                    }}
                                >
                                    <Typography variant="h6">Words</Typography>
                                    <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>{total}</Typography>
                                </Card>
                            </CardContent>
                        </Grid>
                        <Grid item xs={3}>
                            <CardContent>
                                <Card
                                    sx={{
                                        padding: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                    }}
                                >
                                    <Typography variant="h6">Spam</Typography>
                                    <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>{sWords}</Typography>
                                </Card>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h6">Enter Content:</Typography>
                    <div
                        ref={contentEditableRef}
                        contentEditable
                        onInput={handleInput}
                        className={`editable-content ${spamScore > 0 ? "highlight-border" : ""}`}
                        style={{
                            border: "1px solid #ccc",
                            padding: "10px",
                            borderRadius: "8px",
                            minHeight: "300px",
                            boxShadow: spamScore > 0 ? "0 0 10px red" : "none",
                        }}
                    ></div>
                </Grid>
                <Grid item xs={4} container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{ textAlign: "center", fontSize: "24px", fontWeight: "bold" }}>
                            Score: {ScoreText}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <PieChart
                            colors={["red", "green"]}
                            series={[
                                {
                                    startAngle: -360,
                                    endAngle: 90,
                                    data,
                                    cx: 200,
                                    cy: 95,
                                    outerRadius: 100,
                                },
                            ]}
                            height={500}
                            width={350}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SpamChecker;
