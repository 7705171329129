import React, { useContext, useState, useEffect } from "react";
import { CircularProgress, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SessionExpired from "../SessionExpired";
import InvalidVerificationAttempt from "../InvalidVerificationAttempt";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const VerifyTeam = () => {
    const navigate = useNavigate();
    const [showExpiredToken, setShowExpiredToken] = useState(false);
    const [invalidAttempt, setInvalidAttempt] = useState(false);
    const token = window.location.pathname.split("/")[2];
    const [title, setTitle] = useState("");
    const [success, setSuccess] = useState(null);
    useEffect(() => {
        setSuccess(false);
        fetch(`${REACT_APP_SERVER_URL}/verifyTeamInvite/${token}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    navigate(data.data.path, { state: { email: data.data.email } });
                } else if (data.success === false && data.message === "Token has expired") {
                    setShowExpiredToken(true);
                    // setTimeout(() => {
                    //     navigate("/login");
                    // }, 3000);
                } else {
                    setTitle(data.message || data.msg);
                    setInvalidAttempt(true);
                    // setTimeout(() => {
                    //     navigate("/login");
                    // }, 3000);
                }
                setSuccess(true);
            })
            .catch((error) => console.log(error));
    }, []);
    return (
        <Box sx={{ display: "flex", justifyContent: "center", height: "auto", alignItems: "center" }}>
            {invalidAttempt && <InvalidVerificationAttempt title={title} />}
            {showExpiredToken && <SessionExpired teamSession={true} />}
            {!success && <CircularProgress />}
        </Box>
    );
};

export default VerifyTeam;
