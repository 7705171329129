import React, { useContext, useState } from "react";
import { CircularProgress, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SessionExpired from "./GetStarted/SessionExpired";
import InvalidVerificationAttempt from "./GetStarted/InvalidVerificationAttempt";
import { AuthContext } from "./Provider/AuthProvider";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const VerifyAccount = () => {
    const navigate = useNavigate();
    const [success, setSuccess] = React.useState(null);
    const [type, setType] = useState(null);
    const [showExpiredToken, setShowExpiredToken] = useState(false);
    const [invalidAttempt, setInvalidAttempt] = useState(false);
    const token = window.location.pathname.split("/")[2];
    const [resendEmail, setResendEmail] = useState("");
    const [title, setTitle] = useState("");
    const context = useContext(AuthContext);
    React.useEffect(() => {
        setSuccess(false);
        fetch(`${REACT_APP_SERVER_URL}/verifyemailtoken/${token}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success === true && data.data.user) {
                    context.handleuser(data.data.user);
                    navigate(data.data.path, { state: { user: data } });
                } else if (data.success === true && data.data.email) {
                    navigate(data.data.path, { state: { email: data.data.email, token: token } });
                } else if (data.success === false && data.message === "Token has expired") {
                    console.log(data, "expired");
                    data.data?.type && setType(data.data.type);
                    setResendEmail(data.data.email);
                    setShowExpiredToken(true);
                } else {
                    setTitle(data.message || data.msg);
                    setInvalidAttempt(true);
                    // setTimeout(() => {
                    //     navigate("/login");
                    // }, 3000);
                }
                setSuccess(true);
            })
            .catch((error) => console.log(error));
    }, []);
    return (
        <Box>
            {invalidAttempt && <InvalidVerificationAttempt title={title} />}
            {showExpiredToken && <SessionExpired email={resendEmail} type={type} />}
            <Box sx={{ display: "flex", justifyContent: "center" }}>{!success && <CircularProgress />}</Box>
        </Box>
    );
};

export default VerifyAccount;
