import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
    CssBaseline,
    Box,
    Container,
    Typography,
    Button,
    TextField,
    Snackbar,
    Alert,
    CircularProgress,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import rectangle from "../../img/svg/rectangle.png";
import ring from "../../img/svg/ring.png";
import { Check, Close } from "@mui/icons-material";

import logo from "../../img/icons/xaudit1.png";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const mdTheme = createTheme({
    typography: {
        fontFamily: ["League Spartan", "sans-serif"].join(","),
    },
});

const ResetPassword = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);
    const [hasLowercase, setHasLowercase] = useState(false);

    const [hasSpecialCase, setHasSpecialCase] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);

        setIsLengthValid(value.length >= 8);

        setHasUppercase(/[A-Z]/.test(value));

        setHasLowercase(/[a-z]/.test(value));
        setHasSpecialCase(/[!@#$%^&*(),.?":{}|<>]/.test(value));
    };
    const isAllPasswordConditionsMet = () => {
        return isLengthValid && hasUppercase && hasLowercase && hasSpecialCase;
    };
    const passwordRequirements = [
        {
            text: "Password length must be greater than 8",
            check: (password) => password.length >= 8,
        },
        {
            text: "Must have one special character",
            check: (password) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
        },
        {
            text: "Must have one uppercase character",
            check: (password) => /[A-Z]/.test(password),
        },
        {
            text: "Must have one lowercase character",
            check: (password) => /[a-z]/.test(password),
        },
    ];

    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        setButtonLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/update-password`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                password: password,
                email: state.email,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    navigate("/login", { state: { success: true } });
                    setButtonLoading(false);
                    setSuccessSnackbarOpen(true);
                    return response.json();
                } else {
                    setButtonLoading(false);
                    setErrorSnackbarOpen(true);
                    return response.json();
                }
            })
            .then((data) => {
                console.log(data);
                setErrorMessage(data.msg || data.message);
                setSuccessMessage(data.msg || data.message);
            });
    };
    useEffect(() => {
        if (state === null) {
            navigate("/login");
        }
    }, [navigate]);
    console.log(state);
    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <Box
                sx={{
                    width: "100vw",
                    mt: 7,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    component="img"
                    src={logo} // Replace with your image link
                    alt="Placeholder"
                    sx={{ marginBottom: 2, width: "100%", maxWidth: "300px", height: "auto" }}
                />
                <Container
                    maxWidth="sm"
                    sx={{
                        backgroundColor: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid black",
                    }}
                >
                    <Box sx={{ padding: "40px" }}>
                        <Typography sx={{ fontSize: "24px", fontWeight: "700", textAlign: "center" }}>
                            Create a new password
                        </Typography>
                        <Typography sx={{ fontSize: "14px", pb: 1, textAlign: "center" }}>
                            To secure your account, please enter a new password below.
                        </Typography>

                        <TextField
                            sx={{ pb: 2 }}
                            placeholder="New Password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={password}
                            onChange={handlePasswordChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                        />
                        {password && !isAllPasswordConditionsMet() && (
                            <ul style={{ listStyleType: "none", paddingLeft: 0, fontSize: "14px" }}>
                                {passwordRequirements.map((requirement, index) => {
                                    const isValid = requirement.check(password);
                                    return (
                                        <li
                                            key={index}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                color: isValid ? "green" : "red",
                                            }}
                                        >
                                            {isValid ? (
                                                <Check style={{ color: "green", marginRight: 8 }} />
                                            ) : (
                                                <Close style={{ color: "red", marginRight: 8 }} />
                                            )}
                                            {requirement.text}
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                        <TextField
                            placeholder="Confirm New Password"
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={password !== confirmPassword && confirmPassword !== ""} // Show error if passwords do not match
                            helperText={
                                password !== confirmPassword && confirmPassword !== "" ? "Passwords do not match" : ""
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                        >
                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                        />

                        {
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                onClick={submitHandler}
                                sx={{
                                    mt: 1,
                                    borderRadius: "6px",
                                    textTransform: "none",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    backgroundColor: "#481BEB",
                                }}
                                disabled={
                                    buttonLoading || !isAllPasswordConditionsMet() || confirmPassword !== password
                                }
                            >
                                {buttonLoading && <CircularProgress size={25} />}
                                {!buttonLoading && "Reset Password"}
                            </Button>
                        }
                    </Box>
                </Container>
                <hr style={{ border: "1px solid #ccc", width: "50%" }} />

                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                        paddingTop: "10px",
                        textAlign: "center",
                    }}
                >
                    If you have any questions or need assistance, feel free to contact our support team at
                    support@xemailverify.com.
                </Typography>
            </Box>

            <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default ResetPassword;
