import React, { useState, useEffect } from "react";
import { Button, Typography, Box, CircularProgress, Snackbar, Alert, Dialog } from "@mui/material";
import CopyIcon from "@mui/icons-material/ContentCopy";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Step2 = ({ onNext, onPrevious, onClose, domain, txt }) => {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [emails, setEmails] = useState([]);

    useEffect(() => {
        // Open the dialog box when the component mounts
        setDialogOpen(true);
    }, []);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };
    console.log(txt, "txt record is getting");

    const handlePrevious = () => {
        onPrevious();
    };

    function copy(code) {
        navigator.clipboard.writeText(code);
    }

    const handleVerify = () => {
        const confirmation = window.confirm(`Have you added the domain record to the postmaster?`);

        if (confirmation) {
            setButtonLoading(true);
            fetch(`${REACT_APP_SERVER_URL}/checkVerificationTxt`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
                method: "POST",
                body: JSON.stringify({
                    domain: domain,
                    verificationTxt: txt,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setButtonLoading(false);

                    if (data.status === 200) {
                        setEmails(emails.domain);
                        onNext(domain);
                    } else {
                        setErrorSnackbarOpen(true);
                        setErrorMessage("Verification Failed, Kindly add this domain to the postmaster first");
                        // alert('Verification Failed, Kindly add this domain to the postmaster')
                    }
                });
        }
    };

    return (
        <div className="step-container">
            <Dialog style={{ width: "100%", borderRadius: "20px" }} open onClose={onClose}>
                <Box>
                    <Box sx={{ borderBottom: "1px solid #f1f1f1", padding: "10px 15px", margin: "0px" }}>
                        <Typography style={{ fontSize: "20px", fontWeight: 600, lineHeight: "28px" }}>
                            Add Postmaster Domain
                        </Typography>
                    </Box>
                    <Box sx={{ borderBottom: "1px solid #f1f1f1", padding: "10px 15px" }}>
                        <Typography style={{ fontSize: "20px", fontWeight: 600, lineHeight: "28px", marginTop: "5px" }}>
                            2/2 – Domain Verification
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "16px",
                                color: "rgba(71, 84, 103, 1)",
                                marginLeft: "-1px",
                                fontWeight: 400,
                                lineHeight: "20px",
                            }}
                            variant="body1"
                            id="text"
                        >
                            {" "}
                            Verify your ownership of {domain}
                        </Typography>
                        <>
                            <ol style={{ marginRight: "5px" }}>
                                <li
                                    style={{
                                        fontSize: "16px",
                                        marginLeft: "-23px",
                                        marginTop: "-5px",
                                        fontWeight: 500,
                                        lineHeight: "21px",
                                    }}
                                >
                                    Add the TXT to the DNS configuration for {domain}
                                </li>
                                <p
                                    style={{
                                        marginRight: "10px",
                                        marginTop: "10px",
                                        marginLeft: "-38px",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        lineHeight: "16.38px",
                                    }}
                                >
                                    TXT record:
                                    <Typography
                                        sx={{
                                            fontSize: "15px",
                                            border: "1px solid black",
                                            borderRadius: "4px",
                                            padding: "12px",
                                            width: "103%",
                                            marginTop: "2px",
                                        }}
                                    >
                                        {txt}
                                    </Typography>
                                    <CopyIcon
                                        sx={{ marginLeft: 66, marginTop: "-100%", height: "3.2em" }}
                                        titleAccess="copy to clipboard"
                                        cursor="pointer"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSuccessSnackbarOpen(true);
                                            setSuccessMessage("Code Copied");
                                            copy(txt);
                                        }}
                                    ></CopyIcon>
                                </p>
                                <li
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: 500,
                                        lineHeight: "21px",
                                        marginTop: "-15px",
                                        marginLeft: "-20px",
                                    }}
                                >
                                    Click Verify
                                </li>
                            </ol>
                        </>
                        <Typography
                            style={{
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "rgba(71, 84, 103, 1)",
                                lineHeight: "22px",
                                marginTop: "-7px",
                            }}
                        >
                            When Google finds the DNS record that you have added, we will verify your ownership of the
                            domain. To stay verified, do not remove the DNS record, even after the verification
                            succeeds. (DNS changes may take some time and if we do not find the record immediately, we
                            will check for it periodically.)
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "22px",
                                color: "rgba(71, 84, 103, 1)",
                                marginTop: "10px",
                            }}
                        >
                            Having trouble? Try adding a CNAME record as an alternative option.
                        </Typography>
                        <Box
                            sx={{
                                borderRadius: "10px",
                                marginTop: "10px",
                                background: "rgba(237, 244, 251, 1)",
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "22px",
                                    color: "rgba(71, 84, 103, 1)",
                                    padding: "10px",
                                }}
                            >
                                Note: <br />
                                After successful verification, your account will also have access to the domain's data
                                on the Google Search Console.
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", padding: "5px 15px" }}>
                        <div style={{marginLeft:"55%"}}>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handlePrevious}
                                sx={{ width: "100px", fontsize: "14px", margin: "10px", cursor: "pointer" }}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleVerify}
                                disabled={buttonLoading}
                                sx={{
                                    width: "100px",
                                    fontsize: "14px",
                                    margin: "10px",
                                    cursor: "pointer",
                                    backgroundColor: "#004aad",
                                }}
                            >
                                {buttonLoading ? <CircularProgress size={24} /> : "Next"}
                            </Button>
                        </div>
                    </Box>
                </Box>
            </Dialog>
            <>
                <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                    <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
                    <Alert
                        onClose={handleSuccessSnackbarClose}
                        variant="filled"
                        severity="success"
                        sx={{ width: "100%" }}
                    >
                        {successMessage}
                    </Alert>
                </Snackbar>
            </>
        </div>
    );
};

export default Step2;
