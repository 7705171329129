import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, Button, Box } from "@mui/material";
import HtmlPreview from "./HtmlPreview"; // Import the preview component
import "./ManualAudit.css";

const ClientContentDialog = ({ open, content, onClose, onSubmit }) => {
    const [clientData, setClientData] = useState(content || { subject: "", body: "" });
    const [errors, setErrors] = useState({});
    const [showPreview, setShowPreview] = useState(false); // State to toggle preview

    const handleSubmit = () => {
        if (!clientData.subject || clientData.subject.trim() === "") {
            setErrors((prev) => ({ ...prev, subject: "Subject is required" }));
        } else if (!clientData.body || clientData.body.trim() === "") {
            setErrors((prev) => ({ ...prev, body: "Body is required" }));
        } else {
            setErrors({});
            onSubmit(clientData);
        }
    };

    const handleChange = (data, name) => {
        setClientData((prev) => ({ ...prev, [name]: data }));
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="domain-list-dialog" fullWidth maxWidth="sm">
            <DialogTitle id="domain-list-dialog" className="manual-dialog-title" sx={{ marginTop: "10px" }}>
                Enter your content
            </DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Subject"
                    value={clientData.subject}
                    onChange={(e) => handleChange(e.target.value, "subject")}
                    margin="normal"
                    variant="outlined"
                    error={!!errors.subject}
                    helperText={errors.subject}
                />
                <TextField
                    fullWidth
                    label="Body"
                    value={clientData.body}
                    onChange={(e) => handleChange(e.target.value, "body")}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={15}
                    error={!!errors.body}
                    helperText={errors.body}
                />
                {/* Preview Section */}
                <Box mt={2}>
                    <Button variant="outlined" onClick={() => setShowPreview(!showPreview)}>
                        {showPreview ? "Hide Preview" : "Show Preview"}
                    </Button>
                    {showPreview && <HtmlPreview htmlContent={clientData.body} />}
                </Box>
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Analyze Content
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ClientContentDialog;
