import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
    Box,
    Button,
    TextField,
    Typography,
    TableContainer,
    TableBody,
    Table,
    TableHead,
    TableRow,
    CircularProgress,
    Snackbar,
    Alert,
    Pagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F8F9FB",
        fontSize: "16px",
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
function DomainNameGenerator() {
    const navigate = useNavigate();
    const [domain, setDomain] = useState("");
    const [res, setRes] = useState([]);
    const [generateLoader, setGenerateLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [page, setPage] = useState(1);
    const rowsPerPage = 10;
    const handleChangePage = (event, value) => {
        setPage(value);
    };
    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") return;
        setErrorSnackbarOpen(false);
    };

    function generateDomain() {
        if (!domain) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Please enter a domain name.");
            return;
        }
        setGenerateLoader(true);
        fetch(`${REACT_APP_SERVER_URL}/suggestion?domain=${domain}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    setRes(data.data);
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                }
                setGenerateLoader(false);
            });
    }

    return (
        <>
            <Button
                sx={{ margin: "15px" }}
                onClick={() => {
                    navigate(-1);
                }}
            >
                <ArrowBackIos />
                Back
            </Button>
            <Box sx={{ width: "100%", textAlign: "center" }}>
                <Typography style={{ fontSize: "24px", fontWeight: 700 }}>Domain Name Generator</Typography>
                <Typography
                    style={{
                        textAlign: "center",
                        fontSize: "18px",
                        color: "rgba(71, 84, 103, 1)",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        margin: "10px",
                    }}
                >
                    Get creative domain name ideas. Tell us about your idea and Domain Search will create a targeted
                    list of domain names for you.
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    <TextField
                        label="Enter Domain Name"
                        variant="outlined"
                        size="small"
                        onChange={(e) => setDomain(e.target.value)}
                    />

                    <Button variant="contained" disabled={generateLoader} onClick={generateDomain} sx={{ ml: 2 }}>
                        Generate
                    </Button>
                </Box>
                {generateLoader && <CircularProgress sx={{ mt: 2 }} />}
                {res.length > 0 && (
                    <>
                        <TableContainer sx={{ borderRadius: "10px", border: "1px solid #E7E7E7", mt: 2 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Generated Domain</StyledTableCell>
                                        <StyledTableCell>Availability</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {res.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((domain, i) => (
                                        <TableRow key={i}>
                                            <StyledTableCell>{domain.domain}</StyledTableCell>

                                            <StyledTableCell sx={{ color: domain.available ? "green" : "red" }}>
                                                {domain.available ? "Available" : "Not Available"}
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {res.length > 10 && (
                            <Pagination
                                count={Math.ceil(res.length / rowsPerPage)}
                                page={page}
                                onChange={handleChangePage}
                                color="primary"
                                variant="outlined"
                                sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                            />
                        )}
                    </>
                )}
                <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                    <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error">
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </>
    );
}

export default DomainNameGenerator;
