import React, { useState } from "react";
import { Box, Button, Typography, Container, Paper } from "@mui/material";
import XAuditLogo from "../../img/icons/xaudit1.png";
import { useNavigate } from "react-router-dom";
import LockClockIcon from "@mui/icons-material/LockClock";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SessionExpired = ({ email, teamSession, type }) => {
    const navigate = useNavigate();
    const [hideButton, setHideButton] = useState(false);
    const submitHandler = (e) => {
        e.preventDefault();
        setHideButton(true);
        fetch(`${REACT_APP_SERVER_URL}/resendemailtoken`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                email: email,
                type: "register",
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    navigate(`/account-verification?email=${email}`);
                } else {
                    setHideButton(false);
                }
            });
    };
    return (
        <Box>
            <Box
                component="img"
                src={XAuditLogo} // Replace with your image link
                alt="Session Expired"
                sx={{ marginBottom: 2, width: "15%" }}
            />

            <Container
                maxWidth="md"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box style={{ textAlign: "center" }}>
                    <LockClockIcon sx={{ minWidth: "80px", height: "auto" }} />
                    <Typography sx={{ fontSize: "30px", fontWeight: "700", textAlign: "center" }}>
                        Session Expired!
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        It looks like your verification link has expired. For security reasons, verification links are
                        only valid for a limited time.
                    </Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                        But don’t worry—you can easily request a new verification email!
                    </Typography>

                    {!teamSession && type !== "forgotpassword" && (
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{ marginTop: "20px" }}
                            onClick={submitHandler}
                            disabled={hideButton}
                        >
                            Resend Verification Email
                        </Button>
                    )}

                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ marginTop: "20px", borderTop: "1px solid #ccc", paddingTop: "10px" }}
                    >
                        If you have any questions or need assistance, feel free to contact our support team at
                        support@xemailverify.com.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default SessionExpired;
