import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Box, Container, Typography, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import xCampaignLogo from "../../img/icons/xCampaignLogo.png";
import HomeButton from "../../img/icons/HomeButton.png";
import rectangle from "../../img/svg/rectangle.png";
import ring from "../../img/svg/ring.png";

const mdTheme = createTheme({
    typography: {
        fontFamily: ["League Spartan", "sans-serif"].join(","),
    },
});
const Success = () => {
    const navigate = useNavigate();
    const submitHandler = () => {
        navigate("/dashboard/DnsRecords");
    };
    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <Box sx={{ width: "100vw" }}>
                <Link exact to="/">
                    <img src={HomeButton} alt="/" style={{ cursor: "pointer" }} />
                </Link>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <img src={xCampaignLogo} alt="/" />
            </Box>
            <Box
                sx={{
                    width: "100vw",
                    mt: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                }}
            >
                <Container
                    maxWidth="xs"
                    sx={{
                        zIndex: 10,
                        backgroundColor: "#fff",
                        p: 5,
                        px: 4,
                        boxShadow: "0px 32px 100px rgba(50, 47, 85, 0.1)",
                        borderRadius: "65px",
                        padding: "100px",
                    }}
                >
                    <Typography
                        sx={{ fontSize: "24px", fontWeight: "700", pb: 3, color: "#1197CA", textAlign: "center" }}
                    >
                        Payment Successful
                    </Typography>
                    {/* <Button
                                type='submit'
                                variant='contained'
                                fullWidth
                                sx={{ mt: 1, borderRadius: "6px", textTransform: "none", fontSize: "14px", fontWeight: "700", backgroundColor: "#481BEB" }}
                            >
                                Send Reset Link
                            </Button> */}
                    {
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={submitHandler}
                            sx={{
                                mt: 1,
                                borderRadius: "6px",
                                textTransform: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                                backgroundColor: "#481BEB",
                            }}
                        >
                            Go To Dashboard
                        </Button>
                    }
                </Container>
                <svg
                    width="47"
                    height="47"
                    viewBox="0 0 47 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ position: "absolute", left: "530px", top: "180px", zIndex: 1 }}
                >
                    <circle cx="23.5" cy="23.5" r="23.5" fill="#FFE163" />
                </svg>
                <img src={rectangle} alt="/" style={{ position: "absolute", top: "480px", left: "460px" }} />
                <img src={ring} alt="/" style={{ position: "absolute", top: "500px", left: "1200px" }} />
            </Box>
        </ThemeProvider>
    );
};

export default Success;
