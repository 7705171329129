import React from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import "./CardComponent.css"; 

const CardComponent = ({ title, typography, image, type, onCardClick }) => {
    return (
        <Card className="integration-card">
            <CardContent className="integration-card-content">
                <div className="integration-card-image-container">
                    <img
                        src={image}
                        alt={title}
                        className="integration-card-image"
                    />
                </div>
                <Typography
                    variant="h6"
                    component="div"
                    className="integration-card-title"
                >
                    {title}
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    className="integration-card-typography"
                >
                    {typography}
                </Typography>
                <Button
                    onClick={() => onCardClick(type)}
                    variant="contained"
                    className="integration-card-button"
                >
                    Add new
                </Button>
            </CardContent>
        </Card>
    );
};

export default CardComponent;
