import React, { useEffect, useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import "../DomainReputation/domainreputation.css";
import {
    Box,
    Paper,
    Button,
    Typography,
    CardContent,
    Dialog,
    DialogActions,
    DialogTitle,
    InputBase,
    Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { Grid, TableRow, TableCell, CircularProgress, Snackbar, Alert } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Delete, Verified } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Outlet } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: "#f1f1f1",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#004aad",
        color: theme.palette.common.white,
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
        padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        textAlign: "center",
        padding: "10px",
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    })
);

const DomainReputation = () => {
    const [step, setStep] = useState(0);
    const [selectedDomain, setSelectedDomain] = useState("");
    const [txt, setTxt] = useState("");
    const [addDomain, setAddDomain] = useState(false);
    const [domain, setDomain] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteIpDialougeOpen, setdDeleteIpDialougeOpen] = React.useState(false);
    const [Loader, setLoader] = useState(false);
    const [deleteId, setdeleteId] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [userInput, setUserInput] = useState("");
    const [userClientNames, setUserClientNames] = useState([]);
    const [filterInputData, setFilterInputData] = React.useState("All");
    const [selectedDate, setSelectedDate] = useState(null);
    const [stroreClient, setStoreClient] = useState(null);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    useEffect(() => {
        // FetchDomains();
        findClientNames();
    }, [step]);

    const FetchDomains = (e) => {
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/domainslist`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setDomain(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    };

    const findClientNames = () => {
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/findClientNames`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setUserClientNames(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    };

    // function handleDomainClick(domain,clientName) {
    //   console.log(domain, "??????????")
    //   if (domain.status === "Verified") {
    //     window.location.replace(`${process.env.REACT_APP_URL}/dashboard/features/${domain.domain}${clientName}`);
    //   } else {
    //     console.log('%cNot verified', 'color: red'); // Change font color to red
    //   }

    // }

    const handleNextStep = (domain, txt) => {
        setAddDomain(true);
        setStep(step + 1);
        setSelectedDomain(domain);
        setTxt(txt);
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
    };

    const handleClose = () => {
        setAddDomain(false);
        setStep(0);
    };

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return <Step1 onNext={handleNextStep} onClose={handleClose} />;
            case 2:
                return (
                    <Step2
                        onNext={handleNextStep}
                        onPrevious={handlePreviousStep}
                        onClose={handleClose}
                        domain={selectedDomain}
                        txt={txt}
                    />
                );
            case 3:
                return <Step3 onPrevious={handlePreviousStep} onClose={handleClose} domain={selectedDomain} />;
            default:
                return null;
        }
    };

    // function formatDate(inputDate) {
    //   const months = [
    //     'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    //     'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    //   ];

    //   const parts = inputDate.split('-');
    //   if (parts.length === 3) {
    //     const day = parseInt(parts[0], 10);
    //     const monthIndex = parseInt(parts[1], 10) - 1;
    //     const year = parseInt(parts[2], 10);

    //     if (!isNaN(day) && !isNaN(monthIndex) && !isNaN(year)) {
    //       if (monthIndex >= 0 && monthIndex < months.length) {
    //         const monthName = months[monthIndex];
    //         return `${day} ${monthName} ${year}`;
    //       }
    //     }
    //   }

    //   return 'Invalid Date';
    // }

    // function DeleteIpHandle(e) {
    //   let domain = e;
    //   setLoader(true);
    //   fetch(`${REACT_APP_SERVER_URL}/DeleteDomainRep`, {
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/x-www-form-urlencoded",

    //     },
    //     method: "POST",
    //     credentials: "include",
    //     body: new URLSearchParams({
    //       "domain": domain
    //     })

    //   }).then(response => {
    //     return response.json();
    //   }).then(data => {
    //     setLoader(false);
    //     if (data.status === 200) {
    //       setSuccessSnackbarOpen(true);
    //       setSuccessMessage('Domain Deleted Successfully');
    //       // FetchDomains();
    //       handleDomainList(stroreClient)
    //     }
    //     else {
    //       setErrorSnackbarOpen(true);
    //       setErrorMessage('Internal server error');
    //     }
    //   })

    // }

    // function oneClickVerify(d) {

    //   let checkDomain = d;
    //   setLoading(true);
    //   fetch(`${REACT_APP_SERVER_URL}/checkVerificationTxt`, {
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     credentials: "include",
    //     method: "POST",
    //     body: JSON.stringify({
    //       domain: checkDomain,
    //     })
    //   })
    //     .then(response => {
    //       setLoading(false);
    //       return response.json()
    //     }).then(data => {
    //       setLoading(false);

    //       if (data.status === 200) {
    //         // onNext(domain)
    //         setSuccessSnackbarOpen(true);
    //         setSuccessMessage('Verified successfully');
    //         FetchDomains();

    //       }
    //       else {
    //         setErrorSnackbarOpen(true);
    //         setErrorMessage('Verification Failed, Kindly add this domain to the postmaster first')
    //         FetchDomains();
    //         // alert('Verification Failed, Kindly add this domain to the postmaster')
    //       }

    //     })
    // }

    // function inputHanlder(e) {

    //   let input = e.target.value.toLowerCase();
    //   setUserInput(input);

    // }

    // const filterData = domain.filter((d) => {
    //   const isDomainMatch = d.domain.toLowerCase().includes(userInput.toLowerCase());
    //   if (filterInputData === 'All') {
    //     return isDomainMatch;
    //   }
    //   if (filterInputData === 'Verified') {
    //     return d.status === 'Verified' && isDomainMatch;
    //   }
    //   if (filterInputData === 'Unverified') {
    //     return d.status !== 'Verified' && isDomainMatch;
    //   }
    //   if (filterInputData === 'Date' && selectedDate) {
    //     const domainDate = dayjs(d.create_time, 'D-M-YYYY');
    //     return domainDate.isValid() && domainDate.isSame(selectedDate, 'day') && isDomainMatch;
    //   }
    //   return false;
    // });

    // const handleChange = (e) => {
    //   setFilterInputData(e.target.value)
    // };

    // const handleDateChange = (newValue) => {
    //   setSelectedDate(newValue);
    //   setFilterInputData('Date');
    // };

    // const clientNamefilterData = userClientNames.filter((d) => {
    //   if (userInput === '') {
    //     return d;
    //   }
    //   else {
    //     return d.clientName.toLowerCase().includes(userInput);
    //   }
    // })

    // async function handleDomainList(client) {
    //   console.log(client, "hello")
    //   fetch(`${REACT_APP_SERVER_URL}/findingDomainListWithClientName?ClientName=${client}`, {

    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/x-www-form-urlencoded",
    //     },
    //     method: "GET",
    //     credentials: "include",
    //   }).then(response => {
    //     setLoading(false);
    //     return response.json();
    //   })
    //     .then(data => {

    //       console.log(data, 'getting data a rha hai ')
    //       if (data.status === 200) {
    //         setDomain(data.data);
    //       }
    //       else {
    //         console.log(data.message, 'Error Fetching domains')
    //       }
    //     })
    //     .catch(err => {
    //       console.log(err, 'Error Fetching domains')
    //     })
    // }

    // async function back() {
    //   setDomain([])
    // }

    // let sendingValues = [
    //   {
    //     label: 'Date'
    //   }, {
    //     label: 'Verified'
    //   }, {
    //     label: 'Not verified'
    //   }
    // ]

    // async function filterFunctionGetting(e, newValue) {
    //   console.log('working here button');
    //   console.log(e, newValue, 'value')
    // }

    return (
        <Container>
            <Box>
                <Box sx={{ textAlign: "center" }}>
                    <span style={{ fontSize: "30px", fontWeight: "1200px", lineHeight: "38px" }}>
                        Domain/IP Reputation
                    </span>
                    <br />
                    <span
                        style={{
                            fontSize: "18px",
                            color: "rgba(71, 84, 103, 1)",
                            fontWeight: "400px",
                            lineHeight: "24px",
                        }}
                    >
                        Discover the reputation of your domain with ease.
                    </span>
                </Box>
                <br />

                {step === 0 && (
                    <Grid sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                        <Grid item xs={6} md={8}>
                            <Typography
                                style={{
                                    color: "#101828",
                                    fontSize: "20px",
                                    fontWeight: "600px",
                                    lineHeight: "28px",
                                }}
                            >
                                <b>Domain Reputation</b>
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: "18px",
                                    color: "rgba(71, 84, 103, 1)",
                                    lineHeight: "20px",
                                    fontWeight: 400,
                                }}
                            >
                                {" "}
                                Are you seen as trustworthy by email providers, or is there room for improvement? Our
                                tool provides a comprehensive overview empowering you to grasp how your reputation
                                influences the success of your email campaigns.
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Button
                                variant="contained"
                                onClick={handleNextStep}
                                startIcon={<AddIcon />}
                                sx={{
                                    textTransform: "none",
                                    fontSize: "15px",
                                    fontWeight: "700",
                                    backgroundColor: "#004aad",
                                }}
                            >
                            Domain
                            </Button>
                        </Grid>
                    </Grid>
                )}
                {addDomain && (
                    <Box sx={{ marginLeft: "22%", marginTop: "100px" }}>
                        <Paper elevation={3} style={{ width: "55%", padding: "10px" }}>
                            {renderStepContent()}
                        </Paper>
                    </Box>
                )}
                <Outlet />
            </Box>

            <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default DomainReputation;
