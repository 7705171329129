import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
    Box,
    CardContent,
    Card,
    Button,
    TableContainer,
    TableBody,
    Table,
    TableHead,
    TableRow,
    Typography,
    Snackbar,
    Alert,
} from "@mui/material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F8F9FB",
        fontSize: "16px",
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

function EmailHeaderAnalyze() {
    const navigate = useNavigate();
    const [emailHeader, setEmailHeader] = useState("");
    const [resHeader, setResHeader] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") return;
        setErrorSnackbarOpen(false);
    };

    async function analyze(e) {
        e.preventDefault();
        let lines = emailHeader.split("\n");
        let headers = [];

        lines.forEach((line) => {
            let colonIndex = line.indexOf(":");
            if (colonIndex !== -1) {
                let headerName = line.substring(0, colonIndex).trim();
                let headerValue = line.substring(colonIndex + 1).trim();
                headers.push({ header: headerName, value: headerValue });
            }
        });

        if (headers.length === 0) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Bad header or input field is empty");
        } else {
            setResHeader(headers);
        }
    }

    return (
        <>
            <Button
                sx={{ margin: "15px" }}
                onClick={() => {
                    navigate(-1);
                }}
            >
                <ArrowBackIos />
                Back
            </Button>
            <Box sx={{ width: "100%", textAlign: "center" }}>
                <Box>
                    <Typography style={{ fontSize: "24px", fontWeight: 700 }}>Email Header Analyzer</Typography>{" "}
                    <Typography
                        style={{
                            textAlign: "center",
                            fontSize: "18px",
                            color: "rgba(71, 84, 103, 1)",
                            fontWeight: "400px",
                            lineHeight: "24px",
                            margin: "10px",
                        }}
                    >
                        Email Header lets you analyze your email with its source, authentication details. Copy your
                        original email to analyze them in human readable format.
                    </Typography>
                </Box>
                <Card
                    sx={{ width: "100%", borderRadius: "10px", padding: "10px", backgroundColor: "whitesmoke", mt: 2 }}
                >
                    <CardContent>
                        <textarea
                            onChange={(e) => setEmailHeader(e.target.value)}
                            style={{ width: "100%", height: "400px" }}
                        ></textarea>
                        <Button variant="contained" sx={{ float: "right", mt: 2 }} onClick={analyze}>
                            Analyze header
                        </Button>
                    </CardContent>
                    {resHeader.length > 0 && (
                        <TableContainer sx={{ borderRadius: "10px", border: "1px solid #E7E7E7", mt: 2 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Key</StyledTableCell>
                                        <StyledTableCell>Value</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {resHeader.map((data, i) => (
                                        <TableRow key={i}>
                                            <StyledTableCell>{data.header}</StyledTableCell>
                                            <StyledTableCell>{data.value}</StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Card>
                <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                    <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error">
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </>
    );
}

export default EmailHeaderAnalyze;
