import { useContext } from "react";
import { AuthContext } from "../../Provider/AuthProvider";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children, title }) => {
    const { isExpiredPlan } = useContext(AuthContext);
    console.log("title", title);
    const navigate = useNavigate();
    if (isExpiredPlan) {
        navigate("/billing");
    }

    return children;
};

export default ProtectedRoute;
