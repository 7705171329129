// import { useLayoutEffect } from "react";
// import * as am5 from "@amcharts/amcharts5";
// import * as am5xy from "@amcharts/amcharts5/xy";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

// function Chart(props) {
//     useLayoutEffect(() => {
//         let root = am5.Root.new("chartdiv");
//         root._logo.dispose()

//         root.setThemes([am5themes_Animated.new(root)]);

//         let chart = root.container.children.push(
//             am5xy.XYChart.new(root, {
//                 panY: false,
//                 layout: root.verticalLayout,
//             })
//         );

//         // Define data
//         let data = [
//             {
//                 category: "Research",
//                 value1: 1000,
//                 value2: 588,
//             },
//             {
//                 category: "Marketing",
//                 value1: 1200,
//                 value2: 1800,
//             },
//         ];

//         // Create Y-axis
//         let yAxis = chart.yAxes.push(
//             am5xy.ValueAxis.new(root, {
//                 renderer: am5xy.AxisRendererY.new(root, {}),
//             })
//         );

//         // Create X-Axis
//         let xAxis = chart.xAxes.push(
//             am5xy.CategoryAxis.new(root, {
//                 renderer: am5xy.AxisRendererX.new(root, {}),
//                 categoryField: "category",
//             })
//         );
//         xAxis.data.setAll(data);

//         // Create series
//         let series1 = chart.series.push(
//             am5xy.ColumnSeries.new(root, {
//                 name: "Inbox",
//                 xAxis: xAxis,
//                 yAxis: yAxis,
//                 valueYField: "value1",
//                 categoryXField: "category",
//             })
//         );
//         series1.data.setAll(data);

//         let series2 = chart.series.push(
//             am5xy.ColumnSeries.new(root, {
//                 name: "Spam",
//                 xAxis: xAxis,
//                 yAxis: yAxis,
//                 valueYField: "value2",
//                 categoryXField: "category",
//             })
//         );
//         series2.data.setAll(data);
//         series1.columns.template.setAll({
//             width:20
//         })
//         series2.columns.template.setAll({
//             width: 20,
//         });


//         // Add legend
//         let legend = chart.children.push(am5.Legend.new(root, {}));
//         legend.data.setAll(chart.series.values);

//         // Add cursor
//         chart.set("cursor", am5xy.XYCursor.new(root, {}));

//         return () => {
//             root.dispose();
//         };
//     }, []);

//     return <div id="chartdiv" style={{ width: "550px", height: "300px" }}></div>;
// }
// export default Chart;
// BarChart.js
// src/components/BarChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ data, options }) => {
  return (
    <div style={{ width: '500px' ,height:'300px'}}>
      <Bar data={data} options={options}  height={300} width={500}/>
    </div>
  );
};

export default BarChart;

