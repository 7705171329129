import React, { useState, useEffect } from "react";
import { Snackbar, Alert, CircularProgress } from "@mui/material";
import { TextField, Button, Typography, Box, Dialog, DialogTitle } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import '../DomainReputation/domainreputation.css'
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Step1 = ({ onNext, onClose }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [domain, setDomain] = useState("");
    const [client, setClient] = useState("");
    const [buttonLoading, setButtonLoading] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);

    useEffect(() => {
        // Open the dialog box when the component mounts
        setDialogOpen(true);
    }, []);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrorSnackbarOpen(false);
    };

    const [buttonStyles, setButtonStyles] = useState({
        padding: "10px 20px",
        backgroundColor: "#fffff",
        color: "black",
        borderRadius: "5px",
        cursor: "pointer",
    });
    const handleMouseEnter = () => {
        setButtonStyles((prevStyles) => ({
            ...prevStyles,
            backgroundColor: "#3652AD",
        }));
    };
    const handleMouseLeave = () => {
        setButtonStyles((prevStyles) => ({
            ...prevStyles,
            backgroundColor: "#fffff",
        }));
    };

    // const [txt, setTxt] = useState("");

    const handleNext = () => {
        onNext();
    };
    const addDomain = () => {
        if (!client) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Please enter the client Name which wants to associated with domains.");
            return;
        }

        if (!domain) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Please enter the domain.");
            return;
        }

        if (domain && domain.length < 4) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Enter Valid domain.");
            return;
        }
        setButtonLoading(true);
        console.log(domain, "domain is getting");
        fetch(`${REACT_APP_SERVER_URL}/addDomain`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                domain: domain,
                client: client,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    onNext(domain, data.txt);
                } else {
                    setButtonLoading(false);
                    setErrorSnackbarOpen(true);
                    setErrorMessage("Invalid Domain");
                }
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        addDomain();
    };

    return (
        <Box>
            <Dialog open onClose={onClose} style={{ width: "100%", borderRadius: "20px" }}>
                <Box>
                    <DialogTitle style={{ borderBottom: "1px solid #f1f1f1", padding: "10px 15px" }}>
                        <Box sx={{}}>
                            <Typography
                                sx={{
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    lineHeight: "28px",
                                }}
                            >
                                {" "}
                                Add postmaster Domain
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <Box sx={{ marginTop: "0px", padding: "8px 15px", borderBottom: "1px solid #f1f1f1" }}>
                        <Typography sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "28px" }} variant="h6">
                            Step 1/3 – Getting Started
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "16px",
                                fontWeight: 500,
                                lineHeight: "21px",
                                marginTop: "10px",
                                color: "rgba(71, 84, 103, 1)",
                            }}
                            variant="body1"
                        >
                            What domain do you use to authenticate your email? Enter a domain.
                        </Typography>

                        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            <TextField
                                fullWidth
                                label="Enter the domain*"
                                variant="outlined"
                                value={domain}
                                size="small"
                                onChange={(e) => setDomain(e.target.value)}
                                style={{ marginBottom: "1rem", borderradius: "4px", margin: "5px" }}
                            />
                            <TextField
                                fullWidth
                                label="Client Name*"
                                variant="outlined"
                                value={client}
                                size="small"
                                onChange={(e) => setClient(e.target.value)}
                                style={{ marginBottom: "1rem", borderradius: "4px", margin: "5px" }}
                            />
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                justifyItems: "center",
                                alignItems: "center",
                                alignContent: "center",
                                marginTop: "10px",
                                padding: "8px 0px",
                            }}
                        >
                            <HelpOutlineIcon
                                sx={{ marginRight: "5px", marginTop: "-18px", color: "rgba(71, 84, 103, 1)" }}
                            />
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "21px",
                                    marginTop: "10x",
                                    color: "rgba(71, 84, 103, 1)",
                                }}
                                variant="body1"
                            >
                                Enter the domain used to authenticate your mail with SPF or DKIM. Refer to the Help page
                                for more details
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ marginLeft: "60%", display: "flex", marginTop: "15px" }}>
                        <Button
                            variant="outlined"
                            color="error"
                            sx={{
                                width: "100px",
                                height: "35px",
                                fontsize: "14px",
                                cursor: "pointer",
                                margin: "10px",
                            }}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                width: "100px",
                                height: "35px",
                                fontsize: "14px",
                                backgroundColor: "#004aad",
                                letterspacing: "2.5px",
                                fontweight: "500",
                                border: "1px solid rgba(71, 84, 103, 1)",
                                borderradius: "45px",
                                boxshadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",

                                cursor: "pointer",
                                outline: "none",
                                margin: "10px",
                            }}
                            disabled={buttonLoading}
                            onClick={handleSubmit}
                        >
                            {buttonLoading ? <CircularProgress size={24} /> : "Next"}
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Step1;
