import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

export default function AuditDialog({open , onClose, onSubmit}) {

  return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
        The audit report has already been generated. Would you like to regenerate it?
        </DialogContent>
        <DialogActions>
          <Button  onClick={onClose}>No</Button>
          <Button onClick={onSubmit} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
  );
}
