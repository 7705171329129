import React, { useEffect } from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import XAuditLogo from "../../img/icons/xaudit1.png";
const PaymentSuccess = () => {
    const handleGoHome = () => {
        window.location.href = "/";
    };
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/refreshAuthToken`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((res) => res.json())
            .then((data) => console.log(data));
    }, []);
    return (
        <Box>
            <Box
                component="img"
                src={XAuditLogo} // Replace with your image link
                alt="Payment Success"
                sx={{ marginBottom: 2, width: "15%" }}
            />

            <Container maxWidth="sm" style={{ textAlign: "center", padding: "50px" }}>
                <CheckCircleIcon color="success" style={{ fontSize: 60 }} />
                <Typography variant="h4" gutterBottom>
                    Payment Successful!
                </Typography>
                <Typography variant="body1" paragraph>
                    Thank you for your payment. Your transaction has been successfully processed.
                </Typography>
                <Button variant="contained" color="primary" onClick={handleGoHome}>
                    Go to Homepage
                </Button>
            </Container>
        </Box>
    );
};

export default PaymentSuccess;
