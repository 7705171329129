import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import "./ipblacklist.css";
import Link from "@mui/material/Link";
import { ReactComponent as Back } from "./back-arrow.svg";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import {
    Box,
    Button,
    TableRow,
    TableCell,
    Typography,
    Stack,
    CircularProgress,
    Snackbar,
    Alert,
    Dialog,
    DialogActions,
    DialogTitle,
    CardContent,
    TableContainer,
    Paper,
    TableBody,
    Table,
    TableHead,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpIcon from "@mui/icons-material/Help";
import TextField from "@mui/material/TextField";
import Pagination from "@mui/material/Pagination";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F8F9FB",
        color: theme.palette.primary.black,
        fontSize: "1rem",
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "0.875rem",
    },
}));

const IpBlacklist = () => {
    const navigate = useNavigate();
    const [ipAddress, setIpAddress] = useState("");
    const [isBlacklisted, setIsBlacklisted] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [LoadingcheckIp, setLoadingcheckIp] = useState(false);
    const [records, setRecords] = useState([]);
    const [ip, setIp] = useState("");
    const [allIptable, setTable] = useState(true);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [notifiFeature, setNotifiFeature] = useState("none");
    const [slackshow, setSlackshow] = useState(false);
    const [slackurl, setslackurl] = useState("");
    const [successMsg, setsuccessMsg] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [notifibox, setNotifiBox] = useState(true);
    const [currentpage, setcurrentpage] = useState(1);
    const [totalpage, setTotalpage] = useState();
    const [deleteIpDialougeOpen, setdDeleteIpDialougeOpen] = React.useState(false);
    const [deleteId, setdeleteId] = React.useState("");
    const [Loading1, setLoading1] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        handleCheckIP();
    };
    const [detailedPage, setDetailedPage] = useState(1);
    const rowsPerDetailedPage = 10;
    const handleChangeDetailedPage = (event, value) => {
        setDetailedPage(value);
    };
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(even)": {
            backgroundColor: theme.palette.action.hover,
        },
    }));

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSuccessSnackbarOpen(false);
    };

    useEffect(() => {
        recordData();
        // setLoading(true);
    }, [currentpage]);

    const handleCheckIP = (e) => {
        setNotifiBox(false);
        setLoadingcheckIp(true);
        setTable(false);
        e.preventDefault();

        if (ipAddress.length > 3) {
            try {
                e.preventDefault();

                fetch(`${REACT_APP_SERVER_URL}/check?ip=${ipAddress}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "GET",
                    credentials: "include",
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data.status === 200) {
                            setLoadingcheckIp(false);
                            setIp(data.ipaddress);
                            setIsBlacklisted(data.data);
                            setLoadingcheckIp(false);
                        } else {
                            setLoadingcheckIp(false);
                            setTable(true);
                            setErrorSnackbarOpen(true);
                            setErrorMessage("No record found for IP/Domain or Invalid IP/Domain");
                        }
                    });
            } catch (err) {
                console.log(err, "Error in Checking Ip Blacklist");
            }
            return;
        } else {
            setLoadingcheckIp(false);
            setErrorSnackbarOpen(true);
            setErrorMessage("No record found for IP/Domain or Invalid IP/Domain");
            setTable(true);
        }
    };

    const handleChange = (event, value) => {
        setcurrentpage(value);
    };

    function Date(data) {
        let a = data;
        let d = "";
        let b = `${a[5] + a[6]}-${a[8] + a[9]}-${a[0] + a[1] + a[2] + a[3]}`;
        let datas = b.slice(0, 2);
        switch (datas) {
            case "01":
                d = "Jan";
                break;
            case "02":
                d = "Feb";
                break;
            case "03":
                d = "Mar";
                break;
            case "04":
                d = "Apr";
                break;
            case "05":
                d = "May";
                break;
            case "06":
                d = "Jun";
                break;
            case "07":
                d = "Jul";
                break;
            case "08":
                d = "Aug";
                break;
            case "09":
                d = "Sep";
                break;
            case "10":
                d = "Oct";
                break;
            case "11":
                d = "Nov";
                break;
            case "12":
                d = "Dec";
                break;
        }
        let finaldate = `${d}-${a[8] + a[9]}-${a[0] + a[1] + a[2] + a[3]}`;
        return finaldate;
    }

    function notifiFunction(e) {
        if (e === "slack") {
            setSlackshow(true);
        } else if (e === "none") {
            setSlackshow(false);
            let mail = e;

            fetch(`${REACT_APP_SERVER_URL}/Noneoption`, {
                headers: {
                    Accept: "application/json",
                    "Contendt-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    option: mail,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("Saved notification to None");
                });
        } else {
            setSlackshow(false);
            let mail = e;

            fetch(`${REACT_APP_SERVER_URL}/Mailoption`, {
                headers: {
                    Accept: "application/json",
                    "Contendt-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    option: mail,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("The Records Will be sent to your Mail");
                });
        }
    }

    function SaveSlackUrl(url) {
        if (url === "") {
            setErrorSnackbarOpen(true);
            setErrorMessage("SlackWebhook url is empty");
            return;
        } else {
            let slackUrl = url;
            fetch(`${REACT_APP_SERVER_URL}/slackUrl`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    option: "slack",
                    slackurl: slackUrl,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setSlackshow(false);
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("The Records Will be sent to your Slack");
                });
        }
    }

    function recordData(e) {
        setLoading1(true);
        setNotifiBox(true);

        fetch(`${REACT_APP_SERVER_URL}/ipRecords?page=${currentpage}&limit=7`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setLoading1(false);
                    setLoading(false);
                    setTotalpage(data.totalPages);
                    setRecords(data.data);

                    checkMedium(data.medium);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading1(false);
                setLoading(false);
            });
    }

    function checkMedium(m) {
        if (m.slack === false && m.mail === true) {
            setNotifiFeature("mail");
        } else if (m.slack === true && m.mail === false) {
            setNotifiFeature("slack");
        } else {
            setNotifiFeature("none");
        }
    }

    function back() {
        setIsBlacklisted([]);
        setIpAddress("");
        recordData();
        setTable(true);
    }

    function DeleteIpHandle(ip) {
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/DeleteIp`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            credentials: "include",
            body: new URLSearchParams({
                ip: ip,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setLoading(false);
                if (data.status === 200) {
                    recordData();
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("Ip Deleted successfully.");
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage("Something went wrong.");
                }
            });
    }

    const SkeletonTableBody = ({ rowCount = 5 }) => {
        const skeletonRows = [...Array(rowCount)].map((_, index) => (
            <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" width={200} />
                </StyledTableCell>
                <StyledTableCell align="right">
                    <Skeleton variant="text" width={200} />
                </StyledTableCell>
                <StyledTableCell align="right">
                    <Skeleton variant="rectangular" width={200} />
                </StyledTableCell>
                <StyledTableCell align="right">
                    <Skeleton variant="text" width={200} height={30} />
                </StyledTableCell>
                <StyledTableCell align="right">
                    <Skeleton variant="text" width={200} />
                </StyledTableCell>
            </StyledTableRow>
        ));

        return <TableBody>{skeletonRows}</TableBody>;
    };
    return (
        <>
            {Loading ? (
                <Box sx={{ width: "100%", display: "center", justifyContent: "center", overflow: "hidden" }}>
                    <CircularProgress />
                    <Typography>Deleting...</Typography>
                </Box>
            ) : (
                <div>
                    <Dialog
                        PaperProps={{
                            style: { borderRadius: "20px" },
                        }}
                        open={deleteIpDialougeOpen}
                    >
                        <div
                            style={{
                                width: "500px",
                                height: "200px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <DialogTitle id="alert-dialog-title">
                                <p style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
                                    Are you sure you want to delete account?
                                </p>
                            </DialogTitle>
                            <DialogActions sx={{ mb: 4, float: "right" }}>
                                <Button
                                    onClick={() => {
                                        DeleteIpHandle(deleteId);
                                        setdDeleteIpDialougeOpen(false);
                                    }}
                                    variant="contained"
                                    sx={{ color: "#fff" }}
                                    color="success"
                                >
                                    Delete
                                </Button>
                                <Button
                                    onClick={() => {
                                        setdDeleteIpDialougeOpen(false);
                                        setdeleteId("");
                                    }}
                                    variant="contained"
                                    sx={{ color: "#fff" }}
                                    color="error"
                                    disabled={false}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </div>
                    </Dialog>
                    {isBlacklisted.length === 0 && (
                        <Box>
                            <Button
                                sx={{ margin: "15px" }}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <ArrowBackIos />
                                Back
                            </Button>
                            <Box sx={{ textAlign: "center" }}>
                                <Typography style={{ fontSize: "30px", fontWeight: "1200px", lineHeight: "38px" }}>
                                    Blacklist Checker
                                </Typography>
                                <br />
                                <Typography
                                    style={{
                                        textAlign: "center",
                                        fontSize: "18px",
                                        color: "rgba(71, 84, 103, 1)",
                                        fontWeight: "400px",
                                        lineHeight: "24px",
                                    }}
                                >
                                    Discover in an instant where your provided IPs or domains stand on the blacklist.
                                    Get a detailed report on their status and ensure your online presence is pristine.
                                    Protect your reputation with ease.
                                </Typography>
                            </Box>
                            <Box sx={{ marginTop: "10px", marginLeft: "2px" }}>
                                <Typography
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        lineHeight: "28px",
                                        color: "rgba(16, 24, 40, 1)",
                                    }}
                                >
                                    All Records
                                </Typography>
                            </Box>
                            <Box sx={{ marginTop: "15px", alignItems: "center", bottom: "0px" }}>
                                <form onSubmit={handleSubmit} style={{ marginLeft: "2px" }}>
                                    <TextField
                                        sx={{ width: "30%" }}
                                        label="Enter IP/Domain "
                                        id="outlined-size-small"
                                        size="small"
                                        value={ipAddress}
                                        onChange={(e) => setIpAddress(e.target.value)}
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ marginLeft: "10px", padding: "6px", backgroundColor: "#004aad" }}
                                        onClick={handleCheckIP}
                                        disabled={LoadingcheckIp}
                                    >
                                        Add
                                    </Button>
                                </form>
                                {notifibox && (
                                    <div id="notification">
                                        <Box>
                                            <FormControl
                                                variant="standard"
                                                sx={{ m: 1, minWidth: 230, marginTop: "0px", marginLeft: "300px" }}
                                            >
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    Get Daily Blacklist Reports
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={notifiFeature}
                                                    onChange={(e) => {
                                                        let selectFeature = e.target.value;
                                                        setNotifiFeature(selectFeature);
                                                        notifiFunction(selectFeature);
                                                    }}
                                                    label="Get Daily Blacklist Reports"
                                                >
                                                    <MenuItem value="none">None</MenuItem>
                                                    <MenuItem value="slack">SLACK</MenuItem>
                                                    <MenuItem value="mail">EMAIL</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        {slackshow && (
                                            <form id="notifiForm" style={{ marginLeft: "195px" }}>
                                                <TextField
                                                    label="Enter Your slackWebhookUrl"
                                                    id="outlined-size-small"
                                                    defaultValue="Small"
                                                    size="small"
                                                    onChange={(e) => setslackurl(e.target.value)}
                                                    value={slackurl}
                                                />
                                                <Button
                                                    sx={{ marginLeft: "4px" }}
                                                    onClick={() => SaveSlackUrl(slackurl)}
                                                >
                                                    Save
                                                </Button>
                                                <Link
                                                    href="https://youtu.be/NfdZgcIRi5c"
                                                    target="blank"
                                                    title="Find where to get SlackWebhookUrl"
                                                    sx={{ color: "red", textDecoration: "none" }}
                                                >
                                                    Tutorial<HelpIcon></HelpIcon>{" "}
                                                </Link>
                                            </form>
                                        )}
                                    </div>
                                )}
                            </Box>
                        </Box>
                    )}

                    {allIptable && (
                        <Box sx={{ marginTop: "15px" }}>
                            {Loading1 ? (
                                <SkeletonTableBody />
                            ) : (
                                records.length !== 0 && (
                                    <Box>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 800 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>IP/DOMAIN</StyledTableCell>
                                                        <StyledTableCell align="right">LISTED</StyledTableCell>
                                                        <StyledTableCell align="right">CLEAN</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {records &&
                                                        records
                                                            .sort((a, b) => (a.status === "listed" ? -1 : 1))
                                                            .map((data, i) => (
                                                                <StyledTableRow
                                                                    key={data.ip}
                                                                    sx={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setNotifiBox(false);
                                                                        setTable(false);
                                                                        setIp(data.ip);
                                                                        setIsBlacklisted(data.details.d);
                                                                    }}
                                                                >
                                                                    <StyledTableCell component="th" scope="row">
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                fontWeight: 500,
                                                                                lineHeight: "18px",
                                                                            }}
                                                                        >
                                                                            {data.ip}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left">
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                fontWeight: 500,
                                                                                lineHeight: "18px",
                                                                            }}
                                                                        >
                                                                            {`${data.Blacklisted}`}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left">
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                fontWeight: 500,
                                                                                lineHeight: "18px",
                                                                            }}
                                                                        >
                                                                            {`${data.Clean}`}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )
                            )}

                            {
                                <div style={{ marginTop: "13px" }}>
                                    <Stack
                                        spacing={2}
                                        sx={{
                                            textAlign: "center",
                                            justifyContent: "center",
                                            justifyItems: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        {Loading1 ? (
                                            <Pagination count={totalpage} color="primary" disabled />
                                        ) : (
                                            <Pagination
                                                count={totalpage}
                                                color="primary"
                                                onChange={handleChange}
                                                value={currentpage}
                                            />
                                        )}
                                    </Stack>
                                </div>
                            }
                        </Box>
                    )}

                    {LoadingcheckIp ? (
                        <Box sx={{ width: "100%", display: "center", justifyContent: "center", marginTop: 3 }}>
                            <CircularProgress />
                            <Typography>
                                {" "}
                                Searching status for all zones associated with the domain, it would take approx 30 to 40
                                seconds...
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            <Box sx={{ marginTop: "15px" }}>
                                {isBlacklisted.length !== 0 && (
                                    <Box>
                                        <Button onClick={back}>
                                            <Back></Back>
                                        </Button>

                                        <CardContent>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell
                                                                sx={{
                                                                    fontWeight: 600,
                                                                    color: "white",
                                                                    background: "#004aad",
                                                                }}
                                                            >
                                                                IP/Domain
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    fontWeight: 600,
                                                                    color: "white",
                                                                    background: "#004aad",
                                                                }}
                                                            >
                                                                Status
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    fontWeight: 600,
                                                                    color: "white",
                                                                    background: "#004aad",
                                                                }}
                                                            >
                                                                Zones
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    fontWeight: 600,
                                                                    color: "white",
                                                                    background: "#004aad",
                                                                }}
                                                            >
                                                                Delist
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {isBlacklisted &&
                                                            isBlacklisted
                                                                .sort((a, b) => (a.status === "listed" ? -1 : 1))
                                                                .slice(
                                                                    (detailedPage - 1) * rowsPerDetailedPage,
                                                                    detailedPage * rowsPerDetailedPage
                                                                )
                                                                .map((data, i) => (
                                                                    <TableRow
                                                                        key={i}
                                                                        sx={{ borderBottom: "1px solid #EEE7DA" }}
                                                                    >
                                                                        <TableCell>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    lineHeight: "18px",
                                                                                }}
                                                                            >
                                                                                {ip}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.status === "listed" ? (
                                                                                <Typography
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    <CancelIcon
                                                                                        sx={{
                                                                                            color: "red",
                                                                                            height: "20px",
                                                                                        }}
                                                                                    />
                                                                                    <span>Listed</span>
                                                                                </Typography>
                                                                            ) : (
                                                                                <Typography
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    <CheckBoxIcon
                                                                                        sx={{
                                                                                            color: "green",
                                                                                            height: "20px",
                                                                                        }}
                                                                                    />
                                                                                    <span>Ok</span>
                                                                                </Typography>
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    lineHeight: "18px",
                                                                                }}
                                                                            >
                                                                                {data.zone}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Link
                                                                                href={`http://${data.url}/`}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    lineHeight: "18px",
                                                                                    textDecoration: "none",
                                                                                    color: "inherit",
                                                                                }}
                                                                            >
                                                                                {data.url}
                                                                            </Link>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                        {isBlacklisted.length > 10 && (
                                            <Pagination
                                                count={Math.ceil(isBlacklisted.length / rowsPerDetailedPage)}
                                                page={detailedPage}
                                                onChange={handleChangeDetailedPage}
                                                color="primary"
                                                variant="outlined"
                                                sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                                            />
                                        )}
                                    </Box>
                                )}

                                <Snackbar
                                    open={errorSnackbarOpen}
                                    autoHideDuration={2000}
                                    onClose={handleErrorSnackbarClose}
                                >
                                    <Alert
                                        onClose={handleErrorSnackbarClose}
                                        variant="filled"
                                        severity="error"
                                        sx={{ width: "100%" }}
                                    >
                                        {errorMessage}
                                    </Alert>
                                </Snackbar>
                                <Snackbar
                                    open={successSnackbarOpen}
                                    autoHideDuration={6000}
                                    onClose={handleSuccessSnackbarClose}
                                >
                                    <Alert
                                        onClose={handleSuccessSnackbarClose}
                                        variant="filled"
                                        severity="success"
                                        sx={{ width: "100%" }}
                                    >
                                        {successMsg}
                                    </Alert>
                                </Snackbar>
                            </Box>
                        </>
                    )}
                </div>
            )}
        </>
    );
};
export default IpBlacklist;
