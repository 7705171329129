import { useEffect, useState } from "react";
import UserProfile from "./Profile";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { Tabs } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import WorkspaceList from "./WorksSpaceList";
import Pricing from "./Pricing";
import { useLocation, useNavigate } from "react-router-dom";
const Setting = () => {
    const location = useLocation();
    const workspace = new URLSearchParams(location.search).get("workspace");
    const billing = new URLSearchParams(location.search).get("billing");

    const navigate = useNavigate();
    const [tabvalue, setTabValue] = useState(() => {
        if (workspace) return "2";
        if (billing) return "3";
        return "1";
    });
    useEffect(
        () =>
            setTabValue(() => {
                if (workspace) return "2";
                if (billing) return "3";
                return "1";
            }),
        [workspace, billing]
    );
    const handleChange = (e, value) => {
        navigate("/dashboard/setting");
        setTabValue(() => value);
    };
    return (
        <Box>
            <TabContext value={tabvalue}>
                <Tabs
                    value={tabvalue}
                    onChange={handleChange}
                    aria-label="full width tabs example"
                    textColor="primary"
                    indicatorColor="primary"
                >
                    <Tab
                        label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <AccountCircleIcon sx={{ margin: 1 }} />
                                Profile
                            </Box>
                        }
                        value="1"
                    />
                    <Tab
                        label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <WorkHistoryIcon sx={{ margin: 1 }} />
                                WorkSpace
                            </Box>
                        }
                        value="2"
                    />
                    <Tab
                        label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <MonetizationOnIcon sx={{ margin: 1 }} />
                                Pricing
                            </Box>
                        }
                        value="3"
                    />
                </Tabs>
                <TabPanel value="1">
                    <UserProfile />
                </TabPanel>
                <TabPanel value="2">
                    <WorkspaceList />
                </TabPanel>
                <TabPanel value="3">
                    <Pricing />
                </TabPanel>
            </TabContext>
        </Box>
    );
};
export default Setting;
