import { Skeleton, List, ListItem, ListItemIcon, Paper, ListItemText } from "@mui/material";
export const SkeletonList = () => {
    return (
        <>
            {[...Array(5)].map((_, index) => (
                <Paper
                    key={index}
                    elevation={2}
                    sx={{
                        marginBottom: 2,
                        backgroundColor: "#ffffff",
                        width: "100%",
                        borderRadius: 1,
                    }}
                >
                    <ListItem>
                        <ListItemIcon>
                            <Skeleton variant="circular" width={40} height={40} />
                        </ListItemIcon>
                        <ListItemText primary={<Skeleton width="60%" />} secondary={<Skeleton width="40%" />} />
                        <ListItemIcon>
                            <Skeleton variant="rectangular" width={24} height={24} />
                        </ListItemIcon>
                    </ListItem>
                </Paper>
            ))}
        </>
    );
};
