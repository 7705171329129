import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteDialog from "./DeleteDialog";
import AlertDialog from "./AlertDialog";
import { set } from "rsuite/esm/internals/utils/date";

const OverflowMenu = ({ rowId, loading, onDelete ,data }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteClick = () => {
        setOpenDialog(true);
        handleClose();
    };

    const handleDialogClose = (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            onDelete();
        }
    };

    const handleGenerate = () => {
        setOpenAlert(false);
        console.log("regenrate");
        navigate(`/dashboard/manualAudit/${data.sender}`);
    };
    let message = "Refresh and validate all records. This may take some time. Proceed?";
    return (
        <div>
            <Tooltip title="Permanently delete this item">
                <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>
            <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                <MenuItem
                    onClick={() => {
                        setOpenAlert(true);
                        setAnchorEl(null);
                    }}
                >
                    Regenerate
                </MenuItem>
            </Menu>

            <DeleteDialog
                open={openDialog}
                onClose={() => handleDialogClose(false)}
                onConfirm={() => handleDialogClose(true)}
                title="Confirm Delete"
                content="Are you sure you want to delete this item?"
                confirmText="Delete"
                cancelText="Cancel"
                loading={loading}
            />
            <AlertDialog
                open={openAlert}
                onClose={() => setOpenAlert(false)}
                handleSubmit={handleGenerate}
                message={message}
            />
        </div>
    );
};

export default OverflowMenu;
