import { Grid, Paper, Skeleton, Box } from "@mui/material";
const SkeletonSubscription = () => {
    return (
        <Box>
            <Skeleton variant="rectangular" height={1} sx={{ bgcolor: "grey.400" }} />
            <Grid container spacing={3} sx={{ justifyContent: "center", px: 4 }}>
                {[1, 2, 3].map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Paper
                            elevation={3}
                            sx={{
                                padding: "20px",
                                minWidth: "250px",
                                margin: "10px auto",
                                height: "100%",
                                position: "relative",
                            }}
                        >
                            <Skeleton
                                variant="rectangular"
                                height={30}
                                sx={{
                                    width: "50%",
                                    margin: "0 auto 10px auto",
                                    bgcolor: "grey.300",
                                }}
                            />
                            <Skeleton
                                variant="text"
                                height={30}
                                width="80%"
                                sx={{ margin: "0 auto", bgcolor: "grey.300" }}
                            />
                            <Skeleton
                                variant="text"
                                height={20}
                                width="60%"
                                sx={{ margin: "20px auto", bgcolor: "grey.300" }}
                            />
                            <Box style={{ paddingLeft: "20px" }}>
                                <Skeleton variant="text" height={20} width="70%" sx={{ bgcolor: "grey.300" }} />
                                <Skeleton variant="text" height={20} width="70%" sx={{ bgcolor: "grey.300" }} />
                            </Box>
                            <Box style={{ paddingLeft: "20px" }}>
                                <Skeleton variant="text" height={20} width="70%" sx={{ bgcolor: "grey.300" }} />
                                <Skeleton variant="text" height={20} width="70%" sx={{ bgcolor: "grey.300" }} />
                            </Box>
                            <Box style={{ paddingLeft: "20px" }}>
                                <Skeleton variant="text" height={20} width="70%" sx={{ bgcolor: "grey.300" }} />
                                <Skeleton variant="text" height={20} width="70%" sx={{ bgcolor: "grey.300" }} />
                            </Box>
                            <Box style={{ paddingLeft: "20px" }}>
                                <Skeleton variant="text" height={20} width="70%" sx={{ bgcolor: "grey.300" }} />
                                <Skeleton variant="text" height={20} width="70%" sx={{ bgcolor: "grey.300" }} />
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
export default SkeletonSubscription;
