import React, { createContext, useContext, useEffect, useState } from "react";
import cookie from "cookiejs";
import { AuthContext } from "./AuthProvider";
const PlanContext = createContext();

const PlanContextProvider = ({ children }) => {
    const isLogin = cookie.get("token");
    const [features, setFeatures] = useState([]);
    const [loader, setIsLoader] = useState(true);
    const authCtx = useContext(AuthContext);
    const getFeatureData = async () => {
        console.log("I am running feature plan");
        try {
            const res = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/fetchAccessibleFeatures?priceId=${authCtx.PlanDetails.priceId}`,
                {
                    headers: {
                        Accept: "application/json",
                    },
                    credentials: "include",
                    method: "GET",
                }
            );
            const getResponse = await res.json();
            console.log(getResponse.data, "planfeaturesData");
            const keys = ["Dashboard", "Email Audit", "Domains", "Tools", "Integrations", "Settings"];

            const orderedActiveNavLinks = getResponse.data
                ? getResponse.data
                      .filter((link) => keys.includes(link.label) && link.isActive)
                      .sort((a, b) => keys.indexOf(a.label) - keys.indexOf(b.label))
                : [];

            setFeatures(orderedActiveNavLinks);
            setIsLoader(false);
        } catch (error) {
            console.error("Failed to fetch data:", error);
        } finally {
            setIsLoader(false);
        }
    };
    useEffect(() => {
        if (isLogin) {
            getFeatureData();
        }
    }, [authCtx.PlanDetails]);

    const planContextValue = {
        getFeatureData,
        features,
        hideDashboard: loader,
    };
    return <PlanContext.Provider value={planContextValue}>{children}</PlanContext.Provider>;
};

export { PlanContext, PlanContextProvider };
