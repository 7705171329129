import React from "react";
import { Typography, Paper, Grid, List, ListItem, ListItemText, Button } from "@mui/material";
import { Link } from 'react-router-dom';
const BlacklistPanel = ({ records }) => {
    const domainListedZones = records
        .filter((item) => item?.data?.domainListedZones?.listed?.length > 0) // Filter items with non-empty listed array
        .map((item) => item.data.domainListedZones.listed) // Map to extract the listed array
        .flat();
    const ipListedZones = records
        .filter((item) => item?.data?.ipListedZones?.listed?.length > 0) // Filter items with non-empty listed array
        .map((item) => item.data.ipListedZones.listed) // Map to extract the listed array
        .flat();
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Paper
                    sx={{
                        padding: "20px",
                        border: "1px solid #f1f1f1",
                        borderRadius: "5px",
                        backgroundColor: "#fff",
                        maxHeight: "280px",
                        overflowY: "auto",
                        boxShadow: "none !important",
                    }}
                >
                    <Typography
                        sx={{
                            color: "#24a0ed",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "bold",
                        }}
                    >
                        Domain Blacklist
                    </Typography>
                    <List>
                        {domainListedZones.length === 0 ? (
                            <ListItem>
                                <ListItemText primary="No data found" sx={{ textAlign: "center" }} />
                            </ListItem>
                        ) : (
                            domainListedZones.map((data, index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                        border: "1px solid #ddd",
                                        borderRadius: "5px",
                                        marginBottom: "2px",
                                        "&:hover": {
                                            backgroundColor: "#e0f2f1",
                                        },
                                    }}
                                >
                                    <ListItemText primary={data.zone} sx={{ textAlign: "center" }} />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        component={Link}
                                        to={`http://${data.zone.slice(data.zone.indexOf('.') + 1)}/`}
                                        target="_blank" // Optional: Opens the link in a new tab
                                        rel="noopener noreferrer" // Optional: Security feature for external links
                                        sx={{ textDecoration: 'none' }}
                                    >
                                        Delist
                                    </Button>
                                </ListItem>
                            ))
                        )}
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper
                    sx={{
                        padding: "20px",
                        border: "1px solid #f1f1f1",
                        borderRadius: "5px",
                        backgroundColor: "#fff",
                        maxHeight: "280px",
                        overflowY: "auto",
                        boxShadow: "none !important",
                    }}
                >
                    <Typography
                        sx={{
                            color: "#24a0ed",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "bold",
                        }}
                    >
                        IP Blacklist
                    </Typography>
                    <List>
                        {ipListedZones.length === 0 ? (
                            <ListItem>
                                <ListItemText primary="No data found" sx={{ textAlign: "center" }} />
                            </ListItem>
                        ) : (
                            ipListedZones.map((data, index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                        border: "1px solid #ddd",
                                        borderRadius: "5px",
                                        marginBottom: "2px",
                                        "&:hover": {
                                            backgroundColor: "#e0f2f1",
                                        },
                                    }}
                                >
                                    <ListItemText primary={data.zone} sx={{ textAlign: "center" }} />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        component={Link}
                                        to={`http://${data.zone.slice(data.zone.indexOf('.') + 1)}/`}
                                        target="_blank" // Optional: Opens the link in a new tab
                                        rel="noopener noreferrer" // Optional: Security feature for external links
                                        sx={{ textDecoration: 'none' }}
                                    >
                                        Delist
                                    </Button>
                                </ListItem>
                            ))
                        )}
                    </List>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default BlacklistPanel;
