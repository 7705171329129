import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import XAuditLogo from "../../img/icons/xaudit1.png";
const PaymentFail = () => {
    const handleGoHome = () => {
        window.location.href = "/";
    };

    return (
        <Box>
            <Box
                component="img"
                src={XAuditLogo} // Replace with your image link
                alt="Payment Fail"
                sx={{ marginBottom: 2, width: "15%" }}
            />
            <Container maxWidth="sm" style={{ textAlign: "center", padding: "50px" }}>
                <ErrorIcon color="error" style={{ fontSize: 60 }} />
                <Typography variant="h4" gutterBottom>
                    Payment Failed
                </Typography>
                <Typography variant="body1" paragraph>
                    Unfortunately, your payment could not be processed. Please try again later or contact support if the
                    issue persists.
                </Typography>
                <Button variant="contained" color="primary" onClick={handleGoHome}>
                    Go to Homepage
                </Button>
            </Container>
        </Box>
    );
};

export default PaymentFail;
