import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    Box,
    Grid,
    TableRow,
    TableCell,
    Typography,
    CircularProgress,
    Snackbar,
    Alert,
    TableContainer,
    TableBody,
    Paper,
    TableHead,
    Table,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton } from "@mui/material";
import "./IpBlacklistdetails.css";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#004aad",
        color: theme.palette.common.white,
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
        padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        textAlign: "center",
        padding: "10px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: "#f1f1f1",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function IpBlacklistdetails() {
    const [ip, setIp] = useState("");
    const [date, setDate] = useState("");
    const [details, setDetails] = useState([]);
    const [address, setAddress] = useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [Loading, setLoading] = useState(false);
    const params = useParams();
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    useEffect(() => {
        historydetails();
    }, []);

    function Date(data) {
        let a = data;
        let d = "";
        // let b = `${a[8] + a[9]}-${a[5] + a[6]}-${a[0] + a[1] + a[2] + a[3]}`;
        let b = `${a[5] + a[6]}-${a[8] + a[9]}-${a[0] + a[1] + a[2] + a[3]}`;
        let datas = b.slice(0, 2);
        switch (datas) {
            case "01":
                d = "Jan";
                break;
            case "02":
                d = "Feb";
                break;
            case "03":
                d = "Mar";
                break;
            case "04":
                d = "Apr";
                break;
            case "05":
                d = "May";
                break;
            case "06":
                d = "Jun";
                break;
            case "07":
                d = "Jul";
                break;
            case "08":
                d = "Aug";
                break;
            case "09":
                d = "Sep";
                break;
            case "10":
                d = "Oct";
                break;
            case "11":
                d = "Nov";
                break;
            case "12":
                d = "Dec";
                break;
        }
        let finaldate = `${d}-${a[8] + a[9]}-${a[0] + a[1] + a[2] + a[3]}`;
        return finaldate;
    }

    function historydetails() {
        setLoading(true);
        let code = params.code;
        console.log(code, "code getting");
        fetch(`${REACT_APP_SERVER_URL}/historydetails`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
                code: code,
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                console.log(response, "sdlfjs");
                return response.json();
            })
            .then((data) => {
                setIp(data.ip);
                setDate(data.date);
                setDetails(data.data.d);
                setLoading(false);
                setSuccessSnackbarOpen(true);
                setSuccessMessage("History Records");
            });
        setAddress(`${process.env.REACT_APP_URL}/check/${code}`);
    }
    function copy(text) {
        navigator.clipboard.writeText(text);
    }

    return (
        <>
            <header className="head" id="header">
                <span className="insider">HISTORY</span>
            </header>

            {Loading ? (
                <Box sx={{ display: "center", justifyContent: "center", marginTop: "20px" }}>
                    <CircularProgress />
                    <Typography>Loading ...</Typography>
                </Box>
            ) : (
                <Box sx={{ maxWidth: "1600px", margin: "auto", minWidth: "1200px" }}>
                    <h3 style={{ marginTop: 50 }}></h3>
                    {details.length !== 0 && (
                        <Box sx={{ textAlign: "center" }}>
                            {details.length !== 0 && (
                                <Box sx={{ marginTop: "50px", marginBottom: "20px" }}>
                                    <Box sx={{ marginTop: "-15px", margin: "15px" }}>
                                        <span
                                            style={{ fontSize: 19, color: "black", fontWeight: 500, marginLeft: "9px" }}
                                        >{`${ip} Blacklist History of ${Date(date)}`}</span>
                                    </Box>
                                    <Box sx={{ margin: "10px" }}>
                                        <p>Share the Report</p>
                                        <IconButton
                                            sx={{
                                                width: "400px",
                                                fontSize: "15px",
                                                fontWeight: "bolder",
                                                border: "1px solid GrayText",
                                                borderRadius: "4px",
                                                height: "5vh",
                                                color: "GrayText",
                                                marginTop:"-10px"
                                            }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSuccessSnackbarOpen(true);
                                                setSuccessMessage("Report Link Ready for Sharing");
                                                copy(address);
                                            }}
                                        >
                                            <Typography sx={{ fontSize: "15px", padding: "2px" }}>{address}</Typography>
                                            <CopyIcon
                                                style={{ marginLeft: "55px",height:'32px' }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSuccessSnackbarOpen(true);
                                                    setSuccessMessage("History address successfully copied");
                                                    copy(address);
                                                }}
                                            ></CopyIcon>
                                        </IconButton>
                                    </Box>
                                    <Box sx={{ margin: "100px", marginTop: "-2px" }}>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>Status</StyledTableCell>

                                                        <StyledTableCell>Zones</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {details &&
                                                        details
                                                            .sort((a, b) => (a.status === "listed" ? -1 : 1))
                                                            .map((data, i) => (
                                                                <StyledTableRow key={i}>
                                                                    <StyledTableCell component="th" scope="row">
                                                                        {data.status === "listed" ? (
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                }}
                                                                            >
                                                                                <CancelIcon sx={{ color: "red" }} />
                                                                                <Typography sx={{ marginLeft: "30px" }}>
                                                                                    Listed
                                                                                </Typography>
                                                                            </Box>
                                                                        ) : (
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                }}
                                                                            >
                                                                                <CheckBoxIcon sx={{ color: "green" }} />
                                                                                <Typography>Ok</Typography>
                                                                            </Box>
                                                                        )}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>{data.zone}</StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* <CardContent>
                                            <Grid
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    fontWeight: 600,
                                                    color: "white",
                                                    fontSize: "18px",
                                                    background: "#004aad",
                                                    borderRadius: "10px",
                                                    padding: "1%",
                                                    marginTop: "-5px",
                                                    overflow: "hidden",
                                                }}
                                                container
                                                spacing={2}
                                                alignItems="center"
                                            >
                                                <Grid style={{ width: "50%" }} xs={6} sm={1.7}>
                                                    <label id="detailsTrowIp">Status</label>
                                                </Grid>
                                                <Grid style={{ width: "50%" }} xs={6} sm={4}>
                                                    <label d="detailsTrowStatus">Zones</label>
                                                </Grid>
                                            </Grid>
                                        </CardContent> */}
                                        {/* {details &&
                                            details
                                                .sort((a, b) => (a.status === "listed" ? -1 : 1))
                                                .map((data, i) => (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            gap: 3,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: "94.5%",
                                                                height: "3vh",
                                                                border: "0.1rem solid #EEE7DA",
                                                                marginBottom: "1%",
                                                                padding: "1.5%",
                                                                borderRadius: "10px",
                                                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                                cursor:
                                                                    data.status === "Verified" ? "pointer" : "default",
                                                                overflow: "hidden",
                                                                backgroundColor:
                                                                    data.status === "Verified"
                                                                        ? "#f5f5f5"
                                                                        : "transparent",
                                                                alignItems: "center",
                                                                marginTop: "-8px",
                                                            }}
                                                        >
                                                            <CardContent>
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                    alignItems="center"
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        marginTop: "-20px",
                                                                    }}
                                                                >
                                                                    <Grid style={{ width: "50%" }} xs={6} sm={4}>
                                                                        {" "}
                                                                        {data.status === "listed" ? (
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    marginLeft: "40px",
                                                                                }}
                                                                            >
                                                                                <CancelIcon sx={{ color: "red" }} />
                                                                                <Typography>Listed</Typography>
                                                                            </Box>
                                                                        ) : (
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    marginLeft: "50px",
                                                                                }}
                                                                            >
                                                                                <CheckBoxIcon sx={{ color: "green" }} />
                                                                                <Typography>Ok</Typography>
                                                                            </Box>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid
                                                                        style={{ width: "50%" }}
                                                                        xs={6}
                                                                        sm={4}
                                                                        id="zoneStatus"
                                                                    >
                                                                        {data.zone}
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </div>
                                                    </div>
                                                ))} */}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            )}
            <Snackbar open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default IpBlacklistdetails;
