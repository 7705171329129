import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Card, CardContent, Typography, CardActionArea, Avatar } from "@mui/material";
import ActionComponent from "./ActionComponent";
import BlackListPanel from "./BlackListPanel";
import TemplatePanel from "./TemplatePanel";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import IpReputation from "./DomainIpReputation";
import DNSOverViewChart from "./DNSOverViewChart";
import inbox from "../../../img/images/inbox.png";
import spam from "../../../img/images/spam.png";
import { serviceProviders } from "../../common/ServiceProvider";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const Overview = ({ senderRecords,  senderAccordianData, senderStyle, score, unicodeId }) => {
    const [records, setRecords] = useState(senderRecords?senderRecords:[[],[]]);
    const [calculateScore, setCalculateScore] = useState(score?score:0)
    const [accordianData, setAccordianData] = useState(senderAccordianData?senderAccordianData:[[],[]])
    const [style,setStyle] = useState(senderStyle?senderStyle:[[],[]])
    const handleRecords = (recordList) => {
        const allData = [];
        recordList.forEach((acc, index) => {
            const newData = [];
            acc.forEach((list) => {
                const updatedTitle = list.title && list.title.replace(/\b\w/g, (char) => char.toUpperCase());
                const updatedProcessName =
                    list.processName && list.processName.replace(/\b\w/g, (char) => char.toUpperCase());
                if (list.processName) {
                    newData.push({ processName: updatedProcessName });
                } else if (list?.data === "inbox") {
                    newData.push({ title: updatedTitle, status: "Pass" });
                } else if (list?.data?.spf && list?.data?.dkim && list?.data?.dmarc) {
                    newData.push({ title: updatedTitle, status: "Pass" });
                } else if (
                    list?.data?.domainListedZones?.listed?.length === 0 &&
                    list.data.domainSendMailResult.label === "inbox"
                ) {
                    newData.push({ title: updatedTitle, status: "Pass" });
                } else if (list?.data?.ipListedZones?.listed?.length === 0) {
                    newData.push({ title: updatedTitle, status: "Pass" });
                } else if (list.title === "Element counts") {
                    newData.push({ title: updatedTitle, status: "Pass" });
                } else {
                    newData.push({ title: updatedTitle, status: "Fail" });
                }
            });
            allData.push(newData);
        });
        handleStyle(allData);
        setAccordianData(allData);
    };
    const handleStyle = (data) => {
        let statusStyle = [];
        data.map((accordianStatus) => {
            const slicedData = accordianStatus.slice(1);
            let checkStatus = slicedData.some((obj) => obj.hasOwnProperty("status"));
            if (checkStatus) {
                checkStatus = "pass";
                slicedData.filter((value) => {
                    if (value.status === "Fail") {
                        checkStatus = "fail";
                        return;
                    }
                });
            }

            let style = {
                padding: "10px",
                margin: "20px",
                borderLeft:
                    checkStatus === false ? "" : checkStatus === "pass" ? "7px solid #08800094" : "7px solid #ff000085",
                backgroundColor:
                    checkStatus === false
                        ? ""
                        : checkStatus === "pass"
                          ? "rgb(8 128 0 / 7%)"
                          : "rgba(255, 204, 203, 0.33)",
            };
            statusStyle.push(style);
        });
        setStyle(statusStyle);
    };
    useEffect(() => {
        if (unicodeId) {
            fetch(`${REACT_APP_SERVER_URL}/fetchOverviewData/${unicodeId}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.success) {
                        setRecords(data.data)
                        handleRecords(data.data)
                        setCalculateScore(data.stepOneScore + data.stepTwoScore)
                    } else {
                        console.log(data);
                    }
                });
        }
    }, [unicodeId]);

    const [selectedPanel, setSelectedPanel] = useState("Overview");
    const contentRef = useRef(null);
    const [overflow, setOverflow] = useState("hidden");

   
    const handlePanelSelect = (panel) => {
        setSelectedPanel(panel);
    };
    const templateContent = records[1].find((item) => item.title === "originalContent")?.data;
    const spamCheck = records[1].find((item) => item.title === "modified content")?.data;
    const allscoreCard = {
        score: calculateScore,
        maxScore: 100,
        scoreType: "High Score",
    };
    const espDetails = records[1].find((item) => item.title === "iptCounts")?.data;
    const overallCount = [];
    const espCount = [];
    for (let key in espDetails) {
        if (key === "overallInboxCount") {
            overallCount.push({ title: "Inbox Count", count: espDetails[key], icons: inbox });
        }
        if (key === "overallSpamCount") {
            overallCount.push({ title: "Spam Count", count: espDetails[key], icons: spam });
        }
        serviceProviders.forEach((provider)=>{
            if ((key === provider.service) && espDetails[key] > 0) {
                const found = espCount.find((data) => data.icons === provider.icon);
                found ? (found[provider.title] = espDetails[key]) : espCount.push({ [provider.title]: espDetails[key], icons: provider.icon });
            }
        })
    }
    const dummyData = [
        {
            icon: <RocketLaunchIcon />,
            value: "--",
            description: "Per day volume",
        },
        {
            icon: <ChatBubbleIcon />,
            value: "--",
            description: "Per day warmup volume",
        },
    ];
    useEffect(() => {
        if (espCount.length > 2) {
            setOverflow("auto");
        } else {
            setOverflow("hidden");
        }
    }, [espCount]);
    return (
        <Box>
            <Grid container spacing={2} columns={16}>
                <Grid item xs={3} sm={8} md={4}>
                    <Card
                        sx={{
                            width: "100%",
                            mb: 2,
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                            color: "white",
                            backgroundColor: "#0884e2",
                        }}
                    >
                        <CardContent sx={{ textAlign: "center" }}>
                            <Typography variant="h4">{allscoreCard.scoreType}</Typography>
                            <Typography variant="h2">{allscoreCard.score}</Typography>
                            <Typography variant="subtitle1">out of {allscoreCard.maxScore}</Typography>
                        </CardContent>
                        <Typography
                            sx={{ margin: "10px", padding: "10px", backgroundColor: "#9EC4DF", borderRadius: "5px" }}
                        >
                            The score is calculated directly from these metrics: Inbox rate, Domain health, and IP
                            insights
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={3} sm={8} md={7}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {overallCount.map((data, index) => (
                            <Grid item md={6} key={index}>
                                <Card
                                    key={index}
                                    sx={{
                                        "&:hover": { backgroundColor: "#F7F7F9" },
                                        borderRadius: "8px",
                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                        mb: 2,
                                    }}
                                >
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography variant="h6" gutterBottom textAlign="center">
                                                <img
                                                    src={data.icons}
                                                    alt=""
                                                    style={{
                                                        height: "31px",
                                                        width: "32px",
                                                    }}
                                                />{" "}
                                                {data.title}
                                            </Typography>
                                            <Typography
                                                variant="h5"
                                                style={
                                                    data.title === "Inbox Count"
                                                        ? { color: "#536dfe" } // Blue color for Inbox Count
                                                        : { color: "#e91e63" } // Pink color for other cases
                                                }
                                                textAlign="center"
                                            >
                                                {data.count}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    <Box
                        ref={contentRef}
                        sx={{
                            maxHeight: 160,
                            overflowY: overflow,
                            padding: 2,
                            // Ensure scrollbar is visible when needed
                            "&::-webkit-scrollbar": {
                                width: "8px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                borderRadius: "8px",
                            },
                            msOverflowStyle: "auto", // IE and Edge
                            scrollbarWidth: "auto", // Firefox
                        }}
                    >
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            {espCount.map((data, index) => (
                                <Grid item md={espCount[index + 1] || index % 2 !== 0 ? 6 : 12} key={index}>
                                    <Card
                                        key={index}
                                        sx={{
                                            "&:hover": { backgroundColor: "#F7F7F9" },
                                            borderRadius: "8px",
                                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                            mb: 2,
                                        }}
                                    >
                                        <CardActionArea>
                                            <CardContent>
                                                <Typography variant="h6" gutterBottom textAlign="center">
                                                    <img
                                                        src={data.icons}
                                                        alt=""
                                                        style={{
                                                            height: "31px",
                                                            width: "32px",
                                                        }}
                                                    />{" "}
                                                    ESP Count
                                                </Typography>
                                                <Typography variant="h5" textAlign="center">
                                                    <span style={{ color: "#536dfe" }}>
                                                        {data.inbox ? data.inbox : 0}
                                                    </span>
                                                    /
                                                    <span style={{ color: "#e91e63" }}>
                                                        {data.spam ? data.spam : 0}
                                                    </span>
                                                </Typography>
                                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div>
                                                        <Box
                                                            sx={{
                                                                bgcolor: "#536dfe",
                                                                width: 10,
                                                                height: 10,
                                                            }}
                                                        />
                                                        <span>Inbox</span>
                                                    </div>
                                                    <div>
                                                        <Box
                                                            sx={{
                                                                bgcolor: "#e91e63",
                                                                width: 10,
                                                                height: 10,
                                                            }}
                                                        />
                                                        Spam
                                                    </div>
                                                </Box>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={3} sm={8} md={4}>
                    {dummyData.map((data, index) => (
                        <Card
                            key={index}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                height: "120px",
                                p: 2,
                                mb: 2,
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                "&:hover": { backgroundColor: "#F7F7F9" },
                            }}
                        >
                            <Avatar sx={{ bgcolor: "#E0E0E0", mr: 2 }}>{data.icon}</Avatar>
                            <CardContent sx={{ flexGrow: 1, textAlign: "center" }}>
                                <Typography variant="h5" component="div">
                                    {data.value}
                                </Typography>
                                <Typography variant="body1" color="text.secondary">
                                    {data.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <ActionComponent onSelect={handlePanelSelect} />
                </Grid>
                <Grid item xs={12} md={9}>
                    {selectedPanel === "Overview" && <DNSOverViewChart inboxSpamCount={overallCount} />}
                    {selectedPanel === "BlackList" && <BlackListPanel records={records ? records[0] : []} />}
                    {selectedPanel === "DNS Records" && (
                        <IpReputation
                            records={records ? records : []}
                            style={style ? style : []}
                            accordianData={accordianData ? accordianData : []}
                        />
                    )}
                    {selectedPanel === "TemplateList" && (
                        <TemplatePanel templateContent={templateContent} spamCheck={spamCheck} />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Overview;
