import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { ToggleButtonGroup, ToggleButton, TextField, CircularProgress } from "@mui/material";
import { isBefore } from "date-fns";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function CustomizedDialogs({
    open,
    onClose,
    description,
    email,
    descriptiondateRange,
    description1,
    description2,
    Add,
    loading,
}) {
    const [alignment, setAlignment] = React.useState("Default");
    const [Default, setDefault] = useState(true);
    const [dateRange, setDateRange] = useState(false);
    const [textFieldValue, setTextFieldValue] = useState("");
    const [error, setError] = useState({ status: false, msg: "" });
    const [currentDate] = useState(new Date());
    const [deleteDisabled, setDeleteDisabled] = useState(true);
    console.log(error);
    const HandleChange1 = (event, newAlignment) => {
        if (newAlignment === "Default") {
            setDefault(true);
            setDateRange(false);
            setAlignment("Default");
        } else if (newAlignment === "dateRange") {
            setDefault(false);
            setDateRange(true);
            setAlignment("dateRange");
        }
    };

    const handleTextFieldChange = (event) => {
        setDeleteDisabled(true);
        const input = event.target.value;

        const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;

        if (!datePattern.test(input)) {
            setError({ status: true, msg: "Date format should be DD/MM/YYYY" });
            return;
        }

        const [day, month, year] = input.split("/").map(Number);
        const inputDate = new Date(year, month - 1, day);
        const currentDate = new Date();

        if (inputDate.getFullYear() !== year || inputDate.getMonth() !== month - 1 || inputDate.getDate() !== day) {
            setError({ status: true, msg: "Invalid date" });
            return;
        }

        if (inputDate.toDateString() === currentDate.toDateString()) {
            setError({ status: true, msg: "Date cannot be today" });
        } else if (inputDate > currentDate) {
            setError({ status: true, msg: "Date cannot be in the future" });
        } else {
            setError({ status: false, msg: "" });
            setDeleteDisabled(false);
            setTextFieldValue(input);
        }
    };

    return (
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            sx={{
                "& .MuiDialog-paper": {
                    width: 500,
                    maxWidth: 500,
                    height: "auto",
                },
            }}
        >
            <DialogTitle sx={{ m: 2, p: 2 }} id="customized-dialog-title">
                <ToggleButtonGroup
                    variant="solid"
                    value={alignment}
                    exclusive
                    onChange={HandleChange1}
                    aria-label="Platform"
                    sx={{
                        marginBottom: "10px",
                        width: "100%",
                        height: "40px",
                        justifyContent: "center",
                        marginTop: "10px",
                    }}
                >
                    <ToggleButton
                        style={{
                            width: "auto",
                            padding: "10px",
                            color: alignment === "Default" ? "white" : "black",
                            backgroundColor: alignment === "Default" ? "#004aad" : "white",
                        }}
                        value="Default"
                    >
                        Default
                    </ToggleButton>
                    <ToggleButton
                        style={{
                            width: "auto",
                            padding: "10px",
                            color: alignment === "dateRange" ? "white" : "black",
                            backgroundColor: alignment === "dateRange" ? "#004aad" : "white",
                        }}
                        value="dateRange"
                    >
                        dateRange
                    </ToggleButton>
                </ToggleButtonGroup>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={(theme) => ({
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>
            {Default && (
                <>
                    <DialogContent dividers>
                        <Typography gutterBottom sx={{ textAlign: "center" }}>
                            {description}
                        </Typography>
                        {/* <Typography sx={{ textAlign: "center", fontSize: "16px" }}>{email}</Typography>
                        <Typography sx={{ textAlign: "center", fontSize: "16px" }}>{descriptiondateRange}</Typography> */}
                    </DialogContent>
                    <DialogActions style={{ marginBottom: "10px", marginTop: "10px" }}>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "#e30d0df5" }}
                            onClick={() => Add(email, descriptiondateRange)}
                        >
                            Confirm, Delete
                        </Button>
                        <Button autoFocus variant="contained" color="primary" onClick={onClose}>
                            Dismiss
                        </Button>
                    </DialogActions>
                </>
            )}

            {dateRange && (
                <>
                    <DialogContent dividers>
                        <Typography variant="h6" sx={{ textAlign: "center" }}>
                            {" "}
                            {description1}
                        </Typography>
                        <Typography gutterBottom sx={{ textAlign: "center" }}>
                            {description2}
                        </Typography>
                        <div style={{ textAlign: "center" }}>
                            <TextField
                                id="outlined-basic"
                                label="Date"
                                variant="outlined"
                                onChange={handleTextFieldChange}
                                error={error.status}
                                helperText={error.msg}
                                size="small"
                                placeholder="DD/MM/YYYY"
                            />
                        </div>
                    </DialogContent>
                    <DialogActions style={{ marginBottom: "10px", marginTop: "10px" }}>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "#e30d0df5" }}
                            onClick={() => Add(email, textFieldValue)}
                            disabled={deleteDisabled}
                        >
                            Confirm, Delete
                        </Button>
                        <Button autoFocus variant="contained" color="primary" onClick={onClose}>
                            Dismiss
                        </Button>
                    </DialogActions>
                </>
            )}
        </BootstrapDialog>
    );
}

export default CustomizedDialogs;
