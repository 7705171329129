import React from "react";
import { Box, Typography, Container } from "@mui/material";
import logo from "../../img/icons/xaudit1.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
const VerificationEmailSent = () => {
    return (
        <Box>
            <Box component="img" src={logo} alt="Placeholder" sx={{ marginBottom: 2, width: "15%" }} />
            <Container
                maxWidth="md"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <MailOutlineIcon sx={{ minWidth: "80px", height: "auto" }} />
                <Typography sx={{ fontSize: "24px", fontWeight: "700", textAlign: "center" }}>
                    Check your inbox for password reset
                </Typography>

                <Typography variant="body1" color="textSecondary" sx={{ marginBottom: "10px" }}>
                    We’ve sent a password reset link to the email address you provided
                </Typography>
                <hr style={{ border: "1px solid #ccc", width: "100%" }} />
                <Typography variant="body2" color="textSecondary">
                    Check your spam folder if you do not see it.
                </Typography>

                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                        paddingTop: "10px",
                        textAlign: "center",
                    }}
                >
                    If you have any questions or need assistance, feel free to contact our support team at
                    support@xemailverify.com.
                </Typography>
            </Container>
        </Box>
    );
};

export default VerificationEmailSent;
