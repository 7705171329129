import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItemButton,
    ListItemText,
    Button,
    Box,
    Typography,
} from "@mui/material";
import "./ManualAudit.css";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Link} from 'react-router-dom';

const SenderListDialog = ({
    open,
    onClose,
    senderList,
    selectedSender,
    onSenderSelect,
    onSendMail,
    back,
    close,
    
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="sender-list-dialog"
            fullWidth
            PaperProps={{
                sx: {
                    width: 450,
                    height: "auto",
                    overflow: "hidden",
                },
            }}
        >
            <Box className="iconBox">
                <ArrowBackIcon onClick={back} />
                <CloseIcon onClick={close} />
            </Box>
            <DialogTitle id="sender-list-dialog" className="manual-dialog-title">
                Sender List
                <Typography variant="subtitle1">Choose a Sender</Typography>
            </DialogTitle>
            <DialogContent>
               
                    <List className="class-list">
                        {senderList.map((data, index) => (
                            <ListItemButton
                                key={index}
                                className={`manual-list-item ${selectedSender === data.mail ? "selected" : ""}`}
                                onClick={() => onSenderSelect(data.mail)}
                                // sx={{marginBottom:'10px'}}
                            >
                                <ListItemText sx={{textAlign:'center'}} primary={data.mail} />
                            </ListItemButton>
                        ))}
                    </List>
            
                {selectedSender && (
                    <Box className="manual-send-mail-button">
                        <Link
                            to={`/dashboard/manualAudit/${selectedSender}`}
                            variant="contained"
                            onClick={onSendMail}
                            className="btn"
                        >
                            <Button variant="contained" color="primary">
                                {" "}
                                Execute Step 1
                            </Button>
                        </Link>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default SenderListDialog;
