import React from "react";
import { Paper, Grid } from "@mui/material";

const IpReputation = ({ records, style, accordianData }) => {
    return (
        <Grid item xs={12} md={12}>
            <Paper
                sx={{
                    padding: "20px",
                    border: "1px solid #f1f1f1",
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                    overflowY: "auto",
                    boxShadow: "none !important",
                }}
            >
                {records[0].map((data) => {
                    if (data.title === "DNS Records Check") {
                        return (
                            <div
                                key={data.title}
                                style={{
                                    backgroundColor: "#f9f9f9",
                                    padding: "15px",
                                    borderRadius: "8px",
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    marginBottom: "20px",
                                }}
                            >
                                {Object.keys(data.data).map((key) => (
                                    <p
                                        key={key}
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "8px 0",
                                            borderBottom: "1px solid #e0e0e0",
                                        }}
                                    >
                                        <span style={{ fontWeight: "bold", color: "#333" }}>{key.toUpperCase()}</span>
                                        <span
                                            style={{
                                                color: data.data[key] ? "#4CAF50" : "#F44336", // Green for Pass, Red for Fail
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {data.data[key] ? "Pass" : "Fail"}
                                        </span>
                                    </p>
                                ))}
                            </div>
                        );
                    }
                    return null;
                })}
            </Paper>
        </Grid>
    );
};

export default IpReputation;
