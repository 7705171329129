import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress } from "@mui/material";

const DeleteDialog = ({
    open,
    onClose,
    onConfirm,
    title,
    content,
    confirmText = "Confirm",
    cancelText = "Cancel",
    confirmButtonColor = "success",
    cancelButtonColor = "error",
    loading = false,
}) => {
    return (
        <Dialog
            PaperProps={{
                style: { borderRadius: "20px" },
            }}
            open={open}
            onClose={onClose}
        >
            <div
                style={{
                    width: "500px",
                    height: "200px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <p style={{ color: "red", fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>{title}</p>
                    <p style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>{content}</p>
                </DialogTitle>
                <DialogActions sx={{ mb: 2 }}>
                    <Button
                        variant="contained"
                        sx={{ color: "#fff" }}
                        color={confirmButtonColor}
                        onClick={onConfirm}
                       
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : confirmText}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ color: "#fff" }}
                        color={cancelButtonColor}
                        onClick={onClose}
                    >
                        {cancelText}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};

export default DeleteDialog;
