import { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import FolderIcon from "@mui/icons-material/Folder";
import Paper from "@mui/material/Paper";
import { Typography, Pagination } from "@mui/material";
import { format } from "date-fns";
import WorkspaceManagement from "./WokspaceManageMent";
import { AuthContext } from "../Provider/AuthProvider";
import { Edit } from "@mui/icons-material";
import { SkeletonList } from "../common/SkeletonList";
import { useLocation } from "react-router-dom";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
export default function WorkspaceList() {
    const workspaceCtx = useContext(WorkspaceContext);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [showWorkSpace, setShowWorkSpace] = useState(false);
    const [workSpaceData, setWorkSpaceData] = useState(null);
    const workspacesPerPage = 10;
    const indexOfLastWorkspace = currentPage * workspacesPerPage;
    const indexOfFirstWorkspace = indexOfLastWorkspace - workspacesPerPage;
    const currentWorkspaces = workspaceCtx.workspaceList.slice(indexOfFirstWorkspace, indexOfLastWorkspace);
    const location = useLocation();
    const workspaceId = new URLSearchParams(location.search).get("id");
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    useEffect(() => {
        if (workspaceId && workspaceCtx.workspaceList.length > 0) {
            const data = workspaceCtx.workspaceList.find((workspace) => workspaceId === String(workspace.id));

            if (data) {
                setWorkSpaceData(data);
                setShowWorkSpace(true);
            } else {
                console.error("Workspace not found");
            }
        }
    }, [workspaceId, workspaceCtx.workspaceList]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, "do MMM, HH:mm");
    };
    const handleWorkSpace = (workspace) => {
        console.log(workspace, "workspaces");
        setShowWorkSpace(true);
        setWorkSpaceData(workspace);
    };
    useEffect(() => {
        setTimeout(() => {
            setShowSkeleton(false);
        }, 4000);
    }, []);
    return (
        <Box sx={{ flexGrow: 1, width: "100%", padding: 2 }}>
            {!showWorkSpace ? (
                <Grid container spacing={2}>
                    {showSkeleton ? (
                        <SkeletonList />
                    ) : (
                        <Grid item xs={12}>
                            {workspaceCtx.workspaceList.length > 0 ? (
                                <>
                                    <List>
                                        {currentWorkspaces.map((workspace) => (
                                            <Paper
                                                key={workspace.id}
                                                elevation={2}
                                                sx={{
                                                    marginBottom: 2,
                                                    backgroundColor: "#ffffff",
                                                    width: "100%",
                                                    borderRadius: 1,
                                                }}
                                            >
                                                <ListItem
                                                    sx={{
                                                        width: "100%",
                                                        "&:hover": {
                                                            backgroundColor: "#f0f0f0",
                                                            cursor: "pointer",
                                                            "& .edit-icon": {
                                                                // Targeting the Edit icon on hover
                                                                visibility: "visible",
                                                            },
                                                        },
                                                    }}
                                                    onClick={() => handleWorkSpace(workspace)}
                                                >
                                                    <ListItemIcon>
                                                        <FolderIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={workspace.name}
                                                        secondary={
                                                            <span>
                                                                <i>Created at : </i>
                                                                {formatDate(workspace.createdAt)}
                                                            </span>
                                                        }
                                                    />

                                                    <ListItemIcon
                                                        sx={{
                                                            visibility: "hidden",
                                                        }}
                                                        className="edit-icon"
                                                    >
                                                        <Edit />
                                                    </ListItemIcon>
                                                </ListItem>
                                            </Paper>
                                        ))}
                                    </List>
                                    <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
                                        {workspaceCtx.workspaceList.length > 10 && (
                                            <Pagination
                                                count={Math.ceil(workspaceCtx.workspaceList.length / workspacesPerPage)}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                                color="primary"
                                                variant="outlined"
                                            />
                                        )}
                                    </Box>
                                </>
                            ) : (
                                <Typography>No Workspaces Found</Typography>
                            )}
                        </Grid>
                    )}
                </Grid>
            ) : (
                <WorkspaceManagement
                    workSpaceData={workSpaceData}
                    handleCloseWorkSpace={() => setShowWorkSpace(false)}
                />
            )}
        </Box>
    );
}
