import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

function SearchField({ label, variant, size, value, onChange, ...props }) {
    return (
        <TextField
            label={label}
            variant={variant}
            size={size}
            value={value}
            onChange={onChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            style={{ marginRight: "10px" }}
            {...props}
        />
    );
}

export default SearchField;
