import React, { useState } from "react";
import {
    Box,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Menu,
    MenuItem,
    Card,
    Grid,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Upgrade from "./Upgrade";
import { WorkspaceContext } from "../../Provider/WorkspaceProvider";
import { useContext } from "react";
import { AuthContext } from "../../Provider/AuthProvider";
export default function Pricing() {
    const workCtx = useContext(WorkspaceContext);
    const authCtx = useContext(AuthContext);
    const endDate = authCtx.PlanDetails && authCtx.PlanDetails.end_date ? new Date(authCtx.PlanDetails.end_date) : null;
    const currentDate = new Date();
    const timeDifference = endDate - currentDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const [anchorEl, setAnchorEl] = useState(null);
    const [showUpgrade, setShowUpgrade] = useState(false);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleOpenUpgrade = () => {
        setShowUpgrade(true);
    };
    const handleCloseUpgrade = () => {
        setShowUpgrade(false);
    };
    const dateStr = authCtx.PlanDetails?.end_date || new Date();
    const date = new Date(dateStr);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}`;

    return (
        <Box sx={{ p: 3 }}>
            <Paper
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 2,
                    mb: 3,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6">
                    Current Plan: <strong>{authCtx.PlanDetails?.planName || ""}</strong>
                    <Button variant="outlined" size="small" sx={{ ml: 1 }}>
                        {daysDifference} days left
                    </Button>
                </Typography>
                <Button variant="contained" color="success" onClick={handleOpenUpgrade}>
                    Upgrade
                </Button>
            </Paper>
            <TableContainer component={Paper} sx={{ mb: 3 }} elevation={2}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Plan Name</TableCell>
                            <TableCell>Payment Method</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Renewal Date</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{authCtx.PlanDetails?.planName || ""}</TableCell>
                            <TableCell>Credit Card</TableCell>
                            <TableCell>{authCtx?.PlanDetails?.subscriptionIsActive ? "Active" : "Expired"}</TableCell>
                            <TableCell>{formattedDate}</TableCell>
                            <TableCell>
                                <Button onClick={handleMenuClick}>
                                    <MoreVertIcon />
                                </Button>
                                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                    <MenuItem onClick={handleMenuClose}>Pause</MenuItem>
                                    <MenuItem onClick={handleMenuClose}>Cancel</MenuItem>
                                    <MenuItem onClick={handleMenuClose}>Refund</MenuItem>
                                    <MenuItem onClick={handleMenuClose}>Download Invoice</MenuItem>
                                </Menu>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Button variant="contained" color="info" sx={{ float: "right" }}>
                Credits
            </Button>
            <Typography>Usually Summary</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #ccc",
                            borderRadius: "50%",
                            textAlign: "center",
                            m: "auto",
                            mb: 2,
                            width: "300px",
                            height: "230px",
                        }}
                    >
                        <Typography variant="body1">
                            Workspaces: {workCtx.workspaceList.length} / {workCtx.workspaceLimit.maxWorkspace}
                            <br />
                            Members per workspace: {workCtx.workspaceLimit.maxTeam} /
                            {workCtx.workspaceLimit.maxWorkspace}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={8}>
                    <Paper
                        elevation={2}
                        sx={{
                            p: 2,
                            height: "230px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                        }}
                    >
                        <Typography variant="body1">
                            <strong>Workspaces:</strong> {workCtx.workspaceList.length} /{" "}
                            {workCtx.workspaceLimit.maxWorkspace}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Members per Workspace:</strong> {workCtx.workspaceLimit.maxTeam} /
                            {workCtx.workspaceLimit.maxWorkspace}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            {showUpgrade && <Upgrade open={showUpgrade} onClose={handleCloseUpgrade} />}
        </Box>
    );
}
