import React, { useContext, useState, useEffect } from "react";
import {
    Box,
    TextField,
    Typography,
    Grid,
    IconButton,
    Link,
    InputAdornment,
    Button,
    Container,
    Dialog,
    Tooltip,
    Paper,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { AuthContext } from "../Provider/AuthProvider";
import SnackBar from "../../components/common/SnackBar";
import CircularProgress from "@mui/material/CircularProgress";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const UserProfile = () => {
    const ctx = useContext(AuthContext);
    const [isEditable, setIsEditable] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmpassword, setShowConfirmPassword] = useState(false);
    const [sucessSnackBarOpen, setSuccessSnackBarOpen] = useState(false);
    const [successMsg, setsuccessMsg] = useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorMsg, seterrorMsg] = useState(false);
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);
    const [hasLowercase, setHasLowercase] = useState(false);
    const [hasSpecialCase, setHasSpecialCase] = useState(false);
    const [profileUpdateLoader, setProfileUpdateLoader] = useState(false);
    const handleEditClick = () => setIsEditable(true);
    const handleCancelClick = () => {
        setIsEditable(false);
        setFirstName(ctx.user.firstname || ctx.user.first_name || ctx.user.fname);
        setLastName(ctx.user.lastname || ctx.user.last_name || ctx.user.lname);
    };
    const updateUser = (updatedFirstName, updatedLastName) => {
        const user = JSON.parse(JSON.stringify(ctx.user));
        user.firstname !== undefined
            ? (user.firstname = updatedFirstName)
            : user.first_name !== undefined
              ? (user.first_name = updatedFirstName)
              : (user.fname = updatedFirstName);

        user.lastname !== undefined
            ? (user.lastname = updatedLastName)
            : user.last_name !== undefined
              ? (user.last_name = updatedLastName)
              : (user.lname = updatedLastName);

        ctx.handleuser(user);
    };
    const handleSaveClick = () => {
        if (!firstName || firstName.trim().length < 3) {
            seterrorMsg("First name must be at least 3 characters long");
            setErrorSnackbarOpen(true);
            return;
        }
        setProfileUpdateLoader(true);
        fetch(`${REACT_APP_SERVER_URL}/update-profile`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                fname: firstName,
                lname: lastName,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setsuccessMsg(data.msg || data.message);
                    setSuccessSnackBarOpen(true);
                    updateUser(data.data.first_name, data.data.last_name);
                } else if (data.status === 202) {
                    seterrorMsg(data.msg || data.message);
                    setErrorSnackbarOpen(true);
                } else {
                    seterrorMsg(data.msg || data.message);
                    setErrorSnackbarOpen(true);
                }
                setProfileUpdateLoader(false);
                setIsEditable(false);
            })
            .catch((error) => {
                console.log(error);
                setProfileUpdateLoader(false);
                setIsEditable(false);
            });
    };
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    useEffect(() => {
        setFirstName(ctx.user.firstname || ctx.user.first_name || ctx.user.fname);
        setLastName(ctx.user.lastname || ctx.user.last_name || ctx.user.lname);
        setEmail(ctx.user.email);
    }, []);
    const handleClose = () => {
        setOpenDialog(false);
        setNewPassword("");
        setConfirmPassword("");
    };
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setNewPassword(value);

        setIsLengthValid(value.length >= 8);

        setHasUppercase(/[A-Z]/.test(value));

        setHasLowercase(/[a-z]/.test(value));

        setHasSpecialCase(/[!@#$%^&*(),.?":{}|<>]/.test(value));
    };
    const isAllPasswordConditionsMet = () => {
        return isLengthValid && hasUppercase && hasLowercase && hasSpecialCase;
    };
    const passwordRequirements = [
        {
            text: "Password should be atleast 8 characters",
            check: (password) => password.length >= 8,
        },
        {
            text: "Must have one special character",
            check: (password) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
        },
        {
            text: "Must have one uppercase letter",
            check: (password) => /[A-Z]/.test(password),
        },
        {
            text: "Must have one lowercase letter",
            check: (password) => /[a-z]/.test(password),
        },
    ];
    const handleUpdatePassword = () => {
        if (!newPassword || !isAllPasswordConditionsMet() || newPassword !== confirmPassword) {
            seterrorMsg("Please enter valid password");
            setErrorSnackbarOpen(true);
            return;
        }
        fetch(`${REACT_APP_SERVER_URL}/update-password`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                email: email,
                password: newPassword,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    setsuccessMsg(data.msg || data.message);
                    setSuccessSnackBarOpen(true);
                    handleClose();
                } else {
                    seterrorMsg(data.msg || data.message);
                    setErrorSnackbarOpen(true);
                }
            })
            .catch((error) => console.log(error));
    };
    return (
        <>
            <Container maxWidth="md" sx={{ paddingTop: 4 }}>
                <Paper elevation={1} sx={{ padding: 3, mb: 3 }}>
                    <Box sx={{ mb: 3 }}>
                        <Grid container alignItems="center" spacing={1} sx={{ mb: 1 }}>
                            <Grid item>
                                <PersonOutlinedIcon fontSize="small" />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Name</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    onClick={handleEditClick}
                                    fullWidth
                                    label="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                    InputProps={{ readOnly: !isEditable }}
                                    error={firstName && firstName.trim().length < 3}
                                    helperText={
                                        firstName && firstName.trim().length < 3
                                            ? "First name must be at least 3 characters long"
                                            : ""
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    onClick={handleEditClick}
                                    fullWidth
                                    label="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                    InputProps={{ readOnly: !isEditable }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ mb: 3 }}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <EmailOutlinedIcon fontSize="small" />
                            </Grid>
                            <Grid item>
                                <Typography>Email</Typography>
                            </Grid>
                        </Grid>

                        <Tooltip title="This email is not editable">
                            {" "}
                            <TextField
                                fullWidth
                                value={email}
                                variant="outlined"
                                size="small"
                                disabled
                                sx={{
                                    backgroundColor: "#d9d9d9", // Set background color
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#ccc", // Set border color to match background
                                        },
                                    },
                                }}
                            />
                        </Tooltip>
                    </Box>
                    <Box sx={{ mb: 3 }}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <LockOutlinedIcon fontSize="small" />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Password</Typography>
                            </Grid>
                            <Grid item xs={5} textAlign="right">
                                <Link
                                    sx={{ textDecoration: "none" }}
                                    onClick={() => {
                                        setOpenDialog(true);
                                    }}
                                >
                                    Update Password
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="xs">
                        <Box sx={{ padding: "30px" }}>
                            <TextField
                                label="New Password"
                                type={showPassword ? "text" : "password"}
                                value={newPassword}
                                onChange={handlePasswordChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                sx={{ mt: 1 }}
                            />
                            {newPassword && !isAllPasswordConditionsMet() && (
                                <ul style={{ listStyleType: "none", paddingLeft: 0, fontSize: "14px" }}>
                                    {passwordRequirements.map((requirement, index) => {
                                        const isValid = requirement.check(newPassword);
                                        return (
                                            <li
                                                key={index}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: isValid ? "green" : "#d32f2f",
                                                }}
                                            >
                                                {isValid ? (
                                                    <Check style={{ color: "green", marginRight: 8 }} />
                                                ) : (
                                                    <Close style={{ color: "#d32f2f", marginRight: 8 }} />
                                                )}
                                                {requirement.text}
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                            <TextField
                                label="Confirm New Password"
                                type={!showConfirmpassword ? "password" : "text"}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowConfirmPassword(!showConfirmpassword)}
                                            >
                                                {showConfirmpassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                error={newPassword !== confirmPassword && confirmPassword !== ""}
                                helperText={
                                    newPassword !== confirmPassword && confirmPassword !== ""
                                        ? "Password does not match"
                                        : ""
                                }
                                sx={{ mt: 1 }}
                            />
                            <Box sx={{ mt: 2 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mr: 2 }}
                                    onClick={handleUpdatePassword}
                                >
                                    Reset
                                </Button>
                                <Button variant="outlined" color="error" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Dialog>
                    <SnackBar
                        open={sucessSnackBarOpen}
                        message={successMsg}
                        type="success"
                        onClose={() => setSuccessSnackBarOpen(false)}
                    />
                    <SnackBar
                        open={errorSnackbarOpen}
                        message={errorMsg}
                        type="error"
                        onClose={() => setErrorSnackbarOpen(false)}
                    />
                </Paper>
            </Container>
            {isEditable && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        padding: "16px",
                        borderTop: "1px solid #e0e0e0",
                        backgroundColor: "#fff",
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        left: 0,
                    }}
                >
                    <Box>
                        <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={handleSaveClick}>
                            {profileUpdateLoader ? <CircularProgress color="inherit" /> : "Save"}
                        </Button>
                        <Button variant="outlined" color="error" onClick={handleCancelClick}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default UserProfile;
