import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
    Button,
    Checkbox,
    MenuItem,
    MenuList,
    Box,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Snackbar,
    Alert,
    TextField,
    Modal,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Chart } from "react-google-charts";
import { Bar, Line } from "react-chartjs-2";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import "../DataDashboard/DataDashboard.css";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const DataDashboard = () => {
    const [clientbox, setClientbox] = useState(false);
    const [clients, setClient] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [chooseClient, setchooseClient] = useState([]);
    const [spamRate, setSpamRate] = useState([]);
    const [value, setValue] = "";
    const [stackedBarData, setStackedBarData] = useState([]);
    const [stackedBarOption, setStackedBarOption] = useState([]);
    const [displayStackedBarGraph, setdisplayStackedBarGraph] = useState(false);
    const [alignment1, setAlignment1] = React.useState("SpamShare");
    const [SpamShare, setSpamShare] = useState(true);
    const [WarmupShare, setWarmupShare] = useState(false);
    const [barGraphDisplay, setbarGraphDisplay] = useState(false);
    const [warmup, setWarmup] = useState([]);
    const [warmupGraphData, setwarmupGraphData] = useState([]);
    const [warmupGraphOption, setWarmupGraphOption] = useState([]);
    const [showWarmupDatas, setShowWarmupDatas] = useState(false);
    const [Loading, setLoading] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [SpamClientData, setSpamClientData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [Domains, setDomains] = useState([]);
    const [DomainDispaly, setDomainDisplay] = useState(false);
    const [showDatesValues, setShowDatesValues] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchVal, setSearchVal] = useState("");
    const [showUpload, setShowupload] = useState(false);
    const [queryWarmup, setQueryWarmup] = useState(true);
    const [pathUrl, setpathUrl] = useState("");
    const [processCsv, setProcessCsv] = useState(false);
    const [fileData, setFileData] = useState({
        file: null,
        filePath: null,
    });
    const fileReader = new FileReader();

    const handleOnChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            setFileData({
                file: selectedFile,
                filePath: e.target.value,
            });
        }
    };

    const handleOnSubmitSpamShare = (e) => {
        e.preventDefault();

        const { file } = fileData;

        if (file) {
            setLoading(true);

            try {
                const formData = new FormData();
                formData.append("file", file);

                fileReader.onload = function (event) {
                    const csvOutput = event.target.result;
                    console.log("File Content:", csvOutput);

                    // Append the file content to the FormData
                    formData.append("csvContent", csvOutput);
                    console.log(formData, "formdatattt");
                    fetch(`${REACT_APP_SERVER_URL}/fileupload`, {
                        method: "POST",
                        credentials: "include",
                        body: formData,
                    })
                        .then((response) => {
                            console.log(response, "response consoling");
                            return response.json();
                        })
                        .then((data) => {
                            setLoading(false);
                            console.log(data.data[0]);
                            if (data.status === 200) {
                                setpathUrl(data.data[0]);
                                setSuccessSnackbarOpen(true);
                                setSuccessMessage("File Uploaded");
                                setProcessCsv(true);
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            console.error("Error uploading file:", error);
                            // Handle the error, show an error message, etc.
                        });
                };

                fileReader.readAsText(file);
            } catch (err) {
                setLoading(false);
                console.error("Error on uploading file:", err);
                // Handle the error, show an error message, etc.
            }
        }
    };

    const processExcelSpam = (e) => {
        console.log("working");
        let path = pathUrl;
        setLoading(true);
        try {
            console.log("log in processExcel");
            fetch(`${REACT_APP_SERVER_URL}/processExcelSpam?path=${path}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data, "data form process");
                    setLoading(false);
                    setShowupload(false);
                    setProcessCsv(false);
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage("File Processed Successully");
                    if (data.status === 200) {
                    }
                });
        } catch (err) {
            console.log(err, "error");
        }
    };

    const handleOnSubmitWarmup = (e) => {
        e.preventDefault();

        const { file } = fileData;

        if (file) {
            setLoading(true);

            try {
                const formData = new FormData();
                formData.append("file", file);

                fileReader.onload = function (event) {
                    const csvOutput = event.target.result;
                    console.log("File Content:", csvOutput);

                    // Append the file content to the FormData
                    formData.append("csvContent", csvOutput);
                    console.log(formData, "formdatattt");
                    fetch(`${REACT_APP_SERVER_URL}/fileupload`, {
                        method: "POST",
                        credentials: "include",
                        body: formData,
                    })
                        .then((response) => {
                            console.log(response, "response consoling");
                            return response.json();
                        })
                        .then((data) => {
                            setLoading(false);
                            console.log(data.data[0]);
                            if (data.status === 200) {
                                setpathUrl(data.data[0]);
                                setSuccessSnackbarOpen(true);
                                setSuccessMessage("File Uploaded");
                                setProcessCsv(true);
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            console.error("Error uploading file:", error);
                            // Handle the error, show an error message, etc.
                        });
                };

                fileReader.readAsText(file);
            } catch (err) {
                setLoading(false);
                console.error("Error on uploading file:", err);
                // Handle the error, show an error message, etc.
            }
        }
    };

    const handleOnSubmitCampaign = (e) => {
        e.preventDefault();

        const { file } = fileData;

        if (file) {
            setLoading(true);

            try {
                const formData = new FormData();
                formData.append("file", file);

                fileReader.onload = function (event) {
                    const csvOutput = event.target.result;
                    console.log("File Content:", csvOutput);

                    // Append the file content to the FormData
                    formData.append("csvContent", csvOutput);
                    console.log(formData, "formdatattt");
                    fetch(`${REACT_APP_SERVER_URL}/fileupload`, {
                        method: "POST",
                        credentials: "include",
                        body: formData,
                    })
                        .then((response) => {
                            console.log(response, "response consoling");
                            return response.json();
                        })
                        .then((data) => {
                            setLoading(false);
                            console.log(data);

                            if (data.status === 200) {
                                setQueryWarmup(false);
                                setpathUrl(data.data[0]);
                                setSuccessSnackbarOpen(true);
                                setSuccessMessage("File Uploaded");
                                setProcessCsv(true);
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            console.error("Error uploading file:", error);
                            // Handle the error, show an error message, etc.
                        });
                };

                fileReader.readAsText(file);
            } catch (err) {
                setLoading(false);
                console.error("Error on uploading file:", err);
                // Handle the error, show an error message, etc.
            }
        }
    };

    const onProcessCsvHandle = (e) => {
        let path = pathUrl;
        let type;
        if (queryWarmup === true) {
            type = "warmup";
        } else {
            type = "campaign";
        }

        try {
            fetch(`${REACT_APP_SERVER_URL}/processCsv?type=${type}&path=${path}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data, "data form process");
                    if (data.status === 200) {
                        setShowupload(false);
                        setProcessCsv(false);
                        setSuccessSnackbarOpen(true);
                        setSuccessMessage("File Processed Successully");
                    }
                });
        } catch (err) {
            console.log(err, "error");
        }
    };

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontSize: "1rem",
            fontWeight: "normal",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: "0.875rem",
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    const HandleChange1 = (event, newAlignment) => {
        setAlignment1(newAlignment);
        if (newAlignment === "Spam Share") {
            setSpamShare(true);
            setWarmupShare(false);
        } else {
            setSpamShare(false);
            setWarmupShare(true);
        }
    };

    const [fixedchartData, setfixedChartData] = useState({
        labels: [],
        datasets: [],
    });

    const [fixedchartOptions, setfixedChartOptions] = useState({
        scales: {
            x: { type: "linear", position: "bottom" },
            y: { beginAtZero: true },
        },
    });

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const [chartOptions, setChartOptions] = useState({
        scales: {
            x: { type: "linear", position: "bottom" },
            y: { beginAtZero: true },
        },
    });

    useEffect(() => {
        console.log(startDate, endDate, "Dates");
        fetchData();
        topSpammer();
        fetchBarGraphData();
        BarGraphDateWise();
        warmupCompose();
    }, []);

    const options = {
        title: `Top 10 Clients with Most Spam`,
        is3D: true,
    };

    function onSelectHandler(Chart) {
        const chartWrapper = Chart.chartWrapper;
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        setSelectedData(selection);
    }
    useEffect(() => {
        showUserDomains(selectedData);
    }, [selectedData]);

    function topSpammer() {
        setTimeout(() => {
            // let client = clients;

            try {
                fetch(`${REACT_APP_SERVER_URL}/dashboardSpam`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    method: "POST",
                    credentials: "include",
                    body: JSON.stringify({
                        client: ["Avatar Medical", "CognitiveHealth", "Ethos Business Solutions"],
                        from_date: "2023-01-01",
                        to_date: "2023-12-01",
                    }),
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        // console.log(data, 'data from line 81 printing')
                        if (data.status === 200) {
                            // setSpamRate(data.top_10_count);
                            // console.log(data.top_10_count
                            //     , 'data printing')
                            setSpamClientData(data.top_10_data_rangewise);
                            spamcount(data.top_10_data_rangewise);
                        } else if (data.status === 400) {
                            console.log("No data found");
                        } else {
                            console.log("Something went wrong");
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        }, 2000);
    }

    function spamcount(data) {
        let spamCountArray = data;
        let chartData = [["Client", "Spam Count"], ...spamCountArray.map((item) => [item.Client, item.count])];
        setSpamRate(chartData);
    }

    function fetchData() {
        try {
            fetch(`${REACT_APP_SERVER_URL}/fetchClients`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setClient(data.clients);
                });
        } catch (err) {
            console.log(err, "error");
        }
    }

    function fetchBarGraphData() {
        try {
            fetch(`${REACT_APP_SERVER_URL}/barGraphSpam `, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    DataofBarGraph(data.data);
                });
        } catch (err) {
            console.log(err, "error");
        }
    }

    function countToDate(datas) {
        // console.log(data, 'from count')
        let d;
        switch (datas) {
            case "01":
                d = "Jan";
                break;
            case "02":
                d = "Feb";
                break;
            case "03":
                d = "Mar";
                break;
            case "04":
                d = "Apr";
                break;
            case "05":
                d = "May";
                break;
            case "06":
                d = "Jun";
                break;
            case "07":
                d = "Jul";
                break;
            case "08":
                d = "Aug";
                break;
            case "09":
                d = "Sep";
                break;
            case "10":
                d = "Oct";
                break;
            case "11":
                d = "Nov";
                break;
            case "12":
                d = "Dec";
                break;
        }
        return d;
    }

    function DataofBarGraph(data) {
        let datelabel = data.map((entry) => countToDate(entry.month));

        const fixedcountData = {
            labels: datelabel,
            datasets: [
                {
                    label: "Danger Count",
                    backgroundColor: "rgb(220, 57, 18)",
                    borderColor: "black",
                    borderWidth: 1,
                    data: data.map((entry) => entry.danger_count),
                },
                {
                    label: "Domain Count",
                    backgroundColor: "rgb(51, 102, 204)",
                    borderColor: "black",
                    borderWidth: 1,
                    data: data.map((entry) => entry.domain_count),
                },
                {
                    label: "Content Count",
                    backgroundColor: "rgb(255, 153, 0)",
                    borderColor: "black",
                    borderWidth: 1,
                    data: data.map((entry) => entry.content_count),
                },
            ],
        };

        setfixedChartData(fixedcountData);

        const fixedchartOptions = {
            responsive: true,

            interaction: {
                mode: "index",
                intersect: false,
            },
            scales: {
                x: { type: "category", position: "bottom" },
                y: { beginAtZero: false },
            },
        };

        setfixedChartOptions(fixedchartOptions);
    }

    function BarGraphDateWise() {
        let requiredClients = chooseClient;
        let requiredStartDate = DateHandler(startDate);
        let requiredEndDate = DateHandler(endDate);

        try {
            fetch(`${REACT_APP_SERVER_URL}/barGraphSpamDatewise`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    client: requiredClients,
                    from_date: requiredStartDate,
                    to_date: requiredEndDate,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.status === 200) {
                        DateWiseBarGraph(data.data);

                        // setSpamRate(data.top_10_count);
                        // spamcount(data.top_10_count)
                    } else if (data.status === 400) {
                        console.log("No data found");
                    } else {
                        console.log("Something went wrong");
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }

    function DateWiseBarGraph(data) {
        setbarGraphDisplay(true);
        const newchartData = {
            labels: data.map((entry) => entry.Date),
            datasets: [
                {
                    label: "Danger Count",
                    backgroundColor: "rgb(220, 57, 18)",
                    borderColor: "black",
                    borderWidth: 1,
                    data: data.map((entry) => entry.danger_count),
                },
                {
                    label: "Domain Count",
                    backgroundColor: "rgb(51, 102, 204)",
                    borderColor: "black",
                    borderWidth: 1,
                    data: data.map((entry) => entry.domain_count),
                },
                {
                    label: "Content Count",
                    backgroundColor: "rgb(255, 153, 0)",
                    borderColor: "black",
                    borderWidth: 1,
                    data: data.map((entry) => entry.content_count),
                },
            ],
        };
        setChartData(newchartData);

        const newchartOptions = {
            responsive: true,
            interaction: {
                mode: "index",
                intersect: false,
            },
            scales: {
                x: { type: "category", position: "bottom" },
                y: { beginAtZero: false },
            },
        };
        setChartOptions(newchartOptions);
    }

    function handleClient() {
        setClientbox(true);
    }

    function clientsChangeshandle(e) {
        setClientbox(false);
    }

    function DateHandler(dateObject) {
        const year = dateObject.$y;
        const month = dateObject.$M + 1;
        const day = dateObject.$D;

        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        const newDate = `${year}-${formattedMonth}-${formattedDay}`;

        return newDate;
    }

    function saveDataHandler(e) {
        setLoading(true);
        let requiredClients = chooseClient;
        let requiredStartDate = DateHandler(startDate);
        let requiredEndDate = DateHandler(endDate);

        console.log(requiredClients, requiredStartDate, requiredEndDate, "inputValues");

        if (
            requiredClients.length <= 0 ||
            requiredStartDate === "undefined-NaN-undefined" ||
            requiredEndDate === "undefined-NaN-undefined"
        ) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Please Select Valid Inputs First");
            setLoading(false);
            return;
        }

        if (requiredStartDate > requiredEndDate) {
            requiredEndDate = requiredStartDate;
        }

        try {
            fetch(`${REACT_APP_SERVER_URL}/dashboardSpam`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    client: requiredClients,
                    from_date: requiredStartDate,
                    to_date: requiredEndDate,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setLoading(false);
                    // console.log(data.data, 'datas.datas from line')
                    if (data.status === 200) {
                        // setApiData(data.data)
                        setSpamClientData(data.top_10_data_rangewise);
                        spamcount(data.top_10_data_rangewise);
                        setSuccessSnackbarOpen(true);
                        setSuccessMessage("RESULT FETCHED SUCCESSFULLY");
                        stackedBarFunction(data.data);
                        setShowDatesValues(true);
                    } else if (data.status === 400) {
                        setErrorSnackbarOpen(true);
                        setErrorMessage("DATA NOT FOUND");
                        console.log("No data found");
                    } else {
                        console.log("Something went wrong");
                    }
                });
        } catch (err) {
            console.log(err, "error case lie on data save");
        }
    }

    function stackedBarFunction(datas) {
        setdisplayStackedBarGraph(true);
        let rawData = datas;

        const uniqueClients = [...new Set(rawData.map((item) => item.client))];
        const uniqueDates = [...new Set(rawData.map((item) => item.Date))];

        const datasets = uniqueClients.map((client) => ({
            label: client,
            data: uniqueDates.map((date) => {
                const matchingItem = rawData.find((item) => item.client === client && item.Date === date);
                return matchingItem ? matchingItem.spam_count : 0;
            }),
            backgroundColor: `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},2.0)`,
        }));

        const data = {
            labels: uniqueDates,
            datasets: datasets,
        };

        setStackedBarData(data);

        const options = {
            responsive: true,

            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                },
            },
        };

        setStackedBarOption(options);
    }

    function showUserDomains(index) {
        if (index.length <= 0) {
            return;
        }
        let i = index[0].row;

        let data = SpamClientData[i].Client;

        try {
            let requiredStartDate = DateHandler(startDate);
            let requiredEndDate = DateHandler(endDate);

            console.log(requiredStartDate, requiredEndDate);

            if (requiredStartDate === "undefined-NaN-undefined" && requiredEndDate === "undefined-NaN-undefined") {
                requiredStartDate = "2023-01-01";
                requiredEndDate = "2023-12-30";
            }

            fetch(
                `${REACT_APP_SERVER_URL}/fetchDomains?client=${data}&from_date=${requiredStartDate}&to_date=${requiredEndDate}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    method: "GET",
                    credentials: "include",
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    // console.log(data, 'data from line 81 printing')
                    if (data.status === 200) {
                        setDomainDisplay(true);
                        console.log(data, "from the table of spam");
                        setDomains(data.data);
                    } else if (data.status === 400) {
                        console.log("No data found");
                    } else {
                        console.log("Something went wrong");
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }

    function Datahandler(e) {
        saveDataHandler();
        BarGraphDateWise();
    }

    function warmupCompose() {
        console.log("hitting path");
        setLoading(true);
        let requiredClients = chooseClient;
        let requiredStart = DateHandler(startDate);
        let requiredEnd = DateHandler(endDate);
        setShowWarmupDatas(true);
        if (requiredClients.length <= 0) {
            requiredClients = ["Avatar Medical", "CognitiveHealth", "Ethos Business Solutions"];
            requiredStart = "2023-01-01";
            requiredEnd = "2023-12-30";
        }

        fetch(`${REACT_APP_SERVER_URL}/warmupVsCompose`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                client: requiredClients,
                from_date: requiredStart,
                to_date: requiredEnd,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setLoading(false);
                if (data.status === 200) {
                    // DateWiseBarGraph(data.data)
                    console.log(data, "data taking");
                    warmupGraph(data.data_for_graph);
                    setWarmup(data.data_rangewise);

                    setSuccessSnackbarOpen(true);
                    setSuccessMessage("RESULT FETCHED SUCCESSFULLY");

                    // setSpamRate(data.top_10_count);
                    // spamcount(data.top_10_count)
                } else if (data.status === 400) {
                    setErrorSnackbarOpen(true);
                    setErrorMessage("DATA NOT FOUND");
                    console.log("No data found");
                } else {
                    console.log("Something went wrong");
                }
            });
    }

    function warmupGraph(data) {
        console.log(data, "data from warpupsdds");

        const warmupchartData = {
            labels: data.map((entry) => entry.ClientName),
            datasets: [
                {
                    label: "Warmup Volume",
                    backgroundColor: "red",
                    borderColor: "black",
                    borderWidth: 1,
                    data: data.map((entry) => entry.warmup_volume),
                },
                {
                    label: "Campaign Volume",
                    backgroundColor: "blue",
                    borderColor: "black",
                    borderWidth: 1,
                    data: data.map((entry) => entry.campaign_volume),
                },
            ],
        };
        console.log(warmupchartData, "chdeeee");
        setwarmupGraphData(warmupchartData);

        const WarmupcomposeOptions = {
            responsive: true,
            interaction: {
                mode: "index",
                intersect: false,
            },
            scales: {
                x: { type: "category", position: "bottom" },
                y: { beginAtZero: false },
            },
        };
        setWarmupGraphOption(WarmupcomposeOptions);

        // setChartOptions(newchartOptions)
    }

    const handleClientClick = (client) => {
        if (chooseClient.includes(client)) {
            setchooseClient((prevChooseClient) =>
                prevChooseClient.filter((selectedClient) => selectedClient !== client)
            );
        } else {
            setchooseClient((prevChooseClient) => [...prevChooseClient, client]);
        }
    };

    const handleSelectAll = () => {
        if (chooseClient.length === clients.length) {
            setchooseClient([]);
        } else {
            setchooseClient([...clients]);
        }
    };

    const filteredWarmupData = warmup.filter((data) => {
        const clientNameLowerCase = data.ClientName.toLowerCase();
        const searchQueryLowerCase = searchQuery.toLowerCase();
        return clientNameLowerCase.includes(searchQueryLowerCase);
    });

    const filterClientData = clients.filter((data) => {
        const clientLowerCase = data.toLowerCase();
        const searchValueLowerCase = searchVal.toLowerCase();
        return clientLowerCase.includes(searchValueLowerCase);
    });

    function handlesearchClick(event) {
        event.stopPropagation();
    }

    function uploadFunction() {
        setShowupload(true);
    }

    function closeshowUploadHandler() {
        setShowupload(false);
    }

    function DownloadSampleWarmup() {
        try {
            fetch(`${REACT_APP_SERVER_URL}/demo_download_warmup`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    return response.blob();
                })
                .then((data) => {
                    // setClient(data.clients);
                    const blob = new Blob([data], { type: "text/csv" });

                    const url = window.URL.createObjectURL(blob);

                    const a = document.createElement("a");
                    a.href = url;
                    a.download = "sample_warmup.csv";

                    document.body.appendChild(a);

                    a.click();

                    document.body.removeChild(a);

                    // console.log(data, 'data fromCampaignSample')
                    // console.log(data.data, 'data.data fromCampaignSample')
                });
        } catch (err) {
            console.log(err, "error");
        }
    }

    function DownloadSampleCampaign() {
        try {
            fetch(`${REACT_APP_SERVER_URL}/demo_download_campaign`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    return response.blob();
                })
                .then((data) => {
                    // setClient(data.clients);
                    const blob = new Blob([data], { type: "text/csv" });

                    const url = window.URL.createObjectURL(blob);

                    const a = document.createElement("a");
                    a.href = url;
                    a.download = "sample_campaign.csv";

                    document.body.appendChild(a);

                    a.click();

                    document.body.removeChild(a);

                    // console.log(data, 'data fromCampaignSample')
                    // console.log(data.data, 'data.data fromCampaignSample')
                });
        } catch (err) {
            console.log(err, "error");
        }
    }

    function DownloadSampleSpamShare(e) {
        console.log("jhgjfjf");

        try {
            fetch(`${REACT_APP_SERVER_URL}/demo_spam_data`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    return response.blob();
                })
                .then((data) => {
                    const blob = new Blob([data], { type: "text/xlsx" });

                    const url = window.URL.createObjectURL(blob);

                    const a = document.createElement("a");
                    a.href = url;
                    a.download = "sample_spam.xlsx";

                    document.body.appendChild(a);

                    a.click();

                    document.body.removeChild(a);

                    // console.log(data, 'data fromCampaignSample')
                    // console.log(data.data, 'data.data fromCampaignSample')
                });
        } catch (err) {
            console.log(err, "error");
        }
    }

    return (
        <>
            <Box>
                <ToggleButtonGroup
                    // sx={{}}
                    color="primary"
                    variant="solid"
                    value={alignment1}
                    exclusive
                    onChange={HandleChange1}
                    aria-label="Platform"
                    sx={{ marginBottom: "20px" }}
                    // variant="solid"
                >
                    <ToggleButton value="Spam Share">Spam Share</ToggleButton>
                    <ToggleButton value="Warmup Share">Warmup VS Campaign</ToggleButton>
                </ToggleButtonGroup>
            </Box>

            {SpamShare && (
                <Box sx={{ bgcolor: "whitesmoke", color: "black", padding: "18px" }}>
                    <Box>
                        <button
                            style={{
                                padding: "5px",
                                margin: "5px",
                                width: "137px",
                                borderRadius: "10px",
                                border: "1px solid black",
                                background: "whitesmoke",
                                color: "black",
                                cursor: "pointer",
                            }}
                            onClick={uploadFunction}
                        >
                            UPLOAD SPAM FILE
                        </button>
                    </Box>

                    {showUpload && (
                        <Modal
                            open={showUpload}
                            onClose={() => {
                                setShowupload(false);
                                setLoading(false);
                                setProcessCsv(false);
                            }}
                            id="modal"
                        >
                            <Card id="card">
                                <CardContent id="content">
                                    <button onClick={DownloadSampleSpamShare} id="button">
                                        Download SPAM SHARE Sample
                                    </button>

                                    <Typography variant="h6">OR</Typography>

                                    <form>
                                        <input
                                            type="file"
                                            id="csvFileInput"
                                            accept=".xlsx"
                                            onChange={handleOnChange}
                                            className="fileInput"
                                        />
                                    </form>
                                    {Loading ? (
                                        <CircularProgress size={30}></CircularProgress>
                                    ) : (
                                        <div>
                                            {processCsv ? (
                                                <button onClick={(e) => processExcelSpam(e)} id="button">
                                                    Process CSV
                                                </button>
                                            ) : (
                                                <div>
                                                    <button onClick={(e) => handleOnSubmitSpamShare(e)} id="button">
                                                        Upload SPAM SHARE File
                                                    </button>
                                                    <Typography sx={{ margin: "10px" }}>
                                                        <span style={{ color: "red" }}>NOTE :</span>
                                                        UPLOAD SPAM SHARE FILE ONLY IN EXCEL FORMAT .XLSX
                                                    </Typography>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </CardContent>
                            </Card>
                        </Modal>
                    )}
                    {/* <h2 style={{marginTop:'0px'}}>Deliverability Dashboard</h2> */}

                    <Card
                        sx={{
                            bgcolor: "whitesmoke",
                            color: "white",
                            marginTop: 0,
                            borderRadius: "40px",
                        }}
                    >
                        <CardContent
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Card sx={{ borderRadius: "25px" }}>
                                <CardContent sx={{ cursor: "pointer", maxHeight: "400px", overflow: "auto" }}>
                                    <Button onClick={handleClient}>
                                        Select Clients<ArrowDropDownCircleIcon></ArrowDropDownCircleIcon>
                                    </Button>
                                    {clientbox && (
                                        <Box>
                                            <Box>
                                                <TextField
                                                    label="SEARCH CLIENT"
                                                    id="outlined-size-small"
                                                    size="small"
                                                    value={searchVal}
                                                    onClick={handlesearchClick}
                                                    onChange={(e) => setSearchVal(e.target.value)}
                                                />
                                            </Box>

                                            <ClickAwayListener onClickAway={clientsChangeshandle}>
                                                <MenuList>
                                                    <MenuItem key="select-all" onClick={() => handleSelectAll()}>
                                                        <Checkbox
                                                            checked={chooseClient.length === clients.length}
                                                            onChange={() => handleSelectAll()}
                                                        ></Checkbox>
                                                        Select All
                                                    </MenuItem>
                                                    {filterClientData &&
                                                        filterClientData.sort().map((client) => (
                                                            <MenuItem
                                                                key={client}
                                                                onClick={() => handleClientClick(client)}
                                                            >
                                                                <Checkbox
                                                                    checked={chooseClient.includes(client)}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setchooseClient((prevChooseClient) => [
                                                                                ...prevChooseClient,
                                                                                client,
                                                                            ]);
                                                                        } else {
                                                                            setchooseClient((prevChooseClient) =>
                                                                                prevChooseClient.filter(
                                                                                    (selectedClient) =>
                                                                                        selectedClient !== client
                                                                                )
                                                                            );
                                                                        }
                                                                    }}
                                                                ></Checkbox>
                                                                {client}
                                                            </MenuItem>
                                                        ))}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Box>
                                    )}
                                </CardContent>
                            </Card>

                            <Card sx={{ borderRadius: "25px" }}>
                                <CardContent
                                    sx={{ marginTop: -1, marginBottom: -1, marginLeft: -0.5, marginRight: -0.5 }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={["DatePicker"]}>
                                            <DatePicker
                                                label="Start Date"
                                                value={value}
                                                onChange={(e) => {
                                                    setStartDate(e);
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </CardContent>
                            </Card>

                            <Card sx={{ borderRadius: "25px" }}>
                                <CardContent
                                    sx={{ marginTop: -1, marginBottom: -1, marginLeft: -0.5, marginRight: -0.5 }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={["DatePicker"]}>
                                            <DatePicker
                                                label="End Date"
                                                value={value}
                                                disableFuture={true}
                                                onChange={(e) => {
                                                    setEndDate(e);
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </CardContent>
                            </Card>

                            <Paper
                                sx={{
                                    background: "#c1c1c1",
                                    boxShadow: "20px 20px 60px #00000041 inset, -20px -20px 60px #ffffff40",
                                    borderRadius: "40px",
                                }}
                            >
                                {Loading ? (
                                    <CircularProgress size={30}></CircularProgress>
                                ) : (
                                    <Button
                                        sx={{
                                            padding: "12px",
                                            background: "#3366CC",
                                            color: "white",
                                            borderRadius: "35px",
                                            width: "129px",
                                            height: "53px",
                                        }}
                                        onClick={Datahandler}
                                    >
                                        Save Changes
                                    </Button>
                                )}
                            </Paper>
                        </CardContent>
                    </Card>

                    <Box>
                        {displayStackedBarGraph && (
                            <Card sx={{ margin: "10px", borderRadius: "40px" }}>
                                <CardContent>
                                    <Typography>CLIENT APPEARED IN SPAM VS DATE</Typography>

                                    <Bar data={stackedBarData} options={stackedBarOption}></Bar>
                                </CardContent>
                            </Card>
                        )}

                        {barGraphDisplay && (
                            <Card sx={{ margin: "10px", borderRadius: "40px" }}>
                                <CardContent>
                                    <Typography>COUNTS VS DATE</Typography>

                                    <Bar data={chartData} options={chartOptions} />
                                </CardContent>
                            </Card>
                        )}
                    </Box>
                    <Box>
                        <Card sx={{ background: "#ffff", borderRadius: "40px", margin: "10px" }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 25, fontWeight: 500, fontStyle: "oblique" }}>
                                    {" "}
                                    YEARLY RECORDS
                                </Typography>
                                <Typography>COUNTS VS MONTHS</Typography>
                                <Bar
                                    data={fixedchartData}
                                    options={fixedchartOptions}
                                    width={"100%"}
                                    height={"30%"}
                                ></Bar>
                            </CardContent>
                        </Card>

                        <Card sx={{ margin: "10px", borderRadius: "40px" }}>
                            <CardContent>
                                {showDatesValues && (
                                    <Typography sx={{ fontSize: 23, fontWeight: 400, fontStyle: "oblique" }}>
                                        {" "}
                                        {DateHandler(startDate)} TO {DateHandler(endDate)}
                                    </Typography>
                                )}
                                <Chart
                                    chartType="PieChart"
                                    data={spamRate}
                                    options={options}
                                    chartEvents={[
                                        {
                                            eventName: "select",
                                            callback: onSelectHandler,
                                        },
                                    ]}
                                    // onClick={showUserDomains}
                                    width={"100%"}
                                    height={"400px"}
                                />
                            </CardContent>
                        </Card>

                        <Box>
                            {Domains && (
                                <Box>
                                    {DomainDispaly && (
                                        <Box>
                                            <TableContainer
                                                sx={{
                                                    border: "1px solid #E7E7E7",
                                                    marginTop: "15px",
                                                    maxWidth: 1100,
                                                    maxHeight: 500,
                                                    borderRadius: "40px",
                                                    marginLeft: 1.2,
                                                }}
                                            >
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell sx={{ fontWeight: "bolder" }} align="left">
                                                                Domain
                                                            </StyledTableCell>
                                                            <StyledTableCell sx={{ fontWeight: "bolder" }} align="left">
                                                                Count
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {Domains &&
                                                            Domains.map((data) => (
                                                                <StyledTableRow>
                                                                    <StyledTableCell align="left" id="dashboard">
                                                                        {data.Spam_id}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left" id="dashboard">
                                                                        {data.count}
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}

            {WarmupShare && (
                <Box sx={{ marginTop: "15px", bgcolor: "whitesmoke", color: "black", padding: "18px" }}>
                    <Box sx={{}}>
                        <Box>
                            <button
                                style={{
                                    padding: "5px",
                                    margin: "5px",
                                    width: "197px",
                                    borderRadius: "10px",
                                    border: "1px solid black",
                                    background: "whitesmoke",
                                    color: "black",
                                    cursor: "pointer",
                                }}
                                onClick={uploadFunction}
                            >
                                UPLOAD WARMUP/CAMPAIGN FILE
                            </button>
                        </Box>

                        {showUpload && (
                            <Modal
                                open={showUpload}
                                onClose={() => {
                                    setShowupload(false);
                                    setLoading(false);
                                    setProcessCsv(false);
                                }}
                                id="modal"
                            >
                                <Card id="card">
                                    <CardContent id="content">
                                        <button onClick={DownloadSampleWarmup} id="button">
                                            Download Warmup Sample
                                        </button>
                                        <button onClick={DownloadSampleCampaign} id="button">
                                            Download Campaign Sample
                                        </button>

                                        <Typography variant="h6">OR</Typography>

                                        <form>
                                            <input
                                                type="file"
                                                id="csvFileInput"
                                                accept=".csv"
                                                onChange={handleOnChange}
                                                className="fileInput"
                                            />
                                        </form>
                                        {Loading ? (
                                            <CircularProgress size={30}></CircularProgress>
                                        ) : (
                                            <div>
                                                {processCsv ? (
                                                    <button onClick={(e) => onProcessCsvHandle(e)} id="button">
                                                        Process CSV
                                                    </button>
                                                ) : (
                                                    <div>
                                                        <button onClick={(e) => handleOnSubmitWarmup(e)} id="button">
                                                            Upload Warmup File
                                                        </button>
                                                        <button onClick={(e) => handleOnSubmitCampaign(e)} id="button">
                                                            Upload Campaign File
                                                        </button>

                                                        <Typography sx={{ margin: "10px" }}>
                                                            <span style={{ color: "red" }}>NOTE :</span>
                                                            UPLOAD SPAM SHARE FILE ONLY IN CSV FORMAT .
                                                        </Typography>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </CardContent>
                                </Card>
                            </Modal>
                        )}

                        <Card
                            sx={{
                                bgcolor: "whitesmoke",
                                color: "white",
                                marginTop: 0,
                                margin: "10px",
                                borderRadius: "40px",
                            }}
                        >
                            <CardContent
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Card sx={{ borderRadius: "25px" }}>
                                    <CardContent sx={{ cursor: "pointer", maxHeight: "400px", overflow: "auto" }}>
                                        <Button onClick={handleClient}>
                                            Select Clients<ArrowDropDownCircleIcon></ArrowDropDownCircleIcon>
                                        </Button>
                                        {clientbox && (
                                            <Box>
                                                <Box>
                                                    <TextField
                                                        label="SEARCH CLIENT"
                                                        id="outlined-size-small"
                                                        size="small"
                                                        value={searchVal}
                                                        onClick={handlesearchClick}
                                                        onChange={(e) => setSearchVal(e.target.value)}
                                                    />
                                                </Box>

                                                <ClickAwayListener onClickAway={clientsChangeshandle}>
                                                    <MenuList>
                                                        <MenuItem key="select-all" onClick={() => handleSelectAll()}>
                                                            <Checkbox
                                                                checked={chooseClient.length === clients.length}
                                                                onChange={() => handleSelectAll()}
                                                            ></Checkbox>
                                                            Select All
                                                        </MenuItem>
                                                        {filterClientData &&
                                                            filterClientData.sort().map((client) => (
                                                                <MenuItem
                                                                    key={client}
                                                                    onClick={() => handleClientClick(client)}
                                                                >
                                                                    <Checkbox
                                                                        checked={chooseClient.includes(client)}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                setchooseClient((prevChooseClient) => [
                                                                                    ...prevChooseClient,
                                                                                    client,
                                                                                ]);
                                                                            } else {
                                                                                setchooseClient((prevChooseClient) =>
                                                                                    prevChooseClient.filter(
                                                                                        (selectedClient) =>
                                                                                            selectedClient !== client
                                                                                    )
                                                                                );
                                                                            }
                                                                        }}
                                                                    ></Checkbox>{" "}
                                                                    {client}
                                                                </MenuItem>
                                                            ))}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Box>
                                        )}
                                    </CardContent>
                                </Card>

                                <Card sx={{ borderRadius: "25px" }}>
                                    <CardContent
                                        sx={{ marginTop: -1, marginBottom: -1, marginLeft: -0.5, marginRight: -0.5 }}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={["DatePicker"]}>
                                                <DatePicker
                                                    label="Start Date"
                                                    value={value}
                                                    onChange={(e) => {
                                                        setStartDate(e);
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </CardContent>
                                </Card>

                                <Card sx={{ borderRadius: "25px" }}>
                                    <CardContent
                                        sx={{ marginTop: -1, marginBottom: -1, marginLeft: -0.5, marginRight: -0.5 }}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={["DatePicker"]}>
                                                <DatePicker
                                                    label="End Date"
                                                    value={value}
                                                    disableFuture={true}
                                                    onChange={(e) => {
                                                        setEndDate(e);
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </CardContent>
                                </Card>

                                <Paper
                                    sx={{
                                        background: "#c1c1c1",
                                        boxShadow: "20px 20px 60px #00000041 inset, -20px -20px 60px #ffffff40",
                                        borderRadius: "40px",
                                    }}
                                >
                                    <Button
                                        sx={{
                                            padding: "12px",
                                            background: "#3366CC",
                                            color: "white",
                                            borderRadius: "35px",
                                            width: "129px",
                                            height: "53px",
                                        }}
                                        onClick={warmupCompose}
                                    >
                                        FETCH DATA
                                    </Button>
                                </Paper>
                            </CardContent>
                        </Card>
                    </Box>

                    {Loading ? (
                        <CircularProgress size={35} />
                    ) : (
                        <Box>
                            {showWarmupDatas && (
                                <Box>
                                    <Card sx={{ background: "#ffff", borderRadius: "40px" }}>
                                        <CardContent>
                                            <Line data={warmupGraphData} options={warmupGraphOption}></Line>
                                        </CardContent>
                                    </Card>

                                    <Box>
                                        <TextField
                                            sx={{
                                                mb: "-23px",
                                                mt: "18px",
                                                ml: "56px",
                                                background: "whitesmoke",
                                                backgroundColor: "",
                                                borderRadius: "22px",
                                            }}
                                            label="SEARCH CLIENT"
                                            id="outlined-size-small"
                                            size="small"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </Box>

                                    <Box>
                                        <TableContainer
                                            sx={{
                                                border: "1px solid #E7E7E7",
                                                marginTop: "30px",
                                                maxWidth: 1200,
                                                maxHeight: 500,
                                                borderRadius: "40px",
                                                marginLeft: 1.2,
                                                marginRight: 1.8,
                                            }}
                                        >
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell sx={{ fontWeight: "bolder" }} align="left">
                                                            Client
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            sx={{ fontWeight: "bolder", marginLeft: "-23px" }}
                                                            align="left"
                                                        >
                                                            Date
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ fontWeight: "bolder" }} align="left">
                                                            Warmup Volume
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ fontWeight: "bolder" }} align="left">
                                                            Campaign Volume
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ fontWeight: "bolder" }} align="left">
                                                            Adjust Warmup Volume
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ fontWeight: "bolder" }} align="left">
                                                            Adjust%
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filteredWarmupData &&
                                                        filteredWarmupData.map((data) => (
                                                            <StyledTableRow>
                                                                <StyledTableCell
                                                                    align="left"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                    id="dashboard"
                                                                >
                                                                    {data.ClientName}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    align="left"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                    id="dashboard"
                                                                >
                                                                    {data.formatted_date}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    align="left"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                    id="dashboard"
                                                                >
                                                                    {`${data.warmup_volume}` === "null"
                                                                        ? "-"
                                                                        : `${data.warmup_volume}`}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    align="left"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                    id="dashboard"
                                                                >
                                                                    {`${data.campaign_volume}` === "null"
                                                                        ? "-"
                                                                        : `${data.campaign_volume}`}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    align="left"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                    id="dashboard"
                                                                >
                                                                    {`${data.volume_increase}` === "null"
                                                                        ? "-"
                                                                        : `${Math.abs(data.volume_increase)}`}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    align="left"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                    id="dashboard"
                                                                >
                                                                    {`${data.increase_in_campaign_percent}` === "null"
                                                                        ? "-"
                                                                        : `${Math.abs(data.increase_in_campaign_percent)}%`}
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            )}
            <Snackbar open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
};
export default DataDashboard;
