import React from "react";
import { useParams } from "react-router-dom";
import SelectedSenderAccordian from "./SelectedSenderAccordian";
import Overview from "../AuditOverview/AuditOverview";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Container, Link, Typography } from "@mui/material";
const ManualAccordian = () => {
    let { selectedSender } = useParams();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$/;

    return (
        <Container>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator="››"
                className="bread-crumbs"
                style={{ margin: "10px", fontSize: "16px", border: "1px solid #f1f1f1", padding: "10px" }}
            >
                <Link
                    underline="hover"
                    color="inherit"
                    href="/dashboard/manualAudit"
                    sx={{
                        fontSize: "20px",
                        color: "primary.main",
                        textDecoration: "none",
                        "&:hover": {
                            textDecoration: "underline",
                        },
                    }}
                >
                    Manual Audit
                </Link>
                <Typography
                    color="text.primary"
                    sx={{
                        color: "text.primary",
                        fontSize: "20px",
                    }}
                >
                    Audit Report
                </Typography>
            </Breadcrumbs>
            {emailRegex.test(selectedSender) || domainRegex.test(selectedSender) ? (
                <SelectedSenderAccordian selectedSender={selectedSender} />
            ) : (
                <Overview unicodeId={selectedSender} />
            )}
        </Container>
    );
};
export default ManualAccordian;
