import React, { useEffect } from "react";
import { Button, Snackbar, Alert, Box } from "@mui/material";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Step3 = ({ onPrevious, domain, onClose }) => {
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [successMessage, setsuccessMessage] = React.useState(false);

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
    };

    useEffect(() => {
        setSuccessSnackbarOpen(true);
        setsuccessMessage("Domain verified successfully");
    });

    function handleDone() {
        onClose();
    }

    return (
        <Box sx={{ padding: "10px" }}>
            <h1 style={{ textAlign: "center", marginTop: "-5px", padding: "10px" }}>Verification Page</h1>
            <p style={{ textAlign: "center", fontSize: "20px" }}>{domain} is Verified.</p>
            <Button variant="contained" sx={{ marginLeft: "40%" }} onClick={handleDone}>
                Done
            </Button>
            <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Step3;
