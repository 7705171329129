import { List, ListItem, ListItemText, Chip, Divider, Container } from "@mui/material";
const AuditAccordianDetails = ({ data }) => {
    const slicedData = data ? data.slice(1) : [];
    slicedData.sort((a, b) => {
        const statusOrder = {
            Fail: 1,
            Pass: 2,
        };
        return statusOrder[a.status] - statusOrder[b.status];
    });
    return(
        <List>
            {slicedData.map((li, index) => (
                <Container key={index}>
                    <ListItem>
                        <ListItemText primary={li.title} />
                        <Chip
                            label={li.status}
                            style={{
                                backgroundColor: li.status === "Fail" ? "#d32f2f" : "green",
                                color: "white",
                            }}
                        />
                    </ListItem>
                    <Divider />
                </Container>
            ))}
        </List>
    );
};
export default AuditAccordianDetails;
