import React from "react";
import { Container, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LinearProgressWithLabel from "../LinearProgressWithLabel";
import PropTypes from "prop-types";
import Overview from "../AuditOverview/AuditOverview";
import AuditSteps from "./AuditSteps";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import axios from "axios";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SelectedSenderAccordian = ({ selectedSender }) => {
    const intervalRef = React.useRef(null);
    const url = window.location.href;
    const urlObj = new URL(url);

    // Get the search parameters
    const params = new URLSearchParams(urlObj.search);

    // Extract the 'reCheck' parameter value
    const reCheck = params.get("reCheck");

    const [count, setCount] = useState(0);
    const reCheckVerify = localStorage.getItem(reCheck);
    // const [showOverView, setShowOverView] = useState(false);
    const [records, setRecords] = useState([]);
    const [accordianStyle, setaccordianStyle] = useState([]);
    const [accordianData, setAccordianData] = useState([]);
    const [progress, setProgress] = useState(0);
    const [randomStringForProcess, setRandomStringForProcess] = useState(reCheckVerify ? reCheck : "");
    const [isLoading, setIsLoading] = useState(false);
    const [processStatus, setProcessStatus] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [showError, setShowError] = useState(false);
    const [calculateScore, setCalculateScore] = useState(0);
    const [revalidate, setRevalidate] = useState(false);
    const [circularProgress, setCircularProgress] = useState(false);
    useEffect(() => {
        if (isLoading) {
            const initialTimeout = setTimeout(() => {
                fetchProcessStatus();
                intervalRef.current = setInterval(() => {
                    fetchProcessStatus();
                }, 5000);
            }, 10000);

            return () => {
                clearTimeout(initialTimeout);
                clearInterval(intervalRef.current);
            };
        }
    }, [isLoading]);
    const fetchProcessStatus = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/manualAuditProcessStatusCheck?code=${randomStringForProcess}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                }
            );
            const result = await response.json();
            if (result.success) {
                const { processPercentage, processStatus } = result.data;
                setProcessStatus(processStatus);

                if (processPercentage !== undefined) {
                    setProgress(processPercentage);
                }
                if (processPercentage >= 100 || processStatus === "Fail") {
                    clearInterval(intervalRef.current);
                    setProcessStatus([]);
                    setProgress(0);
                    setIsLoading(false);
                }
            } else {
                console.error("Failed to fetch process status:", result.msg);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error fetching process status:", error);
        }
    };
    LinearProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
    };

    const handleRecords = (recordList) => {
        const allData = [];
        recordList.map((acc, index) => {
            const newData = [];
            acc.map((list) => {
                const updatedTitle = list.title && list.title.replace(/\b\w/g, (char) => char.toUpperCase());
                const updatedProcessName =
                    list.processName && list.processName.replace(/\b\w/g, (char) => char.toUpperCase());
                if (list.processName) {
                    newData.push({ processName: updatedProcessName });
                } else if (list?.data === "inbox") {
                    newData.push({ title: updatedTitle, status: "Pass" });
                } else if (list?.data?.spf && list?.data?.dkim && list?.data?.dmarc) {
                    newData.push({ title: updatedTitle, status: "Pass" });
                } else if (
                    list?.data?.domainListedZones?.listed?.length === 0 &&
                    list.data.domainSendMailResult.label === "inbox"
                ) {
                    newData.push({ title: updatedTitle, status: "Pass" });
                } else if (list?.data?.ipListedZones?.listed?.length === 0) {
                    newData.push({ title: updatedTitle, status: "Pass" });
                } else if (list.title === "Element counts") {
                    newData.push({ title: updatedTitle, status: "Pass" });
                } else {
                    newData.push({ title: updatedTitle, status: "Fail" });
                }
            });
            allData.push(newData);
        });
        handleStyle(allData);
        setAccordianData(allData);
    };

    const generateRandomString = async (length) => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const handleSubmit = async (clientData) => {
        if (revalidate) {
            records.pop();
            setRecords(records);
            handleRecords(records);
        }

        setIsLoading(true);
        localStorage.setItem(`${randomStringForProcess}-Step2`, true);

        fetch(
            `${REACT_APP_SERVER_URL}/manualAuditClientContentCheck?sender=${selectedSender}&code=${randomStringForProcess}`,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "text/html",
                },
                credentials: "include",
                method: "POST",
                body: JSON.stringify(clientData),
            }
        )
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                if (result.success) {
                    const existingRecords = [...records, result.data];
                    handleRecords(existingRecords);
                    setRecords((prev) => [...prev, result.data]);
                    setCalculateScore(result.score);
                    localStorage.removeItem(randomStringForProcess);
                } else {
                    setShowError(true);
                    setTimeout(() => setShowError(false), 4000);
                }
                setIsLoading(false);
            });
        setRevalidate(false);
        setShowDialog(false);
    };
    useEffect(() => {
        gettingResult();
    }, []);
    useEffect(() => {
        if (!isLoading && count <= 5 && count > 0) {
            const intervalId = setInterval(() => {
                setCount((prevCount) => prevCount + 1);
                setCircularProgress(true);
                const checkRecords = localStorage.getItem(reCheck) ? JSON.parse(localStorage.getItem(reCheck)) : false;
                if (checkRecords) {
                    setCircularProgress(false);
                    setRecords([checkRecords.data]);
                    handleRecords([checkRecords.data]);
                    setCalculateScore(checkRecords.score);
                }
            }, 3000); // Interval of 1 second

            // Clear interval after 5 counts
            if (count >= 5) {
                clearInterval(intervalId);
            }
            if (count >= 5 && !localStorage.getItem(reCheck)) {
                handleRecheck();
            }

            // Cleanup function to clear interval when component unmounts
            return () => clearInterval(intervalId);
        }
    }, [count, !isLoading]);
    async function gettingResult() {
        setIsLoading(true);
        setRandomStringForProcess(reCheck);
        if (!reCheck) {
            const randomString = await generateRandomString(10);

            setRandomStringForProcess(randomString);
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/manualAuditSafeContentCheck?sender=${selectedSender}&code=${randomString}`,
                    {
                        headers: {
                            Accept: "application/json",
                        },
                        withCredentials: "include",
                    }
                )
                .then((result) => {
                    setRecords([result.data.data]);
                    handleRecords([result.data.data]);
                    setCalculateScore(result.data.score);
                    setShowError(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    setShowError(true);
                    console.log(error);
                });
        } else {
            const checkRecords = localStorage.getItem(reCheck) ? JSON.parse(localStorage.getItem(reCheck)) : false;
            if (checkRecords) {
                setRecords([checkRecords.data]);
                handleRecords([checkRecords.data]);
                setCalculateScore(checkRecords.score);
                setIsLoading(false);
            } else {
                setCount(1);
            }
        }
    }
    const handleRecheck = () => {
        axios
            .get(`${REACT_APP_SERVER_URL}/fetchFirstProcess?code=${reCheck}`, {
                headers: {
                    Accept: "application/json",
                },
                withCredentials: "include",
            })
            .then((res) => setCount(1))
            .catch((err) => {
                setCircularProgress(false);
                setShowError(true);
            });
    };
    const handleStyle = (data) => {
        let statusStyle = [];
        data.map((accordianStatus) => {
            const slicedData = accordianStatus.slice(1);
            let checkStatus = slicedData.some((obj) => obj.hasOwnProperty("status"));
            if (checkStatus) {
                checkStatus = "pass";
                slicedData.filter((value) => {
                    if (value.status === "Fail") {
                        checkStatus = "fail";
                        return;
                    }
                });
            }

            let style = {
                padding: "10px",
                margin: "20px",
                borderLeft:
                    checkStatus === false ? "" : checkStatus === "pass" ? "7px solid #08800094" : "7px solid #ff000085",
                backgroundColor:
                    checkStatus === false
                        ? ""
                        : checkStatus === "pass"
                          ? "rgb(8 128 0 / 7%)"
                          : "rgba(255, 204, 203, 0.33)",
            };
            statusStyle.push(style);
        });
        setaccordianStyle(statusStyle);
    };
    const previousHandler = () => {
        setRevalidate(true);
        setShowDialog(true);
    };
    return (
        <Container className="accordiancontainer">
            {/* {showOverView ? (
                <Overview
                    senderAccordianData={accordianData ? accordianData : []}
                    senderStyle={accordianStyle && accordianStyle}
                    senderRecords={records ? records : []}
                    score={calculateScore}
                    
                />
            ) : ( */}
            <AuditSteps
                records={records}
                isLoading={isLoading}
                processStatus={processStatus}
                progress={progress}
                accordianData={accordianData}
                accordianStyle={accordianStyle}
                handleDialog={() => setShowDialog(!showDialog)}
                showDialog={showDialog}
                handleClientSubmit={(data) => handleSubmit(data)}
                // handleOverView={() => setShowOverView(true)}
                showError={showError}
                senderId={selectedSender}
                previousHandler={previousHandler}
                reCheckId={reCheck}
                circularProgress={circularProgress}
                randomStringForProcess={randomStringForProcess}
            />
            {/* )} */}
        </Container>
    );
};
export default SelectedSenderAccordian;
