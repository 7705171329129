import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";

function LinearProgressWithLabel({ value }) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress
                    variant="determinate"
                    value={value}
                    sx={{
                        "& .MuiLinearProgress-bar": {
                            backgroundImage: "linear-gradient(to right, #6fcbb6, #9c64f4)",
                        },
                    }}
                />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    );
}

export default LinearProgressWithLabel;
