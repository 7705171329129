import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { CheckBox } from "@mui/icons-material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import "./dnsrecords.css";
import {
    Box,
    Button,
    TableRow,
    TableCell,
    Typography,
    CircularProgress,
    Snackbar,
    Alert,
    TableBody,
    TableContainer,
    TableHead,
    Table,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const options = ["A", "TXT", "MX", "NS", "SPF", "DMARC", "AAAA"];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F8F9FB",
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "center",
        padding: "15px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        textAlign: "center",
        padding: "10px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: "#f1f1f1",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const DnsRecords = () => {
    const [domain, setDomain] = useState([]);
    const [recordType, setRecordType] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [result, setResult] = useState([]);
    const [mx, setMx] = useState([]);
    const [spf, setSpf] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [clientValue, setClientvalue] = useState("");
    const [inputValue, setInputValue] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(2);
    const [userClientNames, setUserClientNames] = useState([]);
    const [value, setValue] = React.useState(null);
    const [domainValue, setDomainValue] = React.useState(null);
    const [addNewDomain, setAddNewDomain] = React.useState(false);
    const [addDomainLoading, setAddDomainLoading] = useState(false);
    const filter = createFilterOptions();
    const navigate = useNavigate();
    useEffect(() => {
        findClientNames();
    }, []);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    const handleMenuItemClick = (event, index) => {
        setRecordType(records[index]);
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const isDomainPresent = (array, input) => {
        if (input && input.domain) {
            return array.some((object) => object.domain === input.domain);
        }
        return false;
    };

    const handleSubmit = (recordType) => {
        setRecordType(recordType);
        if (!domainValue?.domain) {
            setErrorSnackbarOpen(true);
            setErrorMessage(`fields can not be empty`);
            setResult([]);
            setMx([]);
            setSpf([]);

            return;
        } else {
            try {
                setButtonLoading(true);
                fetch(`${REACT_APP_SERVER_URL}/api/dns`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "POST",
                    credentials: "include",
                    body: new URLSearchParams({
                        domain: domainValue.domain,
                        recordType: recordType.type,
                    }),
                })
                    .then((response) => {
                        setButtonLoading(false);
                        setLoading(false);

                        return response.json();
                    })
                    .then((data) => {
                        if (data.status === 200) {
                            if (recordType.type === "MX") {
                                setMx(data.data);
                                setResult([]);
                                setSpf([]);
                            } else if (recordType.type === "SPF") {
                                setSpf(data.data);
                                setMx([]);
                                setResult([]);
                            } else {
                                setResult(data.data);
                                setMx([]);
                                setSpf([]);
                            }
                            return;
                        } else {
                            setErrorSnackbarOpen(true);
                            setErrorMessage(`No ${recordType.type} Record found`);
                            setResult([]);
                            setMx([]);
                            setSpf([]);

                            return;
                        }
                    });
            } catch (error) {
                console.log(error);
            }
        }
    };

    const findClientNames = () => {
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/findClientNames`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setUserClientNames(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    };

    async function handleDomainList(client) {
        fetch(`${REACT_APP_SERVER_URL}/findingDomainListWithClientName?ClientName=${client}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setDomain(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");

                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    }

    const addDomain = () => {
        if (domainValue.domain && domainValue.domain.length < 4) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Enter Valid domain.");
            return;
        }
        setAddDomainLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/addDomain`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                domain: domainValue.domain,
                client: value.clientName,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setAddDomainLoading(false);

                if (data.status === 200) {
                    // onNext(domain, data.txt)
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage(`${data.domain}-Domain Added Successfully`);
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage("Invalid Domain");
                }
                setAddNewDomain(false);
            });
    };
    const SkeletonTableBody = ({ rowCount = 5 }) => {
        const skeletonRows = [...Array(rowCount)].map((_, index) => (
            <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" width={200} />
                </StyledTableCell>
                {[...Array(4)].map((_, cellIndex) => (
                    <StyledTableCell key={cellIndex} align="right">
                        <Skeleton variant="text" width={200} />
                    </StyledTableCell>
                ))}
            </StyledTableRow>
        ));
        return <TableBody>{skeletonRows}</TableBody>;
    };

    const records = [
        { type: "A", text: "The A Record, or address record, links your domain or sub-domain to an IPv4 address." },
        {
            type: "TXT",
            text: "The TXT Record lets administrators insert custom text into DNS records. Common usemucus with bloods include SPF, DKIM, and DMARC for email authentication.",
        },
        {
            type: "MX",
            text: "The MX Record directs email traffic by specifying mail exchange servers responsible for routing emails to their intended destinations.",
        },
        {
            type: "NS",
            text: "The NS Record points to authoritative name servers responsible for managing and publishing DNS records for your domain.",
        },
        {
            type: "SPF",
            text: "Check the existence of SPF records in the 'TXT' DNS records of a domain or host to validate email authenticity.",
        },
        {
            type: "DMARC",
            text: "Ensure email security by verifying your domain's DMARC policy. Improve email deliverability and protect against unauthorized use.",
        },
        {
            type: "AAAA",
            text: "The AAAA Record, or address record, links your domain or sub-domain to an IPv6 address.",
        },
    ];

    return (
        <Box sx={{ marginTop: "-8px", overflow: "hidden" }}>
            <Button
                sx={{ margin: "15px" }}
                onClick={() => {
                    navigate(-1);
                }}
            >
                <ArrowBackIos />
                Back
            </Button>
            <Box sx={{ textAlign: "center" }}>
                <span style={{ fontSize: "30px", fontWeight: "1200px", lineHeight: "38px" }}>DNS Record</span>
                <br />
                <span
                    style={{
                        textAlign: "center",
                        fontSize: "18px",
                        color: "rgba(71, 84, 103, 1)",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        marginTop: "20px",
                    }}
                >
                    Discover all DNS records for a domain with ease. From A to TXT, view various record types
                    effortlessly. Enter the domain name, choose a record type from the dropdown.
                </span>
                <br />
                <span
                    style={{
                        textAlign: "center",
                        fontSize: "18px",
                        color: "rgba(71, 84, 103, 1)",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        marginTop: "20px",
                    }}
                >
                    New updated record might take 24 to 48 hours to reflect.
                </span>
            </Box>
            <br />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Autocomplete
                    value={value}
                    inputValue={clientValue}
                    onInputChange={(event, newInputValue) => {
                        // Capture input changes
                        setClientvalue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setValue({
                                clientName: newValue.clientName,
                            });
                            handleDomainList(newValue.clientName);
                            setClientvalue({
                                clientName: newValue.clientName,
                            });
                        } else {
                            console.log("lies inside the else block");
                            setValue(newValue);
                            handleDomainList(newValue);
                            setClientvalue("");
                        }
                    }}
                    onBlur={() => {
                        setValue({
                            clientName: clientValue,
                        });
                        handleDomainList(clientValue);
                    }}
                    id="free-solo-with-text-demo"
                    options={userClientNames}
                    getOptionLabel={(option) => {
                        if (typeof option === "string") {
                            return option;
                        }
                        if (option.clientName) {
                            return option.clientName;
                        }
                        return option.clientName;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.clientName}</li>}
                    sx={{ width: 250, height: "30px" }}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Client Name" />}
                />

                <Autocomplete
                    value={domainValue}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        // Capture input changes
                        setInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setDomainValue({ domain: newValue.domain });
                            setInputValue({ domain: newValue.domain });
                            if (isDomainPresent(domain, newValue)) {
                                setAddNewDomain(false);
                            } else {
                                setAddNewDomain(true);
                            }
                        } else {
                            setDomainValue(null);
                        }
                    }}
                    onBlur={() => {
                        if (isDomainPresent(domain, { domain: inputValue })) {
                            setDomainValue({ domain: inputValue });
                            setAddNewDomain(false);
                        } else {
                            setDomainValue({ domain: inputValue });
                            setAddNewDomain(true);
                        }
                    }}
                    id="free-solo-with-text-demo"
                    options={domain}
                    getOptionLabel={(option) => {
                        if (typeof option === "string") {
                            return option;
                        }
                        if (option && option.domain) {
                            return option.domain;
                        }
                        return "";
                    }}
                    renderOption={(props, option) => <li {...props}>{option.domain || option}</li>}
                    sx={{ width: 250, marginLeft: "15px" }}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Choose Domain" />}
                />

                <form>
                    {domainValue !== null &&
                        domainValue?.domain !== "" &&
                        addNewDomain &&
                        (addDomainLoading ? (
                            <CircularProgress size={25} />
                        ) : (
                            <Button
                                onClick={addDomain}
                                sx={{ background: "#004aad", color: "white", ml: 3, padding: "10px", marginTop: "5px" }}
                                variant="contained"
                            >
                                Add this Domain
                            </Button>
                        ))}
                </form>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    textAlign: "center",
                    margin: "30px",
                    justifyContent: "center",
                    marginTop: "30px",
                }}
            >
                {records.map((record, index) => (
                    <Button
                        key={index}
                        sx={{ padding: "8px", margin: "8px", background: "#004aad" }}
                        variant="contained"
                        onClick={() => handleSubmit(record)}
                    >
                        {record.type}
                    </Button>
                ))}
            </Box>
            <Box sx={{ display: "flex", marginBottom: 2.5 }}>
                <Box sx={{ marginLeft: "30px" }}>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-bu disabled={index === 2}tton-menu" autoFocusItem>
                                            {options.map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            </Box>

            {recordType && (
                <Typography variant="body1" sx={{ mb: 2 }}>
                    <span style={{ fontSize: "20px" }}>
                        <span style={{ fontSize: "22px" }}>
                            <b>{recordType.type} Record Lookup</b>
                        </span>
                        <br />
                        {recordType.text}
                    </span>
                </Typography>
            )}
            {buttonLoading ? (
                <SkeletonTableBody />
            ) : (
                <Box sx={{ mt: 2 }}>
                    {result.length !== 0 && (
                        <Box>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell style={{ color: "black", textAlign: "left" }}>
                                                Dessert Record
                                            </StyledTableCell>
                                            <StyledTableCell style={{ color: "black", textAlign: "left" }}>
                                                Type
                                            </StyledTableCell>
                                            <StyledTableCell style={{ color: "black", textAlign: "left" }}>
                                                Domain
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {result &&
                                            result.map((data, i) => (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell style={{ textAlign: "left" }}>
                                                        {" "}
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                                overflowX: "auto",
                                                                overflowY: "auto",
                                                                whiteSpace: "pre-wrap",
                                                                wordBreak: "keep-all",
                                                            }}
                                                        >
                                                            {data.data}
                                                        </Typography>
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ textAlign: "left" }}>
                                                        {" "}
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                                whiteSpace: "pre-wrap",
                                                            }}
                                                        >
                                                            {data.record}
                                                        </Typography>
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ textAlign: "left" }}>
                                                        {" "}
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                            }}
                                                        >
                                                            {data.domain}
                                                        </Typography>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    )}
                    {mx.length !== 0 && (
                        <Box>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell style={{ color: "black", textAlign: "left" }}>
                                                {" "}
                                                Perf
                                            </StyledTableCell>
                                            <StyledTableCell style={{ color: "black", textAlign: "left" }}>
                                                Hostname
                                            </StyledTableCell>
                                            <StyledTableCell style={{ color: "black", textAlign: "left" }}>
                                                Type
                                            </StyledTableCell>
                                            <StyledTableCell style={{ color: "black", textAlign: "left" }}>
                                                Domain
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {mx &&
                                            mx.map((data, i) => (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell
                                                        component="th"
                                                        scope="row"
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        {data.data.priority}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ textAlign: "left" }}>
                                                        {data.data.exchange}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ textAlign: "left" }}>
                                                        {data.record}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ textAlign: "left" }}>
                                                        {data.domain}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    )}
                    {spf.length !== 0 && (
                        <Box>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell style={{ color: "black", textAlign: "left" }}>
                                                {" "}
                                                Record
                                            </StyledTableCell>
                                            <StyledTableCell style={{ color: "black", textAlign: "left" }}>
                                                Verified
                                            </StyledTableCell>
                                            <StyledTableCell style={{ color: "black", textAlign: "left" }}>
                                                Type
                                            </StyledTableCell>
                                            <StyledTableCell style={{ color: "black", textAlign: "left" }}>
                                                Domain
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {spf &&
                                            spf.map((data, i) => (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell
                                                        component="th"
                                                        scope="row"
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        {data.data}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ textAlign: "left" }}>
                                                        {<CheckBox sx={{ color: "green" }}></CheckBox>}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ textAlign: "left" }}>
                                                        {data.record}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ textAlign: "left" }}>
                                                        {data.domain}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    )}
                </Box>
            )}

            <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default DnsRecords;
