import React, { createContext, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import cookie from "cookiejs";

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
    const isLogin = cookie.get("token");
    const loggeduser = isLogin ? jwtDecode(isLogin) : {};
    const [user, setUser] = useState(loggeduser);
    const [isExpiredPlan, setIsExpiredPlan] = useState(false);
    const [PlanDetails, setPlanDetails] = useState(loggeduser.details);
    const [savedMembers, setSavedMembers] = useState([
        {
            email: "",
            role: "",
        },
    ]);
    const [workspacename, setworkspacename] = useState(
        loggeduser && loggeduser.email ? `${loggeduser.email.split("@")[0]}'s workspace` : ""
    );

    const handleuser = (value) => {
        setUser(() => value);
    }; // set logged in user
    const addMembers = (members) => {
        setSavedMembers(
            members.length === 0
                ? [
                      {
                          email: "",
                          role: "",
                      },
                  ]
                : members
        );
    };
    const addWorkspaceName = (name) => {
        // for getstarted page , new user
        setworkspacename(name);
    };
    useEffect(() => {
        console.log("I called");
        setPlanDetails(loggeduser.details);
        if (loggeduser.details?.subscriptionIsActive) {
            setIsExpiredPlan(!loggeduser?.details?.subscriptionIsActive);
        }
    }, []);
    const recheckPlanDetails = () => {
        const isLogin = cookie.get("token");
        const loggeduser = isLogin ? jwtDecode(isLogin) : {};
        setPlanDetails(loggeduser.details);
        if (loggeduser.details?.subscriptionIsActive) {
            setIsExpiredPlan(!loggeduser?.details?.subscriptionIsActive);
        }
    };
    const handleExpiredPlan = () => {
        setIsExpiredPlan(true);
    };
    console.log(PlanDetails, isExpiredPlan, "provider", user);
    const authContextValue = {
        isExpiredPlan,
        user,
        savedMembers,
        workspacename,
        handleuser,
        addMembers,
        addWorkspaceName,
        PlanDetails,
        handleExpiredPlan,
        recheckPlanDetails,
    };
    return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthContextProvider };
//owner -2 , member -3
