import * as React from "react";
import { useState, useEffect } from "react";
import { Box, Typography, Grid, List, ListItem, ListItemText, Paper, Snackbar } from "@mui/material";
import BarChart from "../../components/chart-component/chart";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Skeleton from "@mui/material/Skeleton";
import "./BccDetails.css";
import { useSearchParams } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";

const BccDetails = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [bccData, setBccData] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [searchParams] = useSearchParams();
    const fromDate = searchParams.get("fromDate");
    const toDate = searchParams.get("toDate");
    const domainName = searchParams.get("domainName");
    const [bccLoader, setBccLoader] = useState(false);
    const [expandedTemplateId, setExpandedTemplateId] = useState(null);
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedData, setSelectedData] = useState({
        labels: ["Inbox", "Spam"],
        datasets: [
            {
                label: "Counts",
                data: [0, 0],
                backgroundColor: ["rgba(75,192,192,0.6)", "rgba(153,102,255,0.6)"],
            },
        ],
    });

    const fetchMailAuditData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/getMailAuditData?fromDate=${fromDate}&toDate=${toDate}&domainName=${domainName}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "GET",
                    credentials: "include",
                }
            );
            const json = await response.json();
            setBccData(json);
            console.log("json", json);

            if (json.length > 0) {
                handleCardClick(json[0]);
            }
        } catch (error) {
            console.error(`Error occured while fetching mail audit --> ${error}`);
            throw error;
        }
    };

    const fetchTemplateData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/getTemplateData?fromDate=${fromDate}&toDate=${toDate}&domainName=${domainName}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "GET",
                    credentials: "include",
                }
            );
            const templatesData = await response.json();

            const processedTemplates = templatesData.data.map((template, index) => ({
                id: index + 1,
                name: `Template ${index + 1}`,
                subject: template.content.subject,
                body: template.content.body,
                originalContent: template.content.body,
            }));

            console.log(processedTemplates, "<<<<<<<<<<>>>>>>>>>>");
            setTemplates(processedTemplates);

            if (processedTemplates.length > 0) {
                setSelectedTemplate(processedTemplates[0]);
            }
        } catch (error) {
            console.error(`Error occurred while fetching template data --> ${error}`);
            throw error;
        }
    };

    useEffect(() => {
        setBccLoader(true);
        Promise.allSettled([fetchMailAuditData(), fetchTemplateData()]).then(() => {
            setBccLoader(false);
        });
    }, []);

    const circleStyle = (color) => ({
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        backgroundColor: color,
        margin: "0 5px",
    });

    const handleTemplateSelect = (template) => {
        console.log(template, "template???????");
        setSelectedTemplate(template);
    };

    var labels;
    var inboxData;
    var spamData;

    const handleCardClick = (data) => {
        setSelectedCard(data.bcc_email);

        labels = data.graphData.map((item) => item.date);
        inboxData = data.graphData.map((item) => item.inbox_count);
        spamData = data.graphData.map((item) => item.spam_count);

        setSelectedData({
            labels: labels,
            datasets: [
                {
                    label: "Inbox count",
                    data: inboxData, // Use the inbox counts from the graphData
                    backgroundColor: "rgba(75,192,192,0.6)",
                },
                {
                    label: "Spam count",
                    data: spamData, // Use the spam counts from the graphData
                    backgroundColor: "rgba(153,102,255,0.6)",
                },
            ],
        });
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                //  text: "Bar Chart Example",
            },
        },
    };
    const copyToClipboard = () => {
        const subjectText = selectedTemplate.subject;

        navigator.clipboard
            .writeText(subjectText)
            .then(() => {
                setSnackbarMessage("Subject text copied to clipboard");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((err) => {
                setSnackbarMessage("Error copying subject text");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
    };
    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const bodyToClipboard = () => {
        const bodyText = selectedTemplate.originalContent;
        navigator.clipboard
            .writeText(bodyText)
            .then(() => {
                setSnackbarMessage("Body text copied to clipboard");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                
            })
            .catch((err) => {
                setSnackbarMessage("Error copying body text");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
    };

    return (
        <Box>
            <>
                {bccLoader ? (
                    <Skeleton
                        variant="text"
                        sx={{
                            fontSize: "1rem",
                            margin: "16px 0",
                            padding: "10px 10px",
                            borderRadius: "10px",
                        }}
                    />
                ) : (
                    <Box className="main_breadcrumbs">
                        <Breadcrumbs aria-label="breadcrumb" separator="››" className="bread-crumbs">
                            <Link
                                underline="hover"
                                color="inherit"
                                href="/dashboard/spreadsheet"
                                sx={{
                                    color: "primary.main",
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline",
                                    },
                                }}
                            >
                                BCC Insights
                            </Link>
                            <Typography
                                color="text.primary"
                                sx={{
                                    color: "text.primary",
                                }}
                            >
                                BCC Details
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                )}
            </>
            <Box className="main-grid">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    overflow: "auto",
                                    height: "322px",
                                    border: "1px solid #f1f1f1",
                                    borderRadius: "5px",
                                    gap: 5,
                                    padding: "10px",
                                }}
                            >
                                {bccLoader
                                    ? // Render Skeleton components while loading
                                      Array.from(new Array(1)).map((_, index) => (
                                          <Skeleton
                                              key={index}
                                              variant="rectangular"
                                              width={1000}
                                              height={270}
                                              sx={{
                                                  margin: "10px",
                                                  borderRadius: "5px",
                                              }}
                                          />
                                      ))
                                    : // Render your data when not loading
                                      bccData.map((data) => {
                                          return (
                                              <Box
                                                  key={data.bcc_email}
                                                  sx={{
                                                      width: bccData.length === 1 ? "100%" : "250px",
                                                      textAlign: "center",
                                                      cursor: "pointer",
                                                      borderRadius: "5px",
                                                      minHeight: "110px",
                                                      height: "auto",
                                                      alignContent: "center",
                                                      border:
                                                          selectedCard === data.bcc_email
                                                              ? "1px solid blue"
                                                              : "1px solid #f1f1f1",
                                                      backgroundColor:
                                                          selectedCard === data.bcc_email ? "#f0f8ff" : "white",
                                                      marginLeft: "10px",
                                                  }}
                                                  onClick={() => handleCardClick(data)}
                                              >
                                                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                                                      {data.bcc_email}
                                                  </Typography>
                                                  <Typography className="typo-inbox">
                                                      Total Inbox: {data.total_inbox}
                                                  </Typography>
                                                  <Typography className="typo-spam">
                                                      Total Spam: {data.total_spam}
                                                  </Typography>
                                              </Box>
                                          );
                                      })}
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className="box-chart">
                            {bccLoader ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={270}
                                    sx={{
                                        margin: "16px 0",
                                        padding: "10px",
                                        borderRadius: "10px",
                                    }}
                                />
                            ) : (
                                <BarChart data={selectedData} options={options} />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Paper
                            sx={{
                                height: "480px",
                                padding: "20px",
                                border: "1px solid #f1f1f1",
                                borderRadius: "5px",
                                backgroundColor: "#fff",
                                overflowY: templates.length > 10 ? "auto" : "hidden", // Scrollable if more than 10 items
                                boxShadow: "none !important",
                            }}
                        >
                            <Typography className="Templates-text">TEMPLATES</Typography>
                            {bccLoader ? (
                                <List>
                                    {Array.from(new Array(5)).map((_, index) => (
                                        <Skeleton
                                            key={index}
                                            variant="rectangular"
                                            height={48}
                                            sx={{ marginBottom: "10px", borderRadius: "5px" }}
                                        />
                                    ))}
                                </List>
                            ) : (
                                <List>
                                    {templates.map((template) => (
                                        <div key={template.id}>
                                            <ListItem
                                                className="template-card"
                                                onClick={() => handleTemplateSelect(template)}
                                                sx={{
                                                    backgroundColor:
                                                        selectedTemplate.id === template.id ? "#e0f2f1" : "white", // Highlight selected card
                                                    "&:hover": {
                                                        backgroundColor: "#e0f2f1", // Hover effect
                                                    },
                                                }}
                                            >
                                                <ListItemText sx={{ display: "flex", justifyContent: "center" }}>
                                                    <Typography>
                                                        {template.subject.slice(0, 20).concat("....")}
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        </div>
                                    ))}
                                </List>
                            )}
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <Paper
                            sx={{
                                height: "480px",
                                border: "1px solid #f1f1f1",
                                borderRadius: "5px",
                                backgroundColor: "#E9EAEC",
                                overflowY: "auto",
                                boxShadow: "none !important",
                            }}
                        >
                            <Box sx={{ display: "flex", backgroundColor: "#004aad", padding: "15px" }}>
                                <div style={circleStyle("#4CBB17")}></div>
                                <div style={circleStyle("#F6FB7A")}></div>
                                <div style={circleStyle("#B4E380")}></div>
                            </Box>

                            <Box>
                                <Box sx={{ padding: "8px" }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            marginBottom: "20px",
                                            backgroundColor: "white",
                                            alignItems: "center",
                                            height: "40px",
                                            gap: 2,
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontWeight: "bold",
                                                color: "#24a0ed",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            Subject:
                                        </Typography>
                                        {bccLoader ? (
                                            <Skeleton
                                                variant="text"
                                                sx={{
                                                    fontSize: "1rem",
                                                    margin: "16px 0",
                                                    padding: "10px 10px",
                                                    borderRadius: "10px",
                                                    width: "80%",
                                                }}
                                            />
                                        ) : (
                                            selectedTemplate && (
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        margin: "16px 0",
                                                        padding: "10px 10px",
                                                        borderRadius: "10px",
                                                        width: "80%",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {selectedTemplate.subject}
                                                </Typography>
                                            )
                                        )}
                                        <ContentCopyIcon
                                            sx={{ marginLeft: "auto", cursor: "pointer" }}
                                            onClick={copyToClipboard}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ padding: "10px", marginTop: "-25px" }}>
                                    <Box
                                        sx={{
                                            borderRadius: "5px",
                                            padding: "10px",
                                            backgroundColor: "white",
                                            alignItems: "center",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {bccLoader ? (
                                            <Skeleton
                                                variant="rectangular"
                                                sx={{
                                                    height: "300px",
                                                    width: "100%",
                                                    borderRadius: "10px",
                                                }}
                                            />
                                        ) : (
                                            <div
                                                style={{ height: "300px", overflowY: "auto" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: selectedTemplate?.originalContent,
                                                }}
                                            ></div>
                                        )}
                                        <ContentCopyIcon
                                            sx={{ marginLeft: "97%", cursor: "pointer" }}
                                            onClick={bodyToClipboard}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    );
};
export default BccDetails;
