import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import DoneIcon from "@mui/icons-material/Done";
import { styled } from "@mui/material/styles";
import {
    Box,
    Grid,
    Card,
    Button,
    TableContainer,
    TableBody,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Switch,
    Typography,
    Divider,
    Stack,
    CircularProgress,
    Snackbar,
    Alert,
    CardContent,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import cookie from "cookiejs";
import { tableCellClasses } from "@mui/material/TableCell";
import jwt from "jwt-decode";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DraftsIcon from "@mui/icons-material/Drafts";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#FAFAFA",
        fontSize: "12px",
        fontWeight: "700",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const Subscriptions = () => {
    const user = cookie.get("token") && jwt(cookie.get("token"));
    const navigate = useNavigate();

    // if (user.admin) {
    //     navigate("/login")
    // }

    const [paymentLoading, setPaymentLoading] = useState(false);
    const [InvoicesLoading, setInvoicesLoading] = useState(false);
    const [priceValidation, setPriceValidation] = useState("/month");
    const [toggle, setToggle] = useState(false);
    const [SubscriptionsTime, setSubscriptionsTime] = useState("monthly");
    const [homeLoading, setHomeLoading] = useState(false);
    const [data, setData] = useState([]);
    const [checkInvoices, setCheckInvoives] = useState(false);
    const [checkPlans, setCheckPlans] = useState(true);
    const [allInvoices, setAllInvoices] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [alignment, setAlignment] = React.useState("web");
    const [existData, setExistData] = useState([]);
    const [checked, setChecked] = useState(false);
    const [page, setPage] = useState(2);
    const [lastpage, setLastpage] = useState();
    const [currentPage, setCurrentPage] = useState();
    const [hasMore, sethasMore] = useState(true);
    const [DataLoading, setDataLoading] = useState(false);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };
    async function infinteScroll(e) {
        console.log(document.documentElement.scrollHeight);
    }

    window.addEventListener("scroll", infinteScroll);

    React.useEffect(() => {
        setHomeLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/planDetails`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((response) => {
                if (response.status === 200) {
                    setHomeLoading(false);
                    return response.json();
                }
            })
            .then((d) => {
                console.log(d.data);
                setExistData(d.data);
                if (d.data[0].reoccurring === true) {
                    setChecked(true);
                }
                console.log(d);
            });
    }, []);

    React.useEffect(() => {
        setHomeLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/getprice`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((response) => {
                if (response.status === 200) {
                    setHomeLoading(false);
                    setAlignment("plans");
                    return response.json();
                } else if (response.status === 202) {
                    setHomeLoading(false);
                    console.log("token expired");
                } else {
                    setHomeLoading(false);
                    console.log("some error");
                    return response.json();
                }
            })
            .then((data) => {
                if (data.status === 401) {
                    setErrorMessage(data.msg);
                    setErrorSnackbarOpen(true);
                    return;
                }
                console.warn("this is geting ", data);
                setData(data.data);
            })
            .catch((error) => console.log(error));
    }, []);

    const priceFunction = (e) => {
        console.warn(e);
        setPaymentLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/payment`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify(e),
        })
            .then((response) => {
                if (response.status === 200) {
                    setPaymentLoading(false);
                    console.log("Success");
                    return response.json();
                } else if (response.status === 202) {
                    console.log("token expired");
                } else {
                    console.log("some error");
                }
            })
            .then((data) => {
                setPaymentLoading(false);
                window.location.replace(data.data);
                console.warn("data collected", data.data);
            })
            .catch((error) => console.log(error));
    };

    function invoices() {
        setCheckInvoives(true);
        setCheckPlans(false);
        setInvoicesLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/invoices/?page=1`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "GET",
            headers: { email: user.email },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log(data);
                if (data.status === 401) {
                    setErrorMessage(data.msg);
                    setErrorSnackbarOpen(true);
                    setInvoicesLoading(false);
                    return;
                }
                setInvoicesLoading(false);
                setAllInvoices(data.invoices);
                setLastpage(data.last_page);
            })
            .catch((error) => console.log(error));
    }

    function Invoices() {
        setDataLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/invoices/?page=${page}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "GET",
            headers: { email: user.email },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setDataLoading(false);
                console.log(data);
                let temp = allInvoices.concat(data.invoices);
                setAllInvoices(temp);
                setCurrentPage(data.current_page);
            })
            .catch((error) => console.log(error));
    }

    function load() {
        if (currentPage === lastpage) {
            sethasMore(false);
            return;
        } else {
            setPage(page + 1);
            Invoices();
        }
    }

    function plans() {
        setCheckInvoives(false);
        setCheckPlans(true);
    }

    function redirect(data) {
        if (data === null) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Status is not paid");
        }
    }

    const HandleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
        if (newAlignment === "plans") {
            plans();
        } else {
            invoices();
        }
    };

    function Date(data) {
        let a = data;
        let b = `${a[8] + a[9]}-${a[5] + a[6]}-${a[0] + a[1] + a[2] + a[3]}`;

        return b;
    }
    const handleChange = (e) => {
        console.log(e.target.checked);
        setChecked(e.target.checked);
        fetch(`${REACT_APP_SERVER_URL}/reoccuring`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                reoccur: e.target.checked,
            }),
        })
            .then((resp) => {
                return resp.json();
            })
            .then((d) => {
                if (d.status === 200) {
                    setSuccessSnackbarOpen(true);
                    if (e.target.checked === true) {
                        setSuccessMessage("Auto payment turned on");
                    } else {
                        setSuccessMessage("Auto payment turned off");
                    }
                } else {
                    console.log("Not Okay");
                }
            })
            .catch((error) => console.log(error));
    };

    function Date(data) {
        let a = data;
        let b = `${a[8] + a[9]}-${a[5] + a[6]}-${a[0] + a[1] + a[2] + a[3]}`;

        return b;
    }

    return (
        <>
            {
                <Box>
                    <Box sx={{ textAlign: "center", marginTop: "-20px" }}>
                        <p style={{ fontSize: "30px", fontWeight: "1200px", lineHeight: "38px" }}>Our Plans</p>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={HandleChange}
                            aria-label="Platform"
                        >
                            <ToggleButton value="plans">Plans</ToggleButton>
                            <ToggleButton value="invoices">Invoices</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                    {checkPlans && (
                        <Box sx={{ marginTop: "20px" }}>
                            <Box>
                                {existData.length !== 0 && (
                                    <Box sx={{ my: 2 }}>
                                        <Typography
                                            sx={{
                                                fontSize: "30px",
                                                fontWeight: "400",
                                                textAlign: "center",
                                                background: "#481BEB",
                                                color: "white",
                                                my: 2,
                                                borderRadius: "10px",
                                                boxShadow:
                                                    "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                                            }}
                                        >
                                            Current Plan
                                        </Typography>
                                        <TableContainer sx={{ borderRadius: "10px", border: "1px solid #E7E7E7" }}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="center">USER EMAIL</StyledTableCell>
                                                        <StyledTableCell align="center">MAX EMAIL</StyledTableCell>
                                                        <StyledTableCell align="center">MAX LEAD</StyledTableCell>
                                                        <StyledTableCell align="center">END DATE</StyledTableCell>
                                                        <StyledTableCell align="center">Reoccuring</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {existData &&
                                                        existData.map((data, i) => (
                                                            <StyledTableRow>
                                                                <StyledTableCell
                                                                    align="center"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                >
                                                                    {data.email}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    align="center"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                >
                                                                    {data.maxEmail}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    align="center"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                >
                                                                    {data.maxLead}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    align="center"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                >
                                                                    {Date(data.endDate.slice(0, 10))}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    align="center"
                                                                    sx={{ fontSize: "14px", fontWeight: "700" }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            fontSize: "16px",
                                                                            fontWeight: 500,
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            my: 1,
                                                                        }}
                                                                    >
                                                                        <Switch
                                                                            checked={checked}
                                                                            onChange={handleChange}
                                                                            inputProps={{ "aria-label": "controlled" }}
                                                                            color="success"
                                                                            sx={{ float: "left" }}
                                                                        />
                                                                    </Box>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )}
                                {existData.length === 0 && (
                                    <Typography
                                        sx={{
                                            fontSize: "30px",
                                            fontWeight: "400",
                                            textAlign: "center",
                                            background: "blue",
                                            color: "white",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        No existing plans
                                    </Typography>
                                )}
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: "30px",
                                    fontWeight: "400",
                                    textAlign: "center",
                                    background: "#481BEB",
                                    color: "white",
                                    marginTop: "10vh",
                                    borderRadius: "10px",
                                    boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                                }}
                            >
                                Our Plans
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    my: 4,
                                }}
                            >
                                <Box
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: 500,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        my: 1,
                                    }}
                                >
                                    <Switch
                                        onClick={() => {
                                            if (!toggle) {
                                                setSubscriptionsTime("yearly");
                                                setPriceValidation("/year");
                                            } else {
                                                setSubscriptionsTime("monthly");
                                                setPriceValidation("/month");
                                            }
                                            setToggle(!toggle);
                                        }}
                                        color="success"
                                        sx={{ float: "left" }}
                                    />
                                    <Typography sx={{ color: "#7D7D7D", float: "left", mr: 1 }}>
                                        Annual Pricing
                                    </Typography>
                                    <Typography sx={{ color: "#38C68B", float: "left" }}>(save 20%)</Typography>
                                </Box>
                            </Box>
                            {homeLoading ? (
                                <>
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <CircularProgress />
                                    </Box>
                                </>
                            ) : (
                                <Box>
                                    {paymentLoading ? (
                                        <Box sx={{ width: "100%", display: "center", justifyContent: "center" }}>
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                                <Card
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "start",
                                                        flexDirection: "column",
                                                        p: 4,
                                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 2px",
                                                    }}
                                                >
                                                    <Box sx={{ textAlign: "center" }}>
                                                        <Typography
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                fontSize: "10px",
                                                                fontWeight: "700",
                                                                width: "40px",
                                                                height: "40px",
                                                                backgroundColor: "rgba(242, 219, 5,0.2)",
                                                                border: "1px solid blue ",
                                                                borderRadius: "50%",
                                                                color: "rgb(227, 208, 14)",
                                                                marginLeft: "42%",
                                                            }}
                                                        >
                                                            <RocketLaunchIcon style={{ color: "blue" }} />
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                color: "#7953FF",
                                                                fontSize: "18px",
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            Startup
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "45px", fontWeight: "700" }}>
                                                            Free
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: "400",
                                                                color: "rgba(26, 26, 26, 0.7)",
                                                            }}
                                                        >
                                                            For personal use only
                                                        </Typography>
                                                    </Box>
                                                    <Divider sx={{ my: 2 }} />
                                                    <Stack spacing={1}>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} />{" "}
                                                            Unlimited editors
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} />{" "}
                                                            Onboarding support
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} /> Custom
                                                            branding
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} />{" "}
                                                            Unlimited projects
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} /> Up to 10
                                                            integrations
                                                        </Typography>
                                                    </Stack>
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            visibility: "hidden",
                                                            backgroundColor: "#481BEB",
                                                            textTransform: "none",
                                                            borderRadius: "6px",
                                                            my: 2,
                                                            width: "70%",
                                                        }}
                                                    >
                                                        Get Started
                                                    </Button>
                                                </Card>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                                <Card
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "start",
                                                        flexDirection: "column",
                                                        p: 4,
                                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 2px",
                                                    }}
                                                >
                                                    <Box sx={{ textAlign: "center" }}>
                                                        <Typography
                                                            sx={{
                                                                display: "flex",
                                                                textAlign: "center",
                                                                alignContent: "center",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                fontSize: "10px",
                                                                fontWeight: "700",
                                                                width: "40px",
                                                                height: "40px",
                                                                backgroundColor: "rgba(242, 0, 0,0.2)",
                                                                border: "1px solid blue",
                                                                borderRadius: "50%",
                                                                color: "red",
                                                                marginLeft: "40%",
                                                            }}
                                                        >
                                                            <FavoriteIcon sx={{ color: "blue" }} />
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                textAlign: "center",
                                                                color: "#7953FF",
                                                                fontSize: "18px",
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            {data[SubscriptionsTime] &&
                                                                data[SubscriptionsTime][0]["name"]}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                textAlign: "center",
                                                                fontSize: "45px",
                                                                fontWeight: "700",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                marginLeft: "27%",
                                                            }}
                                                        >
                                                            $
                                                            {data[SubscriptionsTime] &&
                                                                data[SubscriptionsTime][0]["unit_amount"]}
                                                            <Typography>{priceValidation}</Typography>
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: "400",
                                                                color: "rgba(26, 26, 26, 0.7)",
                                                            }}
                                                        >
                                                            For personal use only
                                                        </Typography>
                                                    </Box>
                                                    <Divider sx={{ my: 2 }} />
                                                    <Stack spacing={1}>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} />{" "}
                                                            Unlimited editors
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} />{" "}
                                                            Onboarding support
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} /> Custom
                                                            branding
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} />{" "}
                                                            Unlimited projects
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} /> Up to 10
                                                            integrations
                                                        </Typography>
                                                    </Stack>
                                                    <Button
                                                        onClick={() => priceFunction(data[SubscriptionsTime][0])}
                                                        variant="contained"
                                                        sx={{
                                                            backgroundColor: "#481BEB",
                                                            textTransform: "none",
                                                            borderRadius: "6px",
                                                            my: 2,
                                                            width: "70%",
                                                        }}
                                                    >
                                                        Get Started
                                                    </Button>
                                                </Card>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                                <Card
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "start",
                                                        flexDirection: "column",
                                                        p: 4,
                                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 2px",
                                                    }}
                                                >
                                                    <Box sx={{ textAlign: "center" }}>
                                                        <Typography
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                fontSize: "10px",
                                                                fontWeight: "700",
                                                                width: "40px",
                                                                height: "40px",
                                                                backgroundColor: "rgba(0, 0, 225,0.2)",
                                                                border: "1px solid blue",
                                                                borderRadius: "50%",
                                                                color: "blue",
                                                                marginLeft: "40%",
                                                            }}
                                                        >
                                                            <RocketLaunchIcon sx={{ color: "blue" }} />
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                color: "#7953FF",
                                                                fontSize: "18px",
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            {data[SubscriptionsTime] &&
                                                                data[SubscriptionsTime][1]["name"]}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "45px",
                                                                fontWeight: "700",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                marginLeft: "28%",
                                                            }}
                                                        >
                                                            $
                                                            {data[SubscriptionsTime] &&
                                                                data[SubscriptionsTime][1]["unit_amount"]}
                                                            <Typography>{priceValidation}</Typography>
                                                        </Typography>

                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: "400",
                                                                color: "rgba(26, 26, 26, 0.7)",
                                                            }}
                                                        >
                                                            For personal use only
                                                        </Typography>
                                                    </Box>
                                                    <Divider sx={{ my: 2 }} />
                                                    <Stack spacing={1}>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} />{" "}
                                                            Unlimited editors
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} />{" "}
                                                            Onboarding support
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} /> Custom
                                                            branding
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} />{" "}
                                                            Unlimited projects
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                                                            <DoneIcon sx={{ color: "blue", marginRight: 1 }} /> Up to 10
                                                            integrations
                                                        </Typography>
                                                    </Stack>
                                                    <Button
                                                        onClick={() => priceFunction(data[SubscriptionsTime][1])}
                                                        variant="contained"
                                                        sx={{
                                                            backgroundColor: "#481BEB",
                                                            textTransform: "none",
                                                            borderRadius: "6px",
                                                            my: 2,
                                                            width: "70%",
                                                        }}
                                                    >
                                                        Get Started
                                                    </Button>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Box>
                            )}
                        </Box>
                    )}
                    {checkInvoices && (
                        <Box sx={{ marginTop: "10px" }}>
                            {InvoicesLoading ? (
                                <Box sx={{ width: "100%", display: "center", justifyContent: "center" }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <InfiniteScroll
                                    id="scroll"
                                    dataLength={allInvoices.length}
                                    next={load}
                                    hasMore={hasMore}
                                    height={520}
                                >
                                    {allInvoices.length !== 0 && (
                                        <Box>
                                            <CardContent>
                                                <Grid
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        fontWeight: 600,
                                                        color: "white",
                                                        fontSize: "18px",
                                                        background: "#525CEB",
                                                        borderRadius: "10px",
                                                        padding: "1%",
                                                        marginTop: "-5px",
                                                        overflow: "hidden",
                                                    }}
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                >
                                                    <Grid sm={4}>
                                                        <label>ID</label>
                                                    </Grid>
                                                    <Grid sm={2}>
                                                        <label>STATUS</label>
                                                    </Grid>
                                                    <Grid sm={2}>
                                                        <label>START DATE</label>
                                                    </Grid>
                                                    <Grid sm={2}>
                                                        <label>END DATE</label>
                                                    </Grid>
                                                    <Grid sm={2}>
                                                        <label>INVOICE</label>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                            {allInvoices &&
                                                allInvoices.map((data, i) => (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            gap: 4,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: "97.2%",
                                                                height: "8vh",
                                                                border: "0.1rem solid #EEE7DA",
                                                                marginBottom: "1%",
                                                                padding: "1.5%",
                                                                borderRadius: "10px",
                                                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                                cursor:
                                                                    data.status === "Verified" ? "pointer" : "default",
                                                                overflow: "hidden",
                                                                backgroundColor:
                                                                    data.status === "Verified"
                                                                        ? "#f5f5f5"
                                                                        : "transparent",
                                                                alignItems: "center",
                                                                marginTop: "-8px",
                                                            }}
                                                        >
                                                            <CardContent>
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                    alignItems="center"
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        marginTop: "-20px",
                                                                    }}
                                                                >
                                                                    <Grid sm={4}> {data.id}</Grid>
                                                                    <Grid sm={2.1}>
                                                                        {" "}
                                                                        {data.status === "paid" ? (
                                                                            <CheckCircleIcon
                                                                                sx={{ color: "green" }}
                                                                            ></CheckCircleIcon>
                                                                        ) : data.status === "draft" || "open" ? (
                                                                            <DraftsIcon></DraftsIcon>
                                                                        ) : (
                                                                            <CancelIcon
                                                                                sx={{
                                                                                    color: "red",
                                                                                }}
                                                                            ></CancelIcon>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid sm={1.9}>
                                                                        {Date(data.periodStart.slice(0, 10))}
                                                                    </Grid>
                                                                    <Grid sm={2.1}>
                                                                        {Date(data.periodEnd.slice(0, 10))}
                                                                    </Grid>
                                                                    <Grid sm={1.9}>
                                                                        <Button
                                                                            href={data.url}
                                                                            target="blank"
                                                                            onClick={(e) => {
                                                                                redirect(data.url);
                                                                            }}
                                                                        >
                                                                            <DownloadForOfflineIcon></DownloadForOfflineIcon>
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </div>
                                                    </div>
                                                ))}
                                        </Box>
                                    )}
                                    {allInvoices.length === 0 && (
                                        <Typography
                                            sx={{
                                                textAlign: "center",
                                                fontSize: "25px",
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            No invoices present
                                        </Typography>
                                    )}
                                    {DataLoading && (
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "center",
                                                justifyContent: "center",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Loading...
                                        </Box>
                                    )}
                                    {currentPage === lastpage && (
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "center",
                                                justifyContent: "center",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            All content loaded
                                        </Box>
                                    )}
                                </InfiniteScroll>
                            )}
                        </Box>
                    )}
                </Box>
            }
            <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default Subscriptions;
