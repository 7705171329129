import React, { useState } from "react";
import { Container, Typography, Box } from "@mui/material";

const ActionComponent = ({ onSelect }) => {
    const [selected, setSelected] = useState("Overview");

    const handleSelect = (option) => {
        setSelected(option);
        onSelect(option);
    };

    const getStyle = (option) => ({
        backgroundColor: selected === option ? "#ADD8E6" : "#fff",
        borderRadius: "5px",
        marginBottom: "10px",
        cursor: "pointer",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    });

    return (
        <Container>
            <Box style={getStyle("Overview")} onClick={() => handleSelect("Overview")}>
                <Typography sx={{ padding: "10px" }}>Overview</Typography>
            </Box>
            <Box style={getStyle("DNS Records")} onClick={() => handleSelect("DNS Records")}>
                <Typography sx={{ padding: "10px" }}>DNS Records</Typography>
            </Box>
            <Box style={getStyle("BlackList")} onClick={() => handleSelect("BlackList")}>
                <Typography sx={{ padding: "10px" }}>BlackList</Typography>
            </Box>
            <Box style={getStyle("TemplateList")} onClick={() => handleSelect("TemplateList")}>
                <Typography sx={{ padding: "10px" }}>Template</Typography>
            </Box>
        </Container>
    );
};

export default ActionComponent;
