import React, { useState } from "react";
import { Typography } from "@mui/material";

const ActionComponent = ({ onSelect }) => {
    const [selected, setSelected] = useState("Overview");

    const handleSelect = (option) => {
        setSelected(option);
        onSelect(option);
    };

    const getStyle = (option) => ({
        backgroundColor: selected === option ? "#ADD8E6" : "#fff",
        borderRadius: "5px",
        marginBottom: "10px",
        cursor: "pointer",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    });

    return (
        <div>
            <div style={getStyle("Overview")} onClick={() => handleSelect("Overview")}>
                <Typography sx={{ padding: "10px" }}>Overview</Typography>
            </div>
            <div style={getStyle("DNS Reputation")} onClick={() => handleSelect("DNS Reputation")}>
                <Typography sx={{ padding: "10px" }}>DNS Records</Typography>
            </div>
            <div style={getStyle("Blacklist")} onClick={() => handleSelect("Blacklist")}>
                <Typography sx={{ padding: "10px" }}>Blacklist</Typography>
            </div>
            <div style={getStyle("TemplateList")} onClick={() => handleSelect("TemplateList")}>
                <Typography sx={{ padding: "10px" }}>TemplateList</Typography>
            </div>
        </div>
    );
};

export default ActionComponent;
