import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Box, Container, Typography, Button, TextField, CircularProgress } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SnackBar from "../common/SnackBar";
import logo from "../../img/icons/xaudit1.png";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const mdTheme = createTheme({
    typography: {
        fontFamily: ["League Spartan", "sans-serif"].join(","),
    },
});
const ForgotPassword = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const navigate = useNavigate();
    const { search, state } = useLocation();
    const queryParams = new URLSearchParams(search);
    const initialEmail = queryParams.get("email");
    const [email, setEmail] = React.useState(initialEmail ? initialEmail : "");
    const [errorMessage, setErrorMessage] = React.useState(state === null ? "" : state.msg);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(state === null ? false : state.error);
    const [forgetLoading, setForgetLoading] = React.useState(false);
    const [isValidEmail, setIsValidEmail] = useState(emailRegex.test(email));

    const submitHandler = (e) => {
        e.preventDefault();
        setForgetLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/resendemailtoken`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                email: email,
                type: "forgotpassword",
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setForgetLoading(false);
                if (data.success) {
                    setForgetLoading(false);
                    navigate("/reset-verification-mail-sent");
                } else {
                    setErrorMessage(data.msg || data.message);
                    setErrorSnackbarOpen(true);
                }
            });
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        setIsValidEmail(emailRegex.test(value));
    };
    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />

            <Box
                sx={{
                    width: "100vw",
                    mt: 10,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    component="img"
                    src={logo} // Replace with your image link
                    alt="Placeholder"
                    sx={{ marginBottom: 2, width: "100%", maxWidth: "300px", height: "auto" }}
                />
                <Container
                    maxWidth="sm"
                    sx={{
                        backgroundColor: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        boxShadow: "0px 32px 100px rgba(50, 47, 85, 0.1)",
                        border: "1px solid black",
                    }}
                >
                    <Box sx={{ padding: "40px" }}>
                        <Typography sx={{ fontSize: "24px", fontWeight: "700", textAlign: "center" }}>
                            Reset your Password
                        </Typography>
                        <Typography sx={{ fontSize: "14px", pb: 1, textAlign: "center" }}>
                            Please enter the email address you'd like your password reset link sent to.
                        </Typography>
                        <TextField
                            required
                            placeholder="Email"
                            type="email"
                            name="email"
                            value={email}
                            fullWidth
                            onChange={handleEmailChange}
                            sx={{ pb: 1 }}
                        />
                        {!isValidEmail && email && (
                            <Typography sx={{ fontSize: "14px", pb: 1, color: "red" }}>
                                Enter valid email address
                            </Typography>
                        )}
                        {
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                onClick={submitHandler}
                                sx={{
                                    mt: 1,
                                    borderRadius: "6px",
                                    textTransform: "none",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    backgroundColor: "#481BEB",
                                }}
                                disabled={forgetLoading || !email || !isValidEmail}
                            >
                                {forgetLoading && <CircularProgress size={25} />}
                                {!forgetLoading && "Reset Your Password"}
                            </Button>
                        }
                        <Typography sx={{ pt: 2 }}>
                            <Typography sx={{ mr: 0.5, float: "left" }}>Go Back to</Typography>
                            <Link exact to="/login" style={{ color: "#3366CC" }}>
                                xemailaudit.com/Login
                            </Link>
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
        </ThemeProvider>
    );
};

export default ForgotPassword;
