import React from "react";
import PropTypes from "prop-types";

const HtmlPreview = ({ htmlContent }) => {
    return (
        <div
            style={{
                border: "1px solid #ddd",
                padding: "10px",
                borderRadius: "4px",
                overflowY: "auto",
                maxHeight: "300px",
                marginTop: "10px",
            }}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
    );
};

HtmlPreview.propTypes = {
    htmlContent: PropTypes.string.isRequired,
};

export default HtmlPreview;
