import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    Button,
    Link,
    Typography,
    Box,
} from "@mui/material";
const withSenderDialog =
    (TableComponent) =>
    ({ data, headers, senderColumnName, ...props }) => {
        const [open, setOpen] = useState(false);
        const [senders, setSenders] = useState([]);

        const handleOpen = (senderList) => {
            setSenders(senderList);
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const renderCell = (key, value) => {
            if (key === senderColumnName) {
                if (Array.isArray(value)) {
                    const senderNames = value.map((sender) => sender.name).join(", ");
                    return (
                        <Link component="button" variant="body2" onClick={() => handleOpen(value)}>
                            View
                        </Link>
                    );
                }
                return (
                    <Link component="button" variant="body2" onClick={() => handleOpen([value])}>
                        View
                    </Link>
                );
            }
            return value && typeof value === "object" ? JSON.stringify(value) : value;
        };

        return (
            <>
                <TableComponent data={data} headers={headers} renderCell={renderCell} {...props} />
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        sx: {
                            width: 450,
                            height: "auto",
                            overflow: "hidden",
                        },
                    }}
                >
                    <DialogTitle>
                        <Typography sx={{ textAlign: "center", fontSize: "20px", marginTop: "10px", fontWeight: 600 }}>
                            {" "}
                            Senders
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <List>
                                {senders.map((sender, index) => (
                                    <ListItem key={index} className="sender-listItem">
                                        <ListItemText primary={sender.name} />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </DialogContent>
                    <Box display="flex" justifyContent="center" sx={{ mt: 2,padding:'10px' }}>
                        <Button onClick={handleClose} color="primary" variant="outlined">
                            Close
                        </Button>
                    </Box>
                </Dialog>
            </>
        );
    };

export default withSenderDialog;
