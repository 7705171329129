export default function SetupFetchInterceptor(handleExpiredPlan) {
    const originalFetch = window.fetch;
    window.fetch = async (url, options) => {
        try {
            const response = await originalFetch(url, options);
            console.log(response, "interceptor");
            if (response.status === 403) {
                console.log("Here status 200");
                handleExpiredPlan();
            }

            return response;
        } catch (error) {
            return Promise.reject("An error occurred while making the request");
        }
    };
}
