import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
export default function CustomCard({
    img,
    imgTitle,
    title,
    content,
    actions,
    handleClick,
    badgeText,
    style,
    isLoading,
    disabled,
}) {
    return (
        <Card
            onClick={handleClick}
            sx={{
                opacity: disabled ? 0.5 : 1,
                pointerEvents: disabled ? "none" : "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                textAlign: "center",
                transition: "background-color 0.2s, transform 0.2s",
                backgroundColor: "white",
                minHeight: "300px",
                "&:hover": {
                    cursor: "pointer",
                    transform: "scale(1.05)",
                },
            }}
        >
            {/* Badge */}
            {badgeText && (
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        backgroundColor: "green", // Customize badge color here
                        color: "white",
                        padding: "2px 8px",
                        fontSize: "14px",
                    }}
                >
                    {badgeText}
                </Box>
            )}

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                {img && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "10px",
                            marginTop: 2, // Margin above the image
                        }}
                    >
                        <CardMedia
                            component="img"
                            sx={
                                style
                                    ? { ...style, objectFit: "contain", marginRight: "8px" }
                                    : { maxHeight: 40, width: 40, objectFit: "contain", marginRight: "8px" }
                            }
                            image={img}
                            alt={imgTitle || "Card image"}
                        />
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "primary.main" }}>
                            {imgTitle || ""}
                        </Typography>
                    </Box>
                )}

                <CardContent sx={{ marginBottom: 2 }}>
                    <Typography gutterBottom variant="h6" component="div">
                        {title || ""}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        {content || ""}
                    </Typography>
                </CardContent>

                <CardActions sx={{ justifyContent: "center", marginBottom: 2 }}>
                    {actions?.length > 0 &&
                        actions.map((bt, index) => (
                            <Button
                                key={index}
                                size="small"
                                variant="contained"
                                color={bt.type}
                                onClick={bt.handleClick}
                                disabled={isLoading.title === title}
                            >
                                {bt.text}
                            </Button>
                        ))}
                </CardActions>
            </Box>
        </Card>
    );
}
