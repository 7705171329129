import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    Paper,
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    InputBase,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { Delete } from "@mui/icons-material";
import {
    TableRow,
    TableCell,
    CircularProgress,
    Snackbar,
    Alert,
    TableContainer,
    TableHead,
    TableBody,
    Table,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Skeleton, Pagination } from "@mui/material";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: "1rem",
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "0.875rem",
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:hover": {
        backgroundColor: "#f1f1f1 !important",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const DomainName = () => {
    const { clientName } = useParams();
    const navigate = useNavigate();
    const [domain, setDomain] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteIpDialougeOpen, setdDeleteIpDialougeOpen] = React.useState(false);
    const [Loader, setLoader] = useState(false);
    const [deleteId, setdeleteId] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [userInput, setUserInput] = useState("");
    const [filterInputData, setFilterInputData] = React.useState("All");
    const [selectedDate, setSelectedDate] = useState(null);
    const [stroreClient, setStoreClient] = useState(null);

    const [page, setPage] = useState(1);
    const rowsPerPage = 10;
    const handleChangePage = (event, value) => {
        setPage(value);
    };
    useEffect(() => {
        handleDomainList();
    }, []);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    const FetchDomains = (e) => {
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/domainslist`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setDomain(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    };

    function handleDomainClick(domain) {
        if (domain.status === "Verified") {
            navigate(`/dashboard/DomainReputation/features/${domain.domain}/${clientName}`);
        } else {
            console.log("%cNot verified", "color: red");
        }
    }

    function formatDate(inputDate) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

        const parts = inputDate.split("-");
        if (parts.length === 3) {
            const day = parseInt(parts[0], 10);
            const monthIndex = parseInt(parts[1], 10) - 1;
            const year = parseInt(parts[2], 10);

            if (!isNaN(day) && !isNaN(monthIndex) && !isNaN(year)) {
                if (monthIndex >= 0 && monthIndex < months.length) {
                    const monthName = months[monthIndex];
                    return `${day} ${monthName} ${year}`;
                }
            }
        }

        return "Invalid Date";
    }

    function DeleteIpHandle(e) {
        let domain = e;
        setLoader(true);
        fetch(`${REACT_APP_SERVER_URL}/DeleteDomainRep`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            credentials: "include",
            body: new URLSearchParams({
                domain: domain,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setLoader(false);
                if (data.status === 200) {
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage("Domain Deleted Successfully");
                    handleDomainList(stroreClient);
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage("Internal server error");
                }
            });
    }

    function oneClickVerify(d) {
        let checkDomain = d;
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/checkVerificationTxt`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                domain: checkDomain,
            }),
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                setLoading(false);

                if (data.status === 200) {
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage("Verified successfully");
                    FetchDomains();
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage("Verification Failed, Kindly add this domain to the postmaster first");
                    FetchDomains();
                }
            });
    }

    function inputHanlder(e) {
        let input = e.target.value.toLowerCase();
        setUserInput(input);
    }

    const filterData = domain.filter((d) => {
        const userInputLower = userInput.toLowerCase();
        const isDomainMatch = d.domain.toLowerCase().includes(userInput.toLowerCase());

        if (userInput !== "") {
            return (
                d.domain.toLowerCase().includes(userInputLower) ||
                d.clientName.toLowerCase().includes(userInputLower) ||
                d.status.toLowerCase().includes(userInputLower) ||
                formatDate(d.created_time).toLowerCase().includes(userInputLower)
            );
        } else {
            if (filterInputData === "All") {
                return isDomainMatch;
            }
            if (filterInputData === "Verified") {
                return d.status === "Verified" && isDomainMatch;
            }
            if (filterInputData === "Unverified") {
                return d.status !== "Verified" && isDomainMatch;
            }
            if (filterInputData === "Date" && selectedDate) {
                const domainDate = dayjs(d.create_time, "D-M-YYYY");
                return domainDate.isValid() && domainDate.isSame(selectedDate, "day") && isDomainMatch;
            }
        }
    });

    const handleChange = (e) => {
        setFilterInputData(e.target.value);
    };

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
        setFilterInputData("Date");
    };
    const activeWorkspace = localStorage.getItem("activeWorkspace");
    async function handleDomainList(client) {
        console.log(client, "getting clientName");
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/getDomainList?workSpaceId=${activeWorkspace}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                console.log(data, "getting data");
                if (data.success) {
                    if (data.data.length > 0) {
                        data.data.sort((a, b) => new Date(b.created_time) - new Date(a.created_time));
                    }
                    setDomain(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    }

    const handleClick = () => {
        navigate("/dashboard/DomainReputation");
    };
    console.log(filterData, domain, "data");
    return (
        <div className="container">
            {Loader ? (
                <Box sx={{ width: "100%", display: "center", justifyContent: "center" }}>
                    <CircularProgress />
                    <Typography>Deleting...</Typography>
                </Box>
            ) : (
                <div>
                    <Dialog
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        onClose={() => {
                            setdDeleteIpDialougeOpen(false);
                            setdeleteId("");
                        }}
                        open={deleteIpDialougeOpen}
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete account?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    DeleteIpHandle(deleteId);
                                    setdDeleteIpDialougeOpen(false);
                                }}
                                variant="contained"
                                color="success"
                            >
                                Delete
                            </Button>
                            <Button
                                onClick={() => {
                                    setdDeleteIpDialougeOpen(false);
                                    setdeleteId("");
                                }}
                                variant="outlined"
                                color="error"
                                disabled={false}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Box>
                        <>
                            <Box>
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <Paper
                                        component="form"
                                        sx={{
                                            p: 2,
                                            mr: 2,
                                            display: "flex",
                                            alignItems: "center",
                                            width: "50%",
                                            height: "55px",
                                            boxShadow: "none",
                                            border: "1px solid rgba(71, 84, 103, 1)",
                                            float: "left",
                                            marginBottom: "10px",
                                            marginTop: "10px",
                                            fontsize: "16px",
                                            fontWeight: "700",
                                            boxshadow: "0px 2px 1px #1018280d,",
                                        }}
                                    >
                                        <SearchIcon sx={{ color: "#aaa" }} />
                                        <InputBase
                                            sx={{ flex: 1, border: "none" }}
                                            placeholder="Search Domain & Client Name"
                                            onChange={inputHanlder}
                                            inputProps={{ "aria-label": "search for projects" }}
                                        />
                                    </Paper>
                                    <FormControl sx={{ width: "50%", height: "50px", marginTop: "9px" }}>
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filterInputData}
                                            label="Status"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={"All"}>All</MenuItem>
                                            <MenuItem value={"Verified"}>Verified</MenuItem>
                                            <MenuItem value={"Unverified"}>Unverified</MenuItem>
                                            <MenuItem value={"Date"}>Date</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {filterInputData === "Date" && (
                                        <Box style={{ marginLeft: "5px", marginTop: "9px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Select Date" fullWidth />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    )}
                                </Box>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Domain Name</StyledTableCell>
                                                <StyledTableCell>Status</StyledTableCell>
                                                <StyledTableCell>Client Name</StyledTableCell>
                                                <StyledTableCell>Added</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        {loading ? (
                                            <TableBody>
                                                {[...Array(5)].map((_, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell component="th" scope="row">
                                                            <Skeleton variant="text" />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            <Skeleton variant="text" />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            <Skeleton variant="rectangular" width={30} height={30} />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            <Skeleton variant="text" />
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        ) : (
                                            <>
                                                <TableBody>
                                                    {filterData.length > 0 ? (
                                                        filterData
                                                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                                            .map((data) => (
                                                                <TableRow
                                                                    key={data.domain}
                                                                    sx={{
                                                                        "&:hover": {
                                                                            backgroundColor: "#f0f0f0",
                                                                            cursor: "pointer",
                                                                            "& .delete-icon": {
                                                                                visibility: "visible",
                                                                            },
                                                                        },
                                                                    }}
                                                                >
                                                                    <StyledTableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{ cursor: "pointer" }}
                                                                        onClick={() => handleDomainClick(data)}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                fontWeight: 500,
                                                                                lineHeight: "18px",
                                                                            }}
                                                                        >
                                                                            {data.domain}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        {data.status === "Verified" ? (
                                                                            <Box
                                                                                sx={{
                                                                                    width: "50%",
                                                                                    textAlign: "center",
                                                                                    color: "#004aad",
                                                                                    border: "2px solid #004aad",
                                                                                    fontSize: "15px",
                                                                                    fontWeight: "600",
                                                                                    lineHeight: "16.38px",
                                                                                    padding: "8px",
                                                                                    borderRadius: "5px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => handleDomainClick(data)}
                                                                            >
                                                                                Verified
                                                                            </Box>
                                                                        ) : (
                                                                            <Box
                                                                                sx={{
                                                                                    width: "50%",
                                                                                    textAlign: "center",
                                                                                    color: "rgba(255, 115, 115, 0.93)",
                                                                                    border: "2px solid rgba(255, 115, 115, 0.93)",
                                                                                    fontSize: "15px",
                                                                                    fontWeight: "600",
                                                                                    lineHeight: "16.38px",
                                                                                    padding: "8px",
                                                                                    borderRadius: "5px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() =>
                                                                                    oneClickVerify(data.domain)
                                                                                }
                                                                            >
                                                                                Not verified
                                                                            </Box>
                                                                        )}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{ cursor: "pointer" }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                fontWeight: 500,
                                                                                lineHeight: "18px",
                                                                            }}
                                                                        >
                                                                            {data.clientName}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        onClick={() => handleDomainClick(data)}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    lineHeight: "18px",
                                                                                }}
                                                                            >
                                                                                {formatDate(data.created_time)}
                                                                            </Typography>
                                                                            <Delete
                                                                                sx={{
                                                                                    cursor: "pointer",
                                                                                    visibility: "hidden",
                                                                                }}
                                                                                className="delete-icon"
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setdDeleteIpDialougeOpen(true);
                                                                                    setdeleteId(data.domain);
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                    </StyledTableCell>
                                                                </TableRow>
                                                            ))
                                                    ) : (
                                                        <TableRow>
                                                            <StyledTableCell component="th" scope="row" colSpan={4}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "16px",
                                                                        fontWeight: 500,
                                                                        lineHeight: "18px",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    No Data Found
                                                                </Typography>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </>
                                        )}
                                    </Table>
                                </TableContainer>
                                {filterData.length > 10 && (
                                    <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                                        <Pagination
                                            count={Math.ceil(filterData.length / rowsPerPage)}
                                            page={page}
                                            onChange={handleChangePage}
                                            color="primary"
                                            variant="outlined"
                                        />
                                    </Box>
                                )}
                            </Box>
                        </>
                    </Box>
                </div>
            )}
            <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default DomainName;
