import React, { useContext, useState } from "react";
import { Box, Typography, Button, Chip } from "@mui/material";
import Upgrade from "../Setting/Pricing/Upgrade";
import { AuthContext } from "../Provider/AuthProvider";
const FreeTrial = () => {
    const [showUpgrade, setShowUpgrade] = useState(false);
    const authCtx = useContext(AuthContext);
    const handleCloseUpgrade = () => {
        setShowUpgrade(false);
    };
    const endDate = authCtx.PlanDetails && authCtx.PlanDetails.end_date ? new Date(authCtx.PlanDetails.end_date) : null;
    const currentDate = new Date();
    console.log(currentDate, endDate, "date");
    const timeDifference = endDate - currentDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return (
        <Box
            sx={{
                width: 200,
                padding: 2,
                backgroundColor: "#f5f5f5",
                mb: 2,
            }}
        >
            <Typography variant="h6" sx={{ mb: 1, textWrap: "wrap" }}>
                {authCtx.PlanDetails?.planName || ""}
            </Typography>

            <Chip
                label={`${daysDifference} days left`}
                variant="outlined"
                sx={{
                    mb: 2,
                    borderRadius: "16px",
                    backgroundColor: "#fff",
                }}
            />

            <Button
                variant="contained"
                color="success"
                onClick={() => setShowUpgrade(true)}
                sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    display: "block",
                }}
            >
                Upgrade
            </Button>
            {showUpgrade && <Upgrade open={showUpgrade} onClose={handleCloseUpgrade} />}
        </Box>
    );
};

export default FreeTrial;
