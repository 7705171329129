import React, { useState } from "react";
import "./autoinboxTest.css";
import CopyIcon from "@mui/icons-material/ContentCopy";
import { useEffect } from "react";
import CryptoJS from "crypto-js";
import { ReactComponent as GoogleSvg } from "./t.svg";
import { ReactComponent as MicrosoftSvg } from "./m.svg";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { useSearchParams } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import {
    Box,
    Grid,
    Card,
    Button,
    TableRow,
    TableCell,
    Typography,
    Snackbar,
    Alert,
    TableContainer,
    TableHead,
    TableBody,
    Paper,
    Table,
} from "@mui/material";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#004aad",
        color: theme.palette.common.white,
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
        padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        textAlign: "center",
        padding: "10px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: "#f1f1f1",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
function AutoInboxTest() {
    const [loading, setLoading] = useState(true);
    const [label, setLabel] = useState([]);
    const [address, setAddress] = useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [inb, setinb] = useState("");
    const [sp, setSp] = useState("");
    const [nr, setNr] = useState("");
    const [date, setDate] = useState("");
    const [user, setUser] = useState("");

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    useEffect(() => {
        decryptData(window.location.href);
    }, []);

    const decryptData = (text) => {
        let index = text.indexOf("?");
        let txt = text.slice(index + 1, text.length);
        const bytes = CryptoJS.AES.decrypt(txt, "XkhZG4fW2t2W");
        const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        var keyValuePairs = data.split("&");
        var email = "";
        var date = "";

        for (var i = 0; i < keyValuePairs.length; i++) {
            var pair = keyValuePairs[i].split("=");
            if (pair[0] === "email") {
                email = pair[1];
            } else if (pair[0] === "date") {
                date = pair[1];
            }
        }

        fetch(`${REACT_APP_SERVER_URL}/reportAutoPlacecment?user=${email}&date=${date}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setDate(data.data[0].create_time);
                setUser(data.data[0].user);
                setinb(data.inboxCount);
                setNr(data.notReceivedCount);
                setSp(data.spamCount);
                setLoading(false);
                setLabel(data.data[0].label);
            });
        setAddress(text);
    };

    function copy(text) {
        navigator.clipboard.writeText(text);
    }

    function Label(data) {
        if (data === "INBOX") {
            return <button id="inb">INBOX</button>;
        } else if (data === "not received") {
            return (
                <button id="ntr" style={{ width: "auto" }}>
                    NOT RECEIVED
                </button>
            );
        } else {
            return <button id="spm">SPAM</button>;
        }
    }

    function meter(data) {
        let d = (data / label.length) * 100;
        let d1 = d.toString();
        return d1.slice(0, 4);
    }

    function Date(data) {
        let a = data;
        let d = "";
        // let b = `${a[8] + a[9]}-${a[5] + a[6]}-${a[0] + a[1] + a[2] + a[3]}`;
        let b = `${a[5] + a[6]}-${a[8] + a[9]}-${a[0] + a[1] + a[2] + a[3]}`;
        let datas = b.slice(0, 2);
        switch (datas) {
            case "01":
                d = "Jan";
                break;
            case "02":
                d = "Feb";
                break;
            case "03":
                d = "Mar";
                break;
            case "04":
                d = "Apr";
                break;
            case "05":
                d = "May";
                break;
            case "06":
                d = "Jun";
                break;
            case "07":
                d = "Jul";
                break;
            case "08":
                d = "Aug";
                break;
            case "09":
                d = "Sep";
                break;
            case "10":
                d = "Oct";
                break;
            case "11":
                d = "Nov";
                break;
            case "12":
                d = "Dec";
                break;
        }
        let finaldate = `${d}-${a[8] + a[9]}-${a[0] + a[1] + a[2] + a[3]}`;
        return finaldate;
    }

    return (
        <>
            <header
                style={{
                    width: "auto",
                    textAlign: "center",
                    fontSize: "25px",
                    padding: "20px",
                    color: "white",
                    backgroundColor: "#004aad",
                }}
            >
                <span>Inbox Placement</span>
            </header>
            {loading ? (
                <Box>
                    <div
                        style={{
                            marginTop: "5%",
                            marginLeft: "30%",
                            marginRight: "30%",
                            marginTop: "40px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingBottom: "4%",
                            borderRadius: "10px",
                            color: "white",
                            backgroundColor: "#004aad",
                            padding: "10px",
                        }}
                    >
                        <LinearProgress sx={{ marginBottom: "20px" }}></LinearProgress>
                        <span style={{ fontSize: "25px" }}>Inbox Placement is checking for your mail...</span>
                        <br></br>
                        <br></br>
                        <span style={{ fontSize: "18px" }}>Please wait...</span>
                    </div>
                </Box>
            ) : (
                <Box sx={{ maxWidth: "1600px", minWidth: "1200px", margin: "auto", overflow: "hidden" }}>
                    <Box sx={{ marginTop: "20px" }}>
                        <Button
                            sx={{ color: "black", width: "50%", border: "2px solid GrayText", marginLeft: "25%" }}
                            onClick={(e) => {
                                e.preventDefault();
                                setSuccessSnackbarOpen(true);
                                setSuccessMessage("Link successfully copied");
                                copy(address);
                            }}
                        >
                            <Typography
                                sx={{ fontSize: "14px", borderRadius: "8px", padding: "4px", marginRight: "10px" }}
                            >
                                {address.slice(0, address.length - 70)}
                            </Typography>
                            <CopyIcon
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSuccessSnackbarOpen(true);
                                    setSuccessMessage("Link successfully copied");
                                    copy(address);
                                }}
                            ></CopyIcon>
                        </Button>
                    </Box>
                    {label.length !== 0 && (
                        <Box
                            sx={{
                                marginLeft: "12.5%",
                                marginRight: "12.5%",
                                marginTop: "50px",
                                marginBottom: "20px",
                                padding: "20px",
                            }}
                        >
                            {/* <Typography>Share this repost:<Typography sx={{ border: '1px solid black', marginRight: '46%', borderRadius: '4px' }}>{address}</Typography></Typography><br></br> */}
                            <Box sx={{ display: "flex", marginTop: "-2%" }}>
                                <Card
                                    sx={{
                                        minWidth: "30.5%",
                                        padding: "10px",
                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 2px",
                                        marginRight: "10px",
                                    }}
                                >
                                    <CardContent
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "10px",
                                            marginTop: "40px",
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: 18, color: "black", fontWeight: "bolder", padding: "10px" }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            INBOX
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                color: "white",
                                                background: "green",
                                                padding: "15px",
                                                borderRadius: "30px",
                                            }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            {meter(inb)}%
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                        minWidth: "30.5%",
                                        padding: "10px",
                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 2px",
                                        marginRight: "10px",
                                    }}
                                >
                                    <CardContent
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "10px",
                                            marginTop: "40px",
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: 18, color: "black", fontWeight: "bolder", padding: "10px" }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            SPAM
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                color: "white",
                                                background: "red",
                                                padding: "15px",
                                                borderRadius: "30px",
                                            }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            {meter(sp)}%
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                        minWidth: "30.5%",
                                        padding: "10px",
                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 2px",
                                        marginRight: "10px",
                                    }}
                                >
                                    <CardContent
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "10px",
                                            marginTop: "40px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 18,
                                                color: "black",
                                                fontWeight: "bolder",
                                                padding: "10px",
                                                marginRight: "5px",
                                            }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            NOT RECEIVED
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                color: "white",
                                                background: "red",
                                                padding: "15px",
                                                borderRadius: "30px",
                                            }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            {meter(nr)}%
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                            <br></br>
                            <Box>
                                <TableContainer component={Paper} elevation={3}>
                                    <Table sx={{ minWidth: 800 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Email</StyledTableCell>
                                                <StyledTableCell align="right">ESP</StyledTableCell>
                                                <StyledTableCell align="right">Status</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {label &&
                                                label.map((data, i) => (
                                                    <StyledTableRow key={i}>
                                                        <StyledTableCell component="th">{data.email}</StyledTableCell>
                                                        <StyledTableCell>
                                                            {" "}
                                                            {data.service === "Microsoft" ? (
                                                                <MicrosoftSvg />
                                                            ) : (
                                                                <GoogleSvg />
                                                            )}
                                                        </StyledTableCell>
                                                        <StyledTableCell> {Label(data.label)}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}{" "}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* <CardContent>
                                    <Grid
                                        style={{
                                            width: "102.5%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: 600,
                                            color: "white",
                                            fontSize: "18px",
                                            background: "#004aad",
                                            borderRadius: "10px",
                                            padding: "1%",
                                            marginTop: "-5px",
                                        }}
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid item sm={3.2} sx={{ marginTop: "-14px" }}>
                                            <label>Email</label>
                                        </Grid>
                                        <Grid item sm={3.5} sx={{ marginTop: "-14px" }}>
                                            <label>ESP</label>
                                        </Grid>
                                        <Grid item sm={2.2} sx={{ marginTop: "-14px" }}>
                                            <label>Status</label>
                                        </Grid>
                                    </Grid>
                                </CardContent> */}
                                {/* {label &&
                                    label.map((data, i) => (
                                        <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                            <div
                                                style={{
                                                    width: "96%",
                                                    height: "5vh",
                                                    border: "0.1rem solid #EEE7DA",
                                                    marginTop: "1%",
                                                    marginBottom: "1%",
                                                    padding: "1.5%",
                                                    borderRadius: "10px",
                                                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                <CardContent>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid sx={{ marginTop: "-10px" }} sm={5}>
                                                            {data.email}
                                                        </Grid>
                                                        <Grid sm={5}>
                                                            {data.service === "Microsoft" ? (
                                                                <MicrosoftSvg />
                                                            ) : (
                                                                <GoogleSvg />
                                                            )}
                                                        </Grid>
                                                        <Grid sx={{ marginTop: "-10px" }} sm={1}>
                                                            {Label(data.label)}
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </div>
                                        </div>
                                    ))} */}
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
            <Snackbar open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default AutoInboxTest;
