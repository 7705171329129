import "./inboxTest.css";
import React, { useState, useRef } from "react";
import { io } from "socket.io-client";
import { useEffect } from "react";
import { ReactComponent as GoogleSvg } from "./t.svg";
import CardContent from "@mui/material/CardContent";
import { ReactComponent as MicrosoftSvg } from "./m.svg";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ButtonGroup from "@mui/material/ButtonGroup";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import {
    Box,
    Grid,
    Card,
    Button,
    TableRow,
    TableCell,
    Typography,
    CircularProgress,
    Snackbar,
    Alert,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
} from "@mui/material";
import { useParams } from "react-router-dom";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const REACT_APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: "1rem",
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "0.875rem",
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: "#f1f1f1",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function InboxTest() {
    const [loading, setLoading] = useState(true);
    const [label, setLabel] = useState([]);
    const [address, setAddress] = useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [score, setScore] = useState(0);
    const [inb, setInb] = useState(0);
    const [sp, setSp] = useState(0);
    const [nr, setNr] = useState(0);
    const params = useParams();
    const [response, setResponse] = useState([]);
    const [testLoading, setTestLoading] = useState(false);
    const socket = useRef();

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };
    let i = 0;
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const executeGetScore = urlParams.get("executeGetScore");

        socket.current = io(REACT_APP_SOCKET_URL);
        socket.current.on("tracking-update", (msg) => {
            i++;
            if (msg.code === params.id) {
                getResult();
                setTestLoading(true);
            }
        });
        if (executeGetScore === "true") {
            getScore();
            window.history.replaceState({}, document.title, window.location.pathname);
        }
        getResult();
    }, []);

    const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.white,
                color: "black",
                boxShadow: theme.shadows[1],
                width: 180,
                fontSize: 13,
            },
        })
    );

    const getResult = async () => {
        let code = params.id;
        fetch(`${REACT_APP_SERVER_URL}/inbox_placement_sender`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                code: code,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                // console.log("------------------->>>>>>>>",data.data);
                setResponse(data.data);
                setLoading(false);
                setTestLoading(false);

                // inbox
                // spam
                // not received
                // after updating the count
                // setInb(inbox)
                //

                data.data.forEach((item) => {
                    switch (item.label) {
                        case "INBOX":
                            label.push(item.label);
                            setInb((prevInb) => prevInb + 1);
                            break;
                        case "SPAM":
                            label.push(item.label);
                            setSp((prevSp) => prevSp + 1);
                            break;
                        case "Not Received": // Adjust label case if it doesn't match exactly
                            label.push(item.label);
                            setNr((prevNr) => prevNr + 1);
                            break;
                        default:
                            // Handle unexpected label values
                            break;
                    }
                });
            });
        setAddress(`${process.env.REACT_APP_URL}/test/${code}`);
    };

    function getScore() {
        let code = params.id;
        fetch(`${REACT_APP_SERVER_URL}/inbox_placement`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
                code: code,
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setLoading(false);
                }
            });
        setAddress(`${process.env.REACT_APP_URL}/test/${code}`);
    }

    function copy(text) {
        navigator.clipboard.writeText(text);
    }

    function Label(data) {
        if (data === "INBOX") {
            return (
                <LightTooltip title=" The emails have successfully landed in the inbox. Safe to proceed with the campaign launch.">
                    <button
                        style={{
                            padding: "10px",
                            color: "white",
                            fontSize: "15px",
                            backgroundColor: "#008c51d4",
                            border: "none",
                            borderRadius: "10px",
                            cursor: "pointer",
                        }}
                    >
                        INBOX
                    </button>
                </LightTooltip>
            );
        } else if (data === "SPAM") {
            return (
                <LightTooltip title="Landed in Spam">
                    <button
                        style={{
                            padding: "10px",
                            color: "white",
                            fontSize: "15px",
                            backgroundColor: "red",
                            border: "none",
                            borderRadius: "10px",
                            cursor: "pointer",
                        }}
                    >
                        SPAM
                    </button>
                </LightTooltip>
            );
        } else {
            return (
                <LightTooltip title="The email is yet to be delivered or the mail provider hasn't sent it. Immediate investigation is crucial">
                    <button
                        style={{
                            padding: "10px",
                            color: "white",
                            fontSize: "15px",
                            backgroundColor: "rgba(255, 115, 115, 0.93)",
                            border: "none",
                            borderRadius: "10px",
                            cursor: "pointer",
                        }}
                    >
                        Not Received
                    </button>
                </LightTooltip>
            );
        }
    }

    function meter(data) {
        // console.log(">>>>>>>>>>>>>>>>>>>",data);
        console.log("-------------------", label.length);
        let d = (data / label.length) * 100;
        let d1 = d.toString();
        return d1.slice(0, 4);
    }

    return (
        <>
            <header
                style={{
                    width: "auto",
                    textAlign: "center",
                    fontSize: "25px",
                    padding: "20px",
                    color: "white",
                    backgroundColor: "#004aad",
                }}
            >
                <span>Inbox Placement</span>
            </header>
            {loading ? (
                <Box>
                    <div
                        style={{
                            marginTop: "5%",
                            marginLeft: "30%",
                            marginRight: "30%",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingBottom: "4%",
                            borderRadius: "10px",
                            color: "white",
                            backgroundColor: "#004aad",
                            padding: "10px",
                        }}
                    >
                        <LinearProgress sx={{ marginBottom: "20px" }}></LinearProgress>
                        <span style={{ fontSize: "25px" }}>Inbox Placement is checking for your mail...</span>
                        <br></br>
                        <br></br>
                        <span style={{ fontSize: "18px" }}>
                            Please wait it may take 10 to 30 seconds to complete...
                        </span>
                        <Typography sx={{ marginTop: "12px" }}>
                            <span style={{ color: "red" }}>NOTE:</span>
                            This code will search for result only for 4 minutes from the sending time, After that the
                            code will expire and show the Latest Result, So kindly pls First send the code and mail,
                            then search for result.
                        </Typography>
                    </div>
                </Box>
            ) : (
                <Box sx={{ maxWidth: "1600px", minWidth: "1200px" }}>
                    {response.length !== 0 && (
                        <Box sx={{ marginTop: "50px", marginLeft: "10%", marginRight: "10%" }}>
                            {/* <ButtonGroup disableElevation
                                variant="contained"
                                aria-label="Disabled elevation buttons" sx={{ marginBottom: '10px', borderRadius: '8px', border: '2px solid black' }} onClick={
                                    (e) => {
                                        copy(address);
                                        setSuccessSnackbarOpen(true);
                                        setSuccessMessage('Addresses copied Successfully')
                                    }
                                }>
                                <Button sx={{ background: 'white', color: 'black', padding: '4px' }}>{address}</Button>
                                <Button sx={{ background: 'white', color: 'black' }}><FileCopyIcon></FileCopyIcon></Button>
                            </ButtonGroup>

                            <IconButton sx={{ marginBottom: '10px', color: 'black', display: 'flex' }} onClick={(e) => {
                                e.preventDefault();
                                setSuccessSnackbarOpen(true);
                                setSuccessMessage('Link successfully copied');
                                copy(address)
                            }}><Typography sx={{ fontSize: '16px', border: '1px solid black', borderRadius: '8px', padding: '4px', marginRight: '10px' }}>{address}</Typography><CopyIcon onClick={(e) => {
                                e.preventDefault();
                                setSuccessSnackbarOpen(true);
                                setSuccessMessage('Link successfully copied');
                                copy(address)
                            }}></CopyIcon></IconButton>
                        */}

                            <Box sx={{ display: "flex", width: "100%" }}>
                                <Card
                                    sx={{
                                        minWidth: "31%",
                                        padding: "10px",
                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 2px",
                                        marginRight: "10px",
                                    }}
                                >
                                    <CardContent
                                        sx={{ display: "flex", justifyContent: "space-between", margin: "15px" }}
                                    >
                                        <Typography
                                            sx={{ fontSize: 18, color: "black", fontWeight: "bolder", padding: "15px" }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            INBOX
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                color: "white",
                                                background: "green",
                                                padding: "15px",
                                                borderRadius: "30px",
                                            }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            {meter(inb)}%
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                        minWidth: "31%",
                                        padding: "10px",
                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 2px",
                                        marginRight: "10px",
                                    }}
                                >
                                    <CardContent
                                        sx={{ display: "flex", justifyContent: "space-between", margin: "15px" }}
                                    >
                                        <Typography
                                            sx={{ fontSize: 18, color: "black", fontWeight: "bolder", padding: "15px" }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            SPAM
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                color: "white",
                                                background: "red",
                                                padding: "15px",
                                                borderRadius: "30px",
                                            }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            {meter(sp)}%
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                        minWidth: "31%",
                                        padding: "10px",
                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 2px",
                                        marginRight: "10px",
                                    }}
                                >
                                    <CardContent
                                        sx={{ display: "flex", justifyContent: "space-between", margin: "15px" }}
                                    >
                                        <Typography
                                            sx={{ fontSize: 18, color: "black", fontWeight: "bolder", padding: "15px" }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            NOT RECEIVED
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                color: "white",
                                                background: "rgba(255, 115, 115, 0.93)",
                                                padding: "15px",
                                                borderRadius: "30px",
                                            }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            {meter(nr)}%
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                            <br></br>
                            <Box>
                                <h2 style={{ fontFamily: "inherit", fontWeight: 500 }}>Share The Report</h2>{" "}
                                {testLoading && <CircularProgress size={25} />}
                                <ButtonGroup
                                    disableElevation
                                    aria-label="Disabled elevation buttons"
                                    sx={{ marginBottom: "10px", borderRadius: "8px" }}
                                    onClick={(e) => {
                                        copy(address);
                                        setSuccessSnackbarOpen(true);
                                        setSuccessMessage("Addresses copied Successfully");
                                    }}
                                >
                                    <Button sx={{ background: "white", color: "black", padding: "4px" }}>
                                        {address}
                                    </Button>
                                    <Button sx={{ background: "white", color: "black" }}>
                                        <FileCopyIcon></FileCopyIcon>
                                    </Button>
                                </ButtonGroup>
                            </Box>

                            <Box>
                                <TableContainer component={Paper} elevation={3}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Email</StyledTableCell>
                                                <StyledTableCell align="right">ESP</StyledTableCell>
                                                <StyledTableCell align="right">Status</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {response &&
                                                response.map((data, i) => (
                                                    <StyledTableRow key={i}>
                                                        <StyledTableCell component="th">{data.email}</StyledTableCell>
                                                        <StyledTableCell>
                                                            {data.service === "Microsoft" ||
                                                            data.service === "Microsoft-professional" ? (
                                                                <MicrosoftSvg />
                                                            ) : (
                                                                <GoogleSvg />
                                                            )}
                                                        </StyledTableCell>
                                                        <StyledTableCell>{Label(data.label)}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}{" "}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* <CardContent>
                                    <Grid
                                        style={{
                                            width: "102.5%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: 600,
                                            color: "white",
                                            fontSize: "18px",
                                            background: "#004aad",
                                            borderRadius: "10px",
                                            padding: "1%",
                                            marginTop: "-5px",
                                        }}
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid item sm={5.5} sx={{ marginTop: "-10px" }}>
                                            <label>Email</label>
                                        </Grid>
                                        <Grid item sm={4} sx={{ marginTop: "-10px" }}>
                                            <label>ESP</label>
                                        </Grid>
                                        <Grid item sm={2} sx={{ marginTop: "-10px" }}>
                                            <label>Status</label>
                                        </Grid>
                                    </Grid>
                                </CardContent> */}
                                {/* {response &&
                                    response.map((data, i) => (
                                        <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                            <div
                                                style={{
                                                    width: "97.5%",
                                                    height: "50px",
                                                    border: "0.1rem solid #EEE7DA",
                                                    marginTop: "0%",
                                                    marginBottom: "1%",
                                                    padding: "1%",
                                                    borderRadius: "10px",
                                                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                <CardContent>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid sm={6} id="ip">
                                                            {data.email}
                                                        </Grid>
                                                        <Grid sm={4}>
                                                            {data.service === "Microsoft" ||
                                                            data.service === "Microsoft-professional" ? (
                                                                <MicrosoftSvg />
                                                            ) : (
                                                                <GoogleSvg />
                                                            )}
                                                        </Grid>
                                                        <Grid sm={0}>{Label(data.label)}</Grid>
                                                    </Grid>
                                                </CardContent>
                                            </div>
                                        </div>
                                    ))} */}
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
            <Snackbar open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default InboxTest;
